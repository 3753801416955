
<template>
  <div id="menuTopBar" class="layout-topbar">
    <button id="toggle-sidebar" class="p-link layout-menu-button" @click="onMenuToggle">
      <span style="font-size: 24px;" class="pi pi-bars"></span>
    </button>
    <div id="topbarIcon" class="layout-topbar-icons">
      <!-- <button id="btnNoti" class="p-link">
        <span class="layout-topbar-item-text">Notification</span>
        <span style="font-size: 24px;" class="layout-topbar-icon fa fa-bell"></span>
      </button>
      
      <button id="btnProfile" class="p-link">
        <span class="layout-topbar-item-text">Setting</span>
        <span style="font-size: 24px;" class="layout-topbar-icon fa fa-gear"></span>
      </button> -->
      
      <button id="btnProfile" class="p-link" @click="OnShow">
        <span class="layout-topbar-item-text">Người dùng</span>
        <span style="font-size: 24px;" class="layout-topbar-icon fa fa-user"></span>
      </button>
      <OverlayPanel ref="op" appendTo="btnProfile" id="overlay_panel" style="width:150px">
        <div class="">
          <a href="#" title="Đổi mật khẩu"  @click='openChangePassword()'>
            <div class="fontsize">Đổi mật khẩu</div>
          </a>
        </div>
        <hr class="new1" />
        <div>
          <a href="#" @click='submitLogout' class="fontsize" title="Đăng xuất">Đăng xuất</a>
        </div>
      </OverlayPanel>
    </div>
    <Dialog header="ĐĂNG XUẤT" :visible.sync="displayLogout" :style="{width: '600px','text-align': 'left'}" :modal="true" :position="position">
      <div class="confirmation-content">
          <span>Bạn có chắc muốn đăng xuất khỏi tài khoản này không?</span>
      </div>
      <template #footer>
        <div class="custom-center">
          <Button label="Hủy" @click="closeLogout()" icon="pi pi-times" class="btn-cancel" />
          <Button label="Xác nhận" @click="validateLogout()" icon="pi pi-check" class="btn-save mr-0" autofocus="autofocus" />
          <!-- <button @click="closeLogout()" class="btn btn-cancel"> Huỷ</button>
          <button @click="validateLogout()" class="btn btn-save mr-0" autofocus><i class="pi pi-check icon" style="font-size: 15px; "></i> Xác nhận</button>  -->
        </div>
      </template>
    </Dialog>

    <Dialog header="ĐỔI MẬT KHẨU" :visible.sync="displayChangePassword" :style="{width: '400px'}" :modal="true" :position="position">
      <div class="confirmation-content">
        <span class="" style="color:red" v-if='error'> {{error}}</span>
          <form action="#" autocomplete="off">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group custom-form-group" style="position: relative">
                  <label class="control-label">Mật khẩu hiện tại</label>
                  <input id="passwordOld" placeholder="Nhập" v-model="passwordOld" :type='passwordType' required="required" class="form-control form-custom-pass custom-pass-input" style="padding-right: 40px !important;margin-bottom: 16px;">
                  <i style="margin-top: -45px; cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibility" v-if="passwordType === 'text'">visibility</i>
                  <i style="margin-top: -45px; cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibility" v-else>visibility_off</i>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group custom-form-group" style="position: relative;">
                  <label class="control-label">Mật khẩu mới</label>
                  <input id="passwordNew" placeholder="Nhập" v-model="passwordNew" :type='secondPasswordType' @keyup='checkPassword' required="required" class="form-control form-custom-pass custom-pass-input" style="padding-right: 40px !important;margin-bottom: 16px;">
                  <i style="margin-top: -45px; cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibilityNew" v-if="secondPasswordType === 'text'">visibility</i>
                  <i style="margin-top: -45px; cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibilityNew" v-else>visibility_off</i>
                </div>
              </div>
            <div class="col-md-12">
              <div class="form-group custom-form-group" style="position: relative; margin-bottom: 0;">
                <label class="control-label">Nhập lại mật khẩu</label>
                <input id="passwordConfirm" placeholder="Nhập" v-model="passwordConfirm" :type='confirmPasswordType' @keyup='checkPassword' required="required" class="form-control form-custom-pass custom-pass-input" style="padding-right: 40px !important;">
                <i style="cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibilityConfirm" v-if="confirmPasswordType === 'text'">visibility</i>
                <i style="cursor: pointer;" class="material-icons lg-icon-password" @click="switchVisibilityConfirm" v-else>visibility_off</i>
              </div>
            </div>
          </div>
        </form>
      </div>
      <template #footer>
        <div class="custom-center">
          <Button label="Hủy" @click="closeLogout()" icon="pi pi-times" class="btn-cancel" />
          <Button label="Lưu thay đổi" @click="submitChangePassword()" icon="pi pi-check" class="btn-save mr-0" autofocus="autofocus" />
        </div>
      </template>
      <!-- <Dialog :visible.sync="displayChangePassword" :modal="true" :position="position"> -->
        <!-- <changePassword :visible.sync="displayChangePassword" class="modal" :position="position"/> -->
      <!-- </Dialog> -->
    </Dialog>
    
    
  </div>
</template>

<script>
import ApiRepository from "@/core/ApiRepository";
//import store from '../../../store'
//import firebase from 'firebase'
// import changePassword from '@/views/auth/changePassword.vue'
import Cryptojs from 'crypto-js'
export default {
  // components: {
  //   'changePassword': changePassword,
  // },
  data() {
    return {
      show: false,
      displayLogout: false,
      passwordOld: null,
      passwordNew: null,
      passwordConfirm: null,
      passwordType: 'password',
      secondPasswordType: 'password',
      confirmPasswordType: 'password',
      displayChangePassword: false,
      error: null,
      position : 'top'
    }
  },
  methods: {
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },
    OnShow(event) {
      this.$refs.op.toggle(event);
    },
    submitLogout() {
      this.displayLogout = true;
      // this.$modal.show(mdLogout, {}, {
      //   clickToClose: false,
      //   height: 'auto',
      //   adaptive: true,
      //   classes: ['col-md-6'],
      //   reset: true
      // })
    },
    closeLogout() {
      this.displayLogout = false;
    },
    closeChangePassword() {
      this.displayChangePassword = false;
    },
    openChangePassword() {
      // close pop-up edit profile
      //this.$emit('close')
      this.error = null;
      this.passwordOld = null;
      this.passwordNew = null;
      this.passwordConfirm = null;
      this.displayChangePassword = true;
      try {
        this.displayChangePassword = true;
      } catch (e) {
        console.error(e)
      }
    },
    validateLogout() {
      // console.log('Logout')
      this.$AuthenticationApp.clearDataLogout().then(() => {
        //this.$emit('close')
        this.displayLogout = false;
        //this.$router.push('/auth/login')
      })
    },
    switchVisibility() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    switchVisibilityNew() {
      this.secondPasswordType = this.secondPasswordType === 'password' ? 'text' : 'password'
    },
    switchVisibilityConfirm() {
      this.confirmPasswordType = this.confirmPasswordType === 'password' ? 'text' : 'password'
    },
    checkPassword() {
      // console.log(e)
      if (this.passwordConfirm !== this.passwordNew) {
        this.matched = false
      } else {
        this.matched = true
      }
    },
    async submitChangePassword() {
      var that = this;
      if (!this.$commonFuction.isEmpty(this.passwordConfirm)
          && !this.$commonFuction.isEmpty(this.passwordOld)
          && !this.$commonFuction.isEmpty(this.passwordNew)
      ) {
        if (this.matched == false){
          this.error = 'New password does not match.';
          return false;
        }
        //console.log("await this.getStateLogin()",(await this.$AuthenticationApp.getToken()))
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        that.$commonFuction.is_loading(true);
        var dataChangePassword = {
          password_current: (Cryptojs.SHA256(this.passwordOld.toString())).toString() ,
          password_new: (Cryptojs.SHA256(this.passwordNew.toString())).toString() ,
        }
        ApiRepository.post(this.$constants.API.CHANGE_PASSWORD, dataChangePassword, {headers}).then(async (res) => {
          console.log('=========> createUser: ', res)
          that.$toast.add({severity: 'success', summary: 'Success', detail: 'Change password successfully', life: 3000});
          that.$commonFuction.is_loading(false);
          that.displayChangePassword = false;
          //logout ra
          that.$AuthenticationApp.clearDataLogout().then(() => {
            this.$router.push('/auth/login')
          })
        })
            .catch(async error => {
              //this.errorMessage = error.message;
              var message = error.response.data.message;
              //console.error("There was an error!", message);
              switch(message) {
                case that.$constants.MSG_ERROR.INVALID_TOKEN_ERR:
                  that.$toast.add({
                    severity: 'error',
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.SESION_LOGIN_EXPIRED,
                    life: 3000
                  });
                  await that.$commonFuction.sleep(2000);
                  //logout
                  that.$AuthenticationApp.clearDataLogout().then(() => {})
                  that.$commonFuction.is_loading(false);
                  break;
                case that.$constants.MSG_ERROR.MISMATCH_PARAMS_ERR:
                  that.$toast.add({
                    severity: 'error',
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                    life: 3000
                  });
                  that.$commonFuction.is_loading(false);
                  break;
                case that.$constants.MSG_ERROR.INVALID_CURRENT_PASSWORD_ERR:
                  that.$toast.add({
                    severity: 'error',
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.INVALID_CURRENT_PASSWORD_ERR,
                    life: 3000
                  });
                  that.$commonFuction.is_loading(false);
                  break;
                case that.$constants.MSG_ERROR.PASSWORD_NEW_NOT_SAME_PASSWORD_CURRENT_ERR:
                  that.$toast.add({
                    severity: 'error',
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.PASSWORD_NEW_NOT_SAME_PASSWORD_CURRENT_ERR,
                    life: 3000
                  });
                  that.$commonFuction.is_loading(false);
                  break;
                default:
                  that.$toast.add({
                    severity: 'error',
                    summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                    detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                    life: 3000
                  });
                  that.$commonFuction.is_loading(false);
              }
            });
      } else  {
        // console.log(this.$refs)
        this.error = 'Vui lòng điền đầy đủ thông tin!'
      }
    }
  },
}
</script>
<style lang="scss">
.fontsize {
  font-size: 13px;
}
#overlay_panel {
  top: 15px !important;
  left: unset !important;
  right: 0 !important;
}
.form-custom-pass{
  padding-right: 40px !important;
  padding-right: 42.5px;
  border-radius: 3px !important;
  box-shadow: none;
  border-color: #d2d6de;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  font-family: inherit;
}
.lg-icon-password {
    margin-top: -30px;
    display: block;
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.custom-pass-input{
  margin-top: 2px;
}
.default-custom-btn{
  width: 125px !important;
}
.custom-center{
  text-align: center;
}
.p-dialog-header{
  padding: 16px 24px !important;
  .p-dialog-title{
    font-weight: bold;
    font-size: 18px;
    color: #1D3118;
  }

  background-color: #F4F4F4 ;
  border-bottom: 1px solid #C8C8C8;
}

.p-dialog-content{
  padding: 24px !important;
  border-bottom: 1px solid #D0D4D9;
  .control-label{
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }
  .form-control{
    height: 48px;
  }
}

.p-dialog-footer{
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px !important;
  .btn-cancel{
    background-color: #F2F3F7;
    border: unset;
    color: #333333;
    .p-button-label{
      color: #333333 !important;
    }
    text-transform: none;
    font-size: 16px;
    // font-family: 'adobe clean', sans-serif;
    border-radius: 3px;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
    margin-top: 0 !important;
    height: 40px;
    letter-spacing: 0;
    &:hover{
      color: #333333;
      background-color: #F2F3F7;
    }
  }
  .btn-save{
    background-color: #C40380;
    color: #FFFFFF;
    text-transform: none;
    font-size: 16px;
    // font-family: 'adobe clean', sans-serif;
    border-radius: 3px;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
    margin-top: 0 !important;
    height: 40px;
    letter-spacing: 0;
    border: unset;
    &:hover{
      color: white;
      background-color: #C40380;
    }
  }
}
.p-overlaypanel-content{
  padding: 16px !important;
  a {
    color: #333333;
  }
  hr {
    opacity: 0.5;
    background: #EEEEEE;
    height: 1px;
  }
}
.p-dialog-mask.p-dialog-top.p-component-overlay{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 311px){
  .p-dialog-footer{
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    .custom-center{
      text-align: end !important;
    }
    .btn-cancel{
      margin-bottom: 10px !important;
      margin-right: 0px !important;
    }
  }
}
</style>