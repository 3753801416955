<template>
  <div class="scanner-container">
    <Button icon="pi pi-camera" class="p-button-rounded p-button-text position-absolute" style="left: 0; z-index: 2; top: 0; color: #fff !important;" @click="switchCam"/>
    <div v-show="!isLoading">
      <video poster="data:image/gif,AAAA" ref="scanner"></video>
      <div class="overlay-element"></div>
      <div class="laser"></div>

    </div>
  </div>
</template>

<script>
import { BrowserMultiFormatReader, Exception } from "@zxing/library";

export default {
  name: "stream-barcode-reader",
  data() {
    return {
      isLoading: true,
      cameraIndex: 0,
      cameraArray: [],
      cameraArr: [],
      codeReader: new BrowserMultiFormatReader(),
      isMediaStreamAPISupported: navigator && navigator.mediaDevices && "enumerateDevices" in navigator.mediaDevices
    };
  },
  async mounted() {
    if (!this.isMediaStreamAPISupported) {
      throw new Exception("Media Stream API is not supported");
    }
    const devices = await navigator.mediaDevices.enumerateDevices();
    this.cameraArray = devices.filter(device => device.kind === 'videoinput');

    // if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    //   this.codeReader.enumerateDevices()
    //   .then((videoInputDevices) => {
    //       this.cameraArray = videoInputDevices;
    //       alert(videoInputDevices.length);
    //       let selectedDeviceId = videoInputDevices[0]?.deviceId;
    //       this.start(selectedDeviceId);
    //   })
    //   .catch((err) => {
    //       console.error(err)
    //   })
    // } else {
    // this.codeReader.listVideoInputDevices()
    // .then((videoInputDevices) => {
    // this.cameraArray = videoInputDevices;
     for (let i=0; i < this.cameraArray.length; i++) {
      let m = this.cameraArray[i];
       this.cameraArr.push({
         label: m.label,
         groupId: m.groupId,
         deviceId: m.deviceId
       })
     }
     this.cameraIndex = this.cameraArray.length > 1 ? 1 : 0;
     let selectedDeviceId = this.cameraArray[this.cameraIndex]?.deviceId;
     this.start(selectedDeviceId);
    // })
    // .catch((err) => {
    //     console.error(err)
    // })
    // }
    this.$refs.scanner.oncanplay = (event) => {
      console.log(event);
      this.isLoading = false;
      this.$emit("loaded");
    };
  },

  beforeDestroy() {
    console.log("stop")
    this.$refs.scanner.onpause = (event) => {
      console.log(event);
      this.isLoading = true;
      this.$emit("loaded");
    };
    this.codeReader.reset();
  },
  methods: {
    switchCam() {
      if (this.cameraArr.length <= 1)
       return;
      this.cameraIndex = (this.cameraIndex + 1) % this.cameraArr.length;
      this.codeReader.reset();
      this.start(this.cameraArr[this.cameraIndex].deviceId);
    },
    start(selectedDeviceId=undefined) {
      let videoConstraints = {
        deviceId: { exact: selectedDeviceId },
//        width: { min: 640, ideal: 1920 },
//        height: { min: 640, ideal: 1920 },
//        aspectRatio: { ideal: 1 }
        width: { min: 640, ideal: 1920 },
        height: { min: 400, ideal: 1080 },
        aspectRatio: { ideal: 1.7777777778 }
      };
      // this.codeReader.decodeFromVideoDevice(selectedDeviceId, this.$refs.scanner, (result, err) => {
      this.codeReader.decodeFromConstraints({video: videoConstraints}, this.$refs.scanner, (result, err) => {
        if (result) {
          this.$emit("decode", result.text);
          this.$emit("result", result);
        }
        if (err) {
          // console.log(err)
        }
      });
    },
  },
};
</script>

<style scoped>
video {
  max-width: 100%;
  max-height: 100%;
  -moz-transform:scale(1);
  -webkit-transform:scale(1);
  -o-transform:scale(1);
  -ms-transform:scale(1);
  transform:scale(1);
}
.scanner-container {
  position: relative;
  overflow: hidden;
}

.overlay-element {
  position: absolute;
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 99%;
  background: rgba(30, 30, 30, 0.5);

  -webkit-clip-path: polygon(0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0%);
}

.laser {
  width: 60%;
  margin-left: 20%;
  background-color: tomato;
  height: 1px;
  position: absolute;
  top: 40%;
  z-index: 2;
  box-shadow: 0 0 4px red;
  -webkit-animation: scanning 2s infinite;
  animation: scanning 2s infinite;
}
@-webkit-keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}
@keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}
</style>
