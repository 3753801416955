import BaseModel from "../core/BaseModel";
import CompanyModel from "./CompanyModel";
import MaterialModel from "./MaterialModel";
import UnitModel from './UnitModel';
import Common from "../core/Common";

export default class ProductModel extends BaseModel {
  constructor() {
    super(
      {
        name: "product",
        tableName: "product",
        label: "Danh sách sản phẩm",
        display_key: "id",
      },
      {
        stt: { 
          name: "stt", 
          label: "STT", 
          // width : 60,
          class: 'center txt-xs-col',
          computed: (data, index) => {
              data.stt = index + 1;
          }
        },
        id: {
          name: "id",
          type: "Int",
          label: "ID",
          primaryKey: true,
          display: false,
        },
        compnay: { 
            refs: 'company {id code}',
            display: false
        },
        company_code: Common.makeFilterCompany('company_code', 'Mã công ty', new CompanyModel(), "product", "small-col"),
        
        code: {
            name: "code",
            label: "Mã sản phẩm",
            // width: 250,
            type: "Text",
            class: "small-col",
            filter: {type: 'input', match: 'contains'}
        },
        
        name: {
            name: "name",
            label: "Tên sản phẩm",
            // width: 250,
            type: "Text",
            class: "medium-col style-name-product",
            filter: {type: 'input', match: 'contains'}
        },
        unit: { 
            refs: 'unit {id name}',
            display: false
        },
        // unit_name: {
        //     name: "unit_name",
        //     label: "Đơn vị",
        //     // width: 120,
        //     class: "small-col",
        //     type: "Selection",
        //     custom: 'SelectionText',
        //     models: new UnitModel(),
        //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
        //     computed: (data) => {
        //       data.unit_name = '';
        //     }
        // },
        unit_name: Common.makeFilterCompany('unit_name', 'Đơn vị', new UnitModel(), "product", "small-col"),
        can_sale: { 
            type: "Number",
            // width: 120,
            class: "small-col",
            label: "Có thể bán",
            computed: (data) => {
                data.can_sale = 0;
            }
        },
        material: {
            refs: 'material {id name code}',
            display: false
        },
        order_items: {
          refs: 'order_items {export_qty import_qty}',
          display: false
        },
        // materials_name: {
        //     name: "materials_name",
        //     // width: 120,
        //     class: "small-col",
        //     label: "Nhóm VTHH",
        //     type: "Selection",
        //     custom: 'SelectionText',
        //     models: new MaterialModel(),
        //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
        //     computed: (data) => {
        //       data.materials_name = '';
        //     }
        // },
        materials_name: Common.makeFilterCompany('materials_name', 'Nhóm VTHH', new MaterialModel(), "product", "small-col"),
        link_image: { 
            name: "link_image",
            // width: 120,
            class: "small-col",
            type: "Link",
            label: "Link hình ảnh sản phẩm"
        },
        link: { 
            name: "link",
            type: "Link",
            // width: 120,
            class: "small-col",
            label: "Link phiếu kiểm nghiệm"
        },
        status : {
            name: 'status',
            type: "Selection",
            // width: 120,
            class: "small-col",
            label: 'Trạng thái KD',
            custom: 'SelectionText',
            // width : 6,
            options: [
                {value: 3, label: 'Hết hàng'},
                {value: 2, label: 'Hàng bỏ mẫu'},
                {value:1,label:"Đang kinh doanh"},
                {value:0,label:"Ngừng kinh doanh"}
            ],
            filter: {
              type: 'select',
              optionLabel: 'label',
              optionKey: 'value',
              match: 'equals'
            },
        },
        created_at : { 
            name: 'created_at', 
            type: "CustomDate", 
            // width: 120,
            class: "small-col",
            label: 'Thời gian tạo', 
            sortable: false,  
            filter: false,
            display: false
        },
        updated_at : { 
            name: 'updated_at', 
            type: "Datetime", 
            // width: 120,
            class: "small-col",
            label: 'Ngày cập nhật', 
            sortable: false,  
            filter: false,
            // display: false
        },
        user_update: {
          refs: 'user_update {id fullname}',
          display: false
        },
        updated_by : { 
            name: 'updated_by',
            type: "Text", 
            // width: 120,
            class: "small-col",
            label: 'Người cập nhật', 
            sortable: false,
            filter: false,
            // display: false
        },
        lost_info: { 
          type: "Selection",
          custom: 'SelectionText',
            label: "Thiếu thông tin",
            // width: 120,
            class: "small-col txt-center",
            options: [
              {value:true,label:"Có"},
              {value:false,label:"Không"}
            ],
            filter: {
              type: 'select',
              optionLabel: 'label',
              optionKey: 'value',
              match: 'equals'
            },
            computed: (data) => {
                data.lost_info = '';
            }
        },
        info_book_min_safe: {
            name: 'info_book_min_safe',
            type: "Text",
            label: "Tồn kho an toàn",
            display: false
        },
        warning_stock: { 
            type: "Selection",
            custom: 'SelectionText',
            label: "Cảnh báo tồn kho",
            // width: 120,
            class: "small-col txt-center",
            options: [
              {value:true,label:"Có"},
              {value:false,label:"Không"}
            ],
            filter: {
              type: 'select',
              optionLabel: 'label',
              optionKey: 'value',
              match: 'equals'
            },
            computed: (data) => {
                data.warning_stock = '';
            }
        },
          key_get1: {
              refs:  'is_lack_information is_warning',
              display: false
          },
      }
    );
  }
}
