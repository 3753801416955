<!--:totalRecords="totalRecords"
:lazy="true"
--><!--:lazy="true"-->
<!--@filter="onFilter($event)"-->
<!--:filters="filters"-->
<!--@filter="onFilter($event)"-->
<!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
    <template>
  <DataTable
    :key="key_table"
    :value="listDataService"
    :lazy="true"
    class="p-datatable-responsive"
    selectionMode="single"
    data-key="id"
    @page="onPage($event)"
     @sort="sortTable($event)"
    :paginator="pagination"
    :totalRecords="totalRecords"
    :rows="paginationRows"
    :loading="loading"
    :scrollable="(scrollHeight !== '' ? true : false) "
    :scrollHeight="scrollHeight"
    :autoLayout="true"
      sortMode="multiple"
    filterMatchMode="startsWith"
    :first="firstRecordIndex"
    :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
    :currentPageReportTemplate=" $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM+' {totalRecords}'"
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >



    <div class="div_search">
      <div class="div_date div_search_mobi div_pc">
        <label class="p-col-fixed txt-right text_align label_company">Xem thống kê </label>
        <Calendar
            class=" margin input_width border_color Calendar width_mobi"
            dateFormat="dd/mm/yy"
            placeholder="DD/MM/YYYY - DD/MM/YYYY"
            id="date"
            v-model="filters['date_filter']"
            :showIcon="true"
            :showOnFocus="true"
            :monthNavigator="true"
            :yearNavigator="true"
            yearRange="2000:2030"
            :manualInput="false"
            selectionMode="range"
            appendTo="body"
            :showClear="true"
            :showButtonBar="true"
            @clear-click="onFilterOutside('DateRange','date_filter')"
            @date-select="onFilterOutside('DateRange','date_filter')"
        />

      </div>

      <div class="div_company div_search_mobi div_pc">
        <label class="p-col-fixed txt-right text_align label_company ">Công ty</label>
        <Dropdown
            id="selectType"
            @change="onFilterOutside('select','company_filter')"
            class="input_text width_mobi "
            v-model="filters['company_filter']"
            :options="company"
            :optionLabel="getLableCompany"
            optionValue="id"
            placeholder="Chọn">
            <template #option="slotProps">
              <div class="p-dropdown-company-option">
                <span><em v-if="slotProps.option.code"><b>{{slotProps.option.code}}</b> - </em>{{slotProps.option.name}}</span>
              </div>
            </template>
          </Dropdown>
      </div>
<!--      <div class="button_import">
        <Button v-if="display == true" v-on:click="show" label="Xuất excel" class="p-button-success" />
      </div>-->
    </div>




    <Column 
      v-for="c in model.getDisplayFields()"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="(c.class ? 'txt-' + c.class : '')"
      :bodyStyle="getHeaderStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="(c.class ? 'txt-' + c.class : '')"
    >
      <template #body="slotProps" >
        <span class="responsive-label">{{ getLabel(c) }}</span>
        <!--{{c}}-->
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>

          <div v-if="c.custom">
            <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge"  />
            <div v-if="c.custom == 'CheckBoxSelect'" >
              <Checkbox  v-model="list_select_item" :value="slotProps.data.code" @change="process_change_checkbox_item()" />
            </div>
            <div v-if="c.custom == 'SelectionText'"  v-html="callbackDataSelect( c ,slotProps.data)"></div>
          </div>
          <span v-else v-html="getData(c, slotProps.data)" :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"></span>
        </div>

      </template>
      <template v-if="c.filter" #filter>
        <div v-if="c.custom">
        <Checkbox v-if="c.custom == 'CheckBoxSelect'"  v-model="checkBoxAll" :binary="true" @change="process_change_checkbox_all($event)" />
        </div>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[$CommonList.getFieldFilter(c)]"
          class="p-column-filter"
          :placeholder="getFilterPlaceHolder(c)"
          @keyup="onFilter($event,c.filter.type,c.key)"
        /><!-- :keyup="onFilter(c.filter.type,c.key)"-->
        <!--v-model="filters[getField(c)]"-->
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"
          @change="onFilter($event,c.filter.type,c.key)"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)"
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
<!--        <Calendar
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false" 
          :monthNavigator="true" 
          :yearNavigator="true" 
          yearRange="2000:2030" 
          dateFormat="yy-mm-dd" 
          id="date_from" 
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :showIcon="true" 
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          @date-select="select_date($event,getField(c))"
          :manualInput="false"
          />-->
        <Calendar
            v-if="c.filter.type == 'DateRange'"
            :showOnFocus="true"
            :monthNavigator="true"
            :yearNavigator="true"
            yearRange="2000:2030"
            dateFormat="dd/mm/yyyy"
            v-model="filters[getField(c)]"
            class="p-column-filter"
            :showIcon="true"
            selectionMode="range"
            appendTo="body"
            :showClear="true"
            :showButtonBar="true"
            @clear-click="onFilter($event,c.filter.type,c.key)"
            @date-select="onFilter($event,c.filter.type,c.key)"
            :manualInput="false"
        />
      </template>
    </Column>
    <Column
      v-if="actions"
      headerStyle="width: 10em; text-align: center"
      bodyStyle="width: 10em; text-align: center"
    >
      <template #body="slotProps">
        <span class="p-column-title">Actions</span>
        {{ slotProps.data.color }}
        <Button
          v-for="(a, k) in actions.rows(slotProps.data)"
          :key="k"
          type="button"
          :icon="getIcon(a.icon)"
          :class="getClass(a.name)"
          :disabled="getDisabled(a.disabled)"
          style="margin: 2px"
          v-on:click="actionsClicked(k, a, slotProps)"
        ></Button>
      </template>
      <template #header="slotProps">
        <Button
          v-if="actions.header"
          type="button"
          :icon="getIcon(actions.header.icon)"
          :class="getClass(actions.header.name)"
          v-on:click="actionsClicked(actions.header.name, actions.header, slotProps)"
        ></Button>
      </template>
    </Column>
    <template #empty>
      {{ $t('datatable.empty') }}
    </template>
    <template #loading>
      {{ $t('datatable.loading') }}
    </template>
  </DataTable>
</template>

<script>
import moment from "moment-timezone";
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
//import ApiRepository from "@/core/ApiRepository";
import AuthenticationApp from "@/core/AuthenticationApp";
export default {
  props: {
    model: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
  },
  data() {
    return {
      //filters: {},
      date_filter:null,
      list_map_code_order: {},
      countDropdown: 0,
      pagination: true, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '', // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      SelectedCompany: null,
      listDataService:null,
      filters: {},
      filtersCustom: [],
      filtersCustomOutside: [],
      company: [],
      lazyParams: {},
      page_transaction:null,
      firstRecordIndex:0,
      list_select_item:[],
      checkBoxAll:false,
      delivery_personnel_map:{},
      vehicle_delivery_map:{},
      key_table:0,
    };
  },
  async created() {
    console.log("modelmodelmodelmodelmodelmodelmodelmodelmodel:",this.model)
    console.log(this.tableData);
    this.test = this.tableData;
    //this.listDataService = await this.dataList();
    console.log("this.optionsthis.options:",this.options)
    if (!this.$commonFuction.isEmpty(this.options)){
      if (!this.$commonFuction.isEmpty(this.options.delivery_personnel)){
          var delivery_personnel = this.options.delivery_personnel
          this.delivery_personnel_map =  this.map_option(delivery_personnel);
      }
      if (!this.$commonFuction.isEmpty(this.options.vehicle_delivery)){
        var vehicle_delivery = this.options.vehicle_delivery
        this.vehicle_delivery_map =  this.map_option(vehicle_delivery);
      }
    }
  },
  async mounted () {
    if (this.$commonFuction.isEmpty(this.filters['date_filter'])){
      console.log("dddddddddddddddddddddddddddd")
      const firstDayOfMonth = moment().startOf('month').format("YYYY-MM-DD");
      const currentDate = moment().format("YYYY-MM-DD");
      this.filters.date_filter = [new Date(firstDayOfMonth), new Date(currentDate)]
      //this.filtersCustom.date_filter = await this.$CoreService.convertFilter('DateRange','date_filter',this.filters);
      await this.initFilterOutside('DateRange','date_filter')
    }
    var whereAttach ={}
    var listDisplayCompany = AuthenticationApp.getListCompany();
    if (listDisplayCompany != null) {
      //order_shipping(where: {order_shipping_items: {order: {company_id: {_in: ""}}}})
      whereAttach.id =  {_in: listDisplayCompany};
    }
    var listData =await this.$CoreService.getCompanyFilter(whereAttach);
    this.company = listData;
    if (this.company.length > 0){
      this.filters['company_filter'] = this.company[0]["id"]
      await this.initFilterOutside('select','company_filter')
    }
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    //  filtersTmp:this.filters
    };
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
        console.log("this.lazyParamsthis.lazyParams:"+this.page_transaction,this.lazyParams);
         this.firstRecordIndex = this.lazyParams.first;
        console.log("this.lazyParams:22222222",this.lazyParams);
       /* if (this.lazyParams){
          console.log("this.lazyParams.filters",this.lazyParams.filters);

        }*/
      }
    }
    this.loading = false
    this.countDropdown++;
    this.onLazyEvent();
  },
  methods: {
    getLableCompany(data) {
      return `${data?.code ? data.code + " - " : ""} ${data.name}`;
    },
    map_option(list_data){
      var list_map = {}
      for (var i=0;i<list_data.length;i++){
       // console.log("list_data[i]list_data[i]:",list_data[i])
        var value = list_data[i]["value"]
        //console.log("valuevaluevalue:",value)
        var label = list_data[i]["label"]
        list_map[value]=label
      }
      //console.log("list_maplist_map",list_map)
      return list_map;
    },
    process_change_checkbox_item(){
      this.process_emit_check_box()
      return 1;
    },
    process_change_checkbox_all(event){
      console.log("444444444444444")
      //console.log("process_change_checkbox_all:",this.checkBoxAll)
      if (event);
      if (this.checkBoxAll == true){
        console.log("listDataService",this.listDataService)
        var list_code_order = [];
        for (var i=0;i<this.listDataService.length;i++){
          var code = this.listDataService[i]['code']
          list_code_order.push(code);
        }
        this.list_select_item = list_code_order;

      }else {
        this.list_select_item = [];
      }
      this.process_emit_check_box()
    },
    process_emit_check_box(){
      var obj = {
        list_select_item:this.list_select_item,
        list_map_code_order:this.list_map_code_order,
      }
      this.$emit("action-list_select_item", obj);
    },
    callbackDataSelect(c,data){
      console.log("delivery_personnel_map:",this.delivery_personnel_map)
      console.log("vehicle_delivery_map:",this.vehicle_delivery_map)
      var text = "";
      switch(c.key) {
        case 'total_number_request':
          var total_number_request =  (!this.$commonFuction.isEmpty(data.total_number_request)) ? data.total_number_request:0;
          var total_number_process =  (!this.$commonFuction.isEmpty(data.total_number_process)) ? data.total_number_process:0;
          if (total_number_request>0 ){
            text = total_number_process+"/"+total_number_request;
          }else {
            text = "-";
          }
          break;
        case 'current_order_status_id':
           text =  (!this.$commonFuction.isEmpty(data.order_status)) ? data.order_status.name:'';
          break;
        case 'total_deliveries':
          text =  (!this.$commonFuction.isEmpty(data.total_deliveries)) ? data.total_deliveries:'';
          break;
        case 'delivery_personnel':
          if (data.order_ship_item){
            if (data.order_ship_item.order_shipping_key){
              var uid = data.order_ship_item.order_shipping_key.uid;
              text = this.delivery_personnel_map[uid]
            }
          }
          break;
        case 'vehicle_delivery':
          if (data.order_ship_item){
            if (data.order_ship_item.order_shipping_key){
              var vehicle_id = data.order_ship_item.order_shipping_key.vehicle_id;
              text = this.vehicle_delivery_map[vehicle_id]
            }
          }
          break;
        default:
      }
      return text;
    },
    select_date(event,col_name) {
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if(col.inputOptions?.mode == 'icon') {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange)  f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      //console.log("colcolcol:",col);
      //console.log("rowrowrowrow:",row);
      var value = '';
      if (col.type == "Date" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy");
      } else if (col.type == "Datetime" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy HH:mm:ss");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm - DD/MM/yyyy");
      } else if(col.type == "Hour" && row[col.key]){
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
        var filtered = col.options.filter( a => a.value  === row[col.key]);
        if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
          value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
        }
      } 
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      return this.$CoreService.getFilterCommonPlaceHolder(col);
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams:this.lazyParams
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
   async dataList(lazyParams) {
      console.log('lazyParams');
      console.log('lazyParams:',lazyParams);
     var offset = lazyParams.first;
     var limit = lazyParams.rows;
     console.log("this.model.tableName:",this.model.tableName)
     var gqLQueryList = DataServices.getList(this.model.tableName);
     var where = {};
     var filters = lazyParams.filters;
     console.log('filtersfiltersfilters:',filters)
     if (filters){
       for (const [key, value] of Object.entries(filters)) {
         if (!this.$commonFuction.isEmpty(value.value)) {
           if (value.type_input == 'input') {
             if (key == 'fullname'){
               where['plain_fullname'] = {_iregex: this.$commonFuction.search(value.value)};
             }else {
               where[key] = {_iregex: this.$commonFuction.search(value.value)};
             }
           } else if (value.type_input == 'select') {
             if (key == 'name_group_filter') {
               where['group_id'] = {_eq: value.value};
             }else if (key == 'company_filter') {
               if (this.$commonFuction.isEmpty(where.order)){
                 where.order = {}
               }
               if (value.value != '-1'){
                 where.order = {
                   company_id: {_eq: value.value}
                 }
               }
             }else  {
               where[key] = {_eq: value.value};
             }
           }else if (value.type_input == 'DateRange'){
             if (key == 'date_filter'){
               if(value.value !== undefined && value.value !== null){
                 // console.log("dsadasdas");
                 where['updated_at'] = {'_gte': value.value.from,'_lt': value.value.to}
               }
             }else {
               if(value.value !== undefined && value.value !== null){
                 // console.log("dsadasdas");
                 where[key] = {'_gte': value.value.from,'_lt': value.value.to}
               }
             }
           }
         }
       }
     }
     if ( !(!this.$commonFuction.isEmpty(this.orderBy)  && this.orderBy.length >0) ){
      this.orderBy = {created_at: 'desc'}
     }
     var resData = await this.$apollo.mutate({
       mutation: gqLQueryList,
       variables: {
         where_key:where ,
         offset:offset,
         limit:limit,
         orderBy:this.orderBy
       }
     });
     var listData = resData.data[this.model.tableName];

     var gqLQueryCount = DataServices.countData(this.model.tableName);
     var countRes = await this.$apollo.mutate({
       mutation: gqLQueryCount,
       variables: {
         where_key:where
       }
     });
     var total = countRes.data[this.model.tableName+'_aggregate']['aggregate']['count'];

     var dataRes = {
       listData: listData,
       total: total,
     };
     console.log('dataRes:',dataRes);
     return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      console.log('this.lazyParams:', this.lazyParams)
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then(async data => {
        console.log("datadata:", data)
        //var count = 0;
        if (data.listData.length < 1) {
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;
        }
        var stt = this.lazyParams.first;
        //var list_id = []
        var list_map_code_order = {}
        for (var i = 0; i < data.listData.length; i++) {
          stt++;
          data.listData[i].stt = stt;
          list_map_code_order[data.listData[i].code] = data.listData[i]
          if (!this.$commonFuction.isEmpty(data.listData[i].order)){
            data.listData[i].date_line_editor = data.listData[i].order.date_preparation;
          }
          //list_id.push( data.listData[i].id)
        }
        this.list_map_code_order = list_map_code_order
        /*
        var dataRequest = {
          list_id: list_id,
        };
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        var item_order_ship = await ApiRepository.post('/_api/order/get-order-ship', dataRequest, {headers});
        var order_ship_map  = (item_order_ship["data"]["data"]) ? item_order_ship["data"]["data"]:null
        console.log("order_ship_map:",order_ship_map)
       for (var j = 0; j < data.listData.length; j++) {
          var order_id = data.listData[j].id
          if (order_ship_map){
            var order_ship_item = order_ship_map[order_id]
            if (!this.$commonFuction.isEmpty(order_ship_item)){
              data.listData[j].order_ship_item = order_ship_item
              console.log("order_ship_item.freight_key:",order_ship_item.freight_key)
              if (!this.$commonFuction.isEmpty(order_ship_item.freight_key)){
                data.listData[j].address_delivery1 = order_ship_item.freight_key.address
               // data.listData[j].address_delivery1 = 'asdasdasd'
              }
            }

          }
        }*/
        that.listDataService = data.listData;
        that.totalRecords = data.total;
        that.loading = false;
        that.model.processData(data.listData)
        //that.key_table++
      });
    },
    onPage(event) {
      console.log("eventeventeventevent",event);
      this.lazyParams = event;
      this.onLazyEvent();
    },
    async onFilter(event,type_input,key) {
      //this.loading = true;
      this.tableData = this.test;
      console.log('this.test',this.test);
      console.log('this.test',this.tableData);
      console.log('tableData',this.whereKeyList);
      console.log('filters:',this.filters);
      if(type_input == "DateRange"){
        if(this.filters[key] !== null && this.filters[key][1]== null){
          return false;
        }
      }
      // this.filtersCustom[key] = {
      //   type_input:type_input,
      //   value:this.filters[key],
      // };

      // console.log('this.filtersCustom:',this.filtersCustom);
      // this.lazyParams.first = 0;
      // this.onLazyEvent();
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      this.lazyParams.first = 0;

      this.onLazyEvent();

      /*setTimeout(() => {
        this.customers = this.datasource.slice(event.first, event.first + event.rows);
        this.loading = false;
      }, 500);*/
    },
    sortTable(event) {
      var list_order_by = (!this.$commonFuction.isEmpty(event.multiSortMeta)) ? event.multiSortMeta:[];
      console.log("sortTable eventeventevent:",event)
      console.log("list_order_bylist_order_by:",list_order_by)
      if (list_order_by.length > 0 ){
        this.orderBy = [];
        var list_custom_sort = [];
        for (var i=0;i<list_order_by.length;i++){
          var sortby = 'asc_nulls_first'
          this.orderBy = {}
          if(list_order_by[i].order == -1){
            sortby = 'desc_nulls_last'
          }
          var name_column_sort = list_order_by[i].field
         /* if (name_column_sort == 'serviceCode'){
            name_column_sort = 'code';
          }else if (name_column_sort == 'created_date'){
            name_column_sort = 'created_date';
          }else if (name_column_sort == 'processStatus'){
            name_column_sort = 'status';
          }*/


          var itemDataSort = {};
          /*if (name_column_sort == 'organizationRequest'){
            itemDataSort["request_organization"] = {obj_view_organization:{name:sortby}} ;
          }else if (name_column_sort == 'organizationAppraisal'){
            itemDataSort["appraisal_organization"] = {obj_view_organization:{name:sortby}} ;
          }else if (name_column_sort == 'products'){
            itemDataSort["obj_view_appraisal"] = {text_appraisal_product_total:sortby};
          }
          else {
            itemDataSort[name_column_sort] = sortby;
          }*/
          //itemDataSort[name_column_sort] = sortby;
          if (name_column_sort =='date_line_editor'){
            if(list_order_by[i].order != -1) {
              itemDataSort['date_line_editor'] = "desc_nulls_last";
               list_custom_sort.push(itemDataSort);
              // itemDataSort = {};
              // itemDataSort['state_name_suggest'] = "desc_nulls_last";
              // list_custom_sort.push(itemDataSort);
              // itemDataSort = {};
              // itemDataSort['district_name_suggest'] = "desc_nulls_last";
              // list_custom_sort.push(itemDataSort);
            }else {
              itemDataSort['date_line_editor'] = "asc_nulls_first";
               list_custom_sort.push(itemDataSort);
              // itemDataSort = {};
              // itemDataSort['state_name_suggest'] = "asc_nulls_first";
              // list_custom_sort.push(itemDataSort);
              // itemDataSort = {};
              // itemDataSort['district_name_suggest'] = "asc_nulls_first";
              // list_custom_sort.push(itemDataSort);
            }
          }else {
            itemDataSort[name_column_sort] = sortby;
          }

        }
        this.orderBy = list_custom_sort;
        console.log("this.orderBythis.orderBy:",this.orderBy)
      }
      this.lazyParams.first = 0;
      this.onLazyEvent()
    },
    async initFilterOutside(type_input,key) {
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      this.lazyParams.first = 0;
    },
    async onFilterOutside(type_input,key) {
      //this.loading = true;
      /*this.tableData = this.test;
      console.log('this.test',this.test);
      console.log('this.test',this.tableData);
      console.log('tableData',this.whereKeyList);
      console.log('filters:',this.filters);*/
      /*if(type_input == "DateRange"){
        if(this.filters[key] !== null && this.filters[key][1]== null){
          return false;
        }
      }*/
      // this.filtersCustom[key] = {
      //   type_input:type_input,
      //   value:this.filters[key],
      // };

      // console.log('this.filtersCustom:',this.filtersCustom);
      // this.lazyParams.first = 0;
      // this.onLazyEvent();
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      this.lazyParams.first = 0;

      this.onLazyEvent();

      /*setTimeout(() => {
        this.customers = this.datasource.slice(event.first, event.first + event.rows);
        this.loading = false;
      }, 500);*/
    },
  }
};
</script>
<style lang="scss">
.p-paginator-bottom{padding: 0 !important;}
//.div_search{
//  display: none!important;
//}
@media screen and (min-width: 1100px) {
  .p-paginator-bottom {
    padding: 0 !important;
  }
  .icon {
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .manager-title {
    font-size: 24px !important;
  }

  //do rong cot
  .txt-width_135 {
    max-width: 135px !important;
  }
  .txt-width_270 {
    max-width: 270px !important;
  }
  .div_button_add {
    display: none !important;
  }
  .p-column-filter {
    width: 100%;
  }
  txt-width_135 {
    max-width: 135px !important;
  }
  .p-dropdown-label {
    height: 45px !important;
  }
  .input_text {
    width: 372px !important;
    height: 45px !important;
    //padding: 12px!important;
    border: 1px solid #D0D4D9;
  }
  .Calendar {
    width: 372px !important;
    //height: 48px!important;
  }
  .div_company {
    margin: 0px 0px 0px 12px !important;
  }
  .label_company {
    font-weight: bold !important;
    padding: 0px !important;
  }
  .p-inputtext {
    padding: 10px !important;
  }
  .div_search {
    display: flex !important;
    margin-bottom: 12px !important;
    //justify-content: space-between;
  }
  .p-col {
    padding: 0px !important;
  }
  .margin_botton {
    margin-bottom: 22px !important;
  }
  .p-button-success {
    background: #C40380;
  }
  div.p-dialog {
    width: 600px !important;
  }
  .width_input_popup {
    width: 100% !important;
  }
  .div_pc {
    width: 372px !important;
  }
  // .p-dropdown-items-wrapper {
  //   max-width: 300px !important;
  // }
}





// responsive
// list
@media screen and (max-width: 600px) {
  //.layout-wrapper .layout-sidebar{
  //  left: -1000px;
  //}
  .p-component *{
    text-align: right!important;
  }
  .responsive-label{
    display: block!important;
  }
  .responsive-label {
    font-weight: bold!important;
    display: flex!important;
    text-align: left!important;
    //margin-right: 50px!important;
    width: 50%!important;


  }
  .p-column-title{
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;
    //padding: 11px!important;

  }
  .common{
    display: flex!important;
    width: 50%!important;
    justify-content: space-between!important;
  }
  .p-button-edit{
    margin-right: 13px!important;
    width: 38px!important;
    height: 32px!important;
  }
  .p-button-danger{
    text-align: right!important;
    width: 38px!important;
    height: 32px!important;
  }
  .action{
    display: flex!important;
  }
  // tim kiem
  .search{
    padding-left: 0px!important;
    display: block!important;
  }
  .input_search{
    width: 100%!important;
    height: 48px!important;
    border: 1px solid #D0D4D9;
  }
  .text_align{
    display: block!important;
    text-align: left!important;
    font-size: 16px!important;
    font-weight: bold;
  }
  // button add
  .div_button_add{
    display: flex!important;
    justify-content: flex-end!important;
    margin-top: 12px!important;
  }
  .button_add{
    width: 38px!important;
    height: 38px!important;
    background-color: #00973D;
    margin-left: 12px!important;
  }
  #search{
    padding:12px!important;
    text-align: left!important;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td{
    display: flex !important;
    //align-items: flex-end!important;
    justify-content: space-between!important;
  }



  //  ------------------------------------------------
  .div_search{
    display: block!important;
    margin-bottom: 12px!important;
    //justify-content: space-between;
  }
  .width_mobi{
    width: 100%!important;
    height: 45px!important;
  }
  .p-button-success{
    margin: 12px 0px 0px 0px !important;
    background: #C40380 !important;
    border: 1px solid #C40380!important;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child{
    border: none!important;
  }
  .label_company{
    text-align: left!important;
  }
  .p-col-fixed{
    padding: 0px!important;
    margin-top: 7px!important;
  }
  label{
    margin: 0px 0px 7px 0px!important;
  }
  .p-dropdown-items-wrapper{
    max-width: 300px!important;
  }

  .p-placeholder{
    display: flex!important;
  }

  .p-selectable-row{
    border: none!important;
  }


}





@media screen and (max-width: 950px) {
  //.layout-wrapper .layout-sidebar{
  //  left: -1000px!important;
  //}
  .p-component *{
    text-align: right!important;
  }


  .responsive-label {
    font-weight: bold!important;
    display: flex!important;
    text-align: left!important;
    //margin-right: 50px!important;
    width: 50%!important;
  }
  .p-column-title{
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;
    justify-content: space-between!important;
    align-items: center!important;
    //padding: 11px!important;
  }
  .common{
    display: flex!important;
    width: 50%!important;
    justify-content: space-between!important;
  }
  .p-button-edit{
    margin-right: 13px!important;
    width: 38px!important;
    height: 32px!important;
  }
  .p-button-danger{
    text-align: right!important;
    width: 38px!important;
    height: 32px!important;
  }
  .action{
    display: flex!important;
  }


  // tim kiem
  .search{
    display: block!important;
    padding-left: 0px!important;
  }
  .input_search{
    width: 100%!important;
    height: 48px!important;
    border: 1px solid #D0D4D9;
  }
  .text_align{
    display: block!important;
    text-align: left!important;
    font-size: 16px!important;
    font-weight: bold;
  }
  // button add
  .div_button_add{
    display: flex!important;
    justify-content: flex-end!important;
    margin-top: 12px!important;
  }
  .button_add{
    width: 38px!important;
    height: 38px!important;
    background-color: #00973D;
    margin-left: 12px!important;
  }
  #search{
    padding:12px!important;
    text-align: left!important;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td{
    display: flex !important;
    //align-items: flex-end!important;
    justify-content: space-between!important;
  }

  //  ----------------------------------------------------
  .div_search{
    display: block!important;
    margin-bottom: 12px!important;
  }
  .width_mobi{
    width: 100%!important;
    height: 45px!important;
  }
  .p-button-success{
    margin: 12px 0px 0px 0px !important;
    background: #C40380 !important;
    border: 1px solid #C40380!important;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child{
    border: none!important;
    font-weight: bold!important;
  }
  .label_company{
    text-align: left!important;
  }
  .p-col-fixed{
    padding: 0px!important;
    margin-top: 7px!important;
  }
  label{
    margin: 0px 0px 7px 0px!important;
  }
  .p-dropdown-items-wrapper{
    max-width: 300px!important;
  }
  .p-dropdown-label{
    display: flex!important;
  }
  .p-component{
    text-align: left!important;
  }
  .p-selectable-row{
    border: none!important;
  }

}
</style>


<style lang="css">
@media screen and (min-width: 1000px) {
  .p-button-success {
    background: #C40380 !important;
    height: 45px !important;
    margin: 35px 0px 0px 436px !important;
    border: 1px solid #C40380 !important;
  }

  .p-dialog {
    justify-content: center !important;
  }
}
@media screen and (max-width: 600px) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.action_border {
    border: 1px solid #EEEEEE !important;
  }
}

@media screen and (max-width: 1000px) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.action_border {
    border: 1px solid #EEEEEE !important;
  }
}
</style>