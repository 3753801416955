<!--:totalRecords="totalRecords"
:lazy="true"
--><!--:lazy="true"-->
<!--@filter="onFilter($event)"-->
<!--:filters="filters"-->
<!--@filter="onFilter($event)"-->
<!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
    <template>
      <div class="style-list-order">
    <div class="responsive-container w-100">
      <div class="responsive-filter-label">Đơn hàng</div>
        <!-- <div class="p-inputgroup"> -->
        <div class="d-flex justify-content-between">
          <InputText
          type="text"
          v-model="filters['code']"
          class="text-box mb-3"
          placeholder="Nhập"
          @keyup="onFilter($event, responsiveFilter.filter.type, responsiveFilter.key)"
          />
          <BarCodeScan @success_scan="success_scan"/>
        </div>
        <!-- </div> -->
      </div>
      
      <DataTable
      :key="key_table"
    :value="listDataService"
    :lazy="true"
    class="p-datatable-responsive"
    selectionMode="single"
    data-key="id"
    :autoLayout="true"
    @page="onPage($event)"
      @sort="sortTable($event)"
    :paginator="pagination"
    :totalRecords="totalRecords"
    :rows="paginationRows"
    :loading="loading"
    :scrollable="(scrollHeight !== '' ? true : false) "
    :scrollHeight="scrollHeight"
      sortMode="multiple"
    filterMatchMode="startsWith"
    :first="firstRecordIndex"
    :rowsPerPageOptions="rowsPerPageOptions"
    :currentPageReportTemplate=" $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM+' {totalRecords}'"
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >
    <Column 
      v-for="c in model.getDisplayFields()"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="(c.class ? 'txt-' + c.class : '')"
      :bodyStyle="getHeaderStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="(c.class ? 'txt-' + c.class : '')"
    >
      <template #body="slotProps" >
        <span v-if="c.key != 'check_box'" class="responsive-label">{{ getLabel(c) }}</span>
        <!--{{c}}-->
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>

          <div v-if="c.custom">
            <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge"  />
            <div v-if="c.custom == 'CheckBoxSelect'"  >
              <img v-if="list_order_code.indexOf(slotProps.data.code) != -1"  @click="remove_click(slotProps.data)" src="/assets/images/icon/icon_check_active.svg">
              <img v-else  @click="click_icon(slotProps.data)" src="/assets/images/icon/ic_check.svg">
<!--              <Checkbox   v-model="list_select_item" :value="slotProps.data.code" @change="process_change_checkbox_item()" />-->
            </div>
            <div @click.capture="click_tag_a(slotProps.data)" class="click_tag_a" v-if="c.custom == 'CutomCLickA'"  >{{slotProps.data.code}}</div>
            <div v-if="c.custom == 'SelectionText'"  v-html="callbackDataSelect( c ,slotProps.data)"></div>
          </div>
          <span v-else v-html="getData(c, slotProps.data)" :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"></span>
        </div>

      </template>
      <template v-if="c.filter" #filter>
        <div v-if="c.custom">
          <div v-if="c.custom == 'CheckBoxSelect'">
          <img  v-if="checkBoxAll == true" @click="click_icon_all(false)" src="/assets/images/icon/icon_check_active.svg">
          <img v-else  @click="click_icon_all(true)" src="/assets/images/icon/ic_check.svg">
          </div>
<!--          <img  @click="click_icon(slotProps.data)" src="/assets/images/icon/ic_check.svg">-->
<!--        <Checkbox v-if="c.custom == 'CheckBoxSelect'"  v-model="checkBoxAll" :binary="true" @change="process_change_checkbox_all($event)" />-->
        </div>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[$CommonList.getFieldFilter(c)]"
          class="p-column-filter"
          :placeholder="getFilterPlaceHolder(c)"
          @keyup="onFilter($event,c.filter.type,c.key)"
        /><!-- :keyup="onFilter(c.filter.type,c.key)"-->
        <!--v-model="filters[getField(c)]"-->
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"
          @change="onFilter($event,c.filter.type,c.key)"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)"
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
        <!-- <Calendar
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false" 
          :monthNavigator="true" 
          :yearNavigator="true" 
          yearRange="2000:2030" 
          dateFormat="yy-mm-dd" 
          id="date_from" 
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :showIcon="true" 
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          @date-select="select_date($event,getField(c))"
          :manualInput="true"
          /> -->
        <Calendar
          :key="countCalendar"
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="true"
          :monthNavigator="true"
          :yearNavigator="true"
          yearRange="2000:2030"
          dateFormat="dd/mm/yyyy"
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :showIcon="true"
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          :showButtonBar="true"
          @clear-click="onFilter($event,c.filter.type,c.key)"
          @date-select="onFilter($event,c.filter.type,c.key)"
          :manualInput="false"
        />
      </template>
    </Column>
    <Column
      v-if="actions"
      headerStyle="width: 10em; text-align: center"
      bodyStyle="width: 10em; text-align: center"
    >
      <template #body="slotProps">
        <span class="responsive-label">Hành động</span>
        {{ slotProps.data.color }}
        <div>

          <Button
          v-for="(a, k) in actions.rows(slotProps.data)"
          :key="k"
          type="button"
          :icon="getIcon(a.icon)"
          :class="getClass(a.name)"
          :disabled="getDisabled(a.disabled)"
          style="margin: 2px"
          v-on:click="actionsClicked(k, a, slotProps)"
          ></Button>
        </div>
        </template>
        <template #header="slotProps">
        <Button
          v-if="actions.header"
          type="button"
          :icon="getIcon(actions.header.icon)"
          :class="getClass(actions.header.name)"
          v-on:click="actionsClicked(actions.header.name, actions.header, slotProps)"
        ></Button>
      </template>
    </Column>
    <template #empty>
      {{ $t('datatable.empty') }}
    </template>
    <template #loading>
      {{ $t('datatable.loading') }}
    </template>
  </DataTable>
  </div>
</template>

<script>
import moment from "moment-timezone";
//import gql from "graphql-tag";
import BarCodeScan from "./index_barcode.vue";
import DataServices from "@/core/DataServices";
import AuthenticationApp from "@/core/AuthenticationApp";
//import ApiRepository from "@/core/ApiRepository";
export default {
  props: {
    model: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
    filterDefaultCustom: Object,
  },
  components: {
    BarCodeScan,
  },
  data() {
    return {
      orderBy:{created_at: 'desc'},
      list_order_code:[],
      key_disable_check_box:true,
      //filters: {},
      list_map_code_order: {},
      countDropdown: 1,
      countCalendar: 1,
      pagination: true, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '', // chiều cao của trang danh sách
      loading: true,
      icon: false,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService:null,
      filters: {},
      filtersCustom: [],
      lazyParams: {},
      page_transaction:null,
      firstRecordIndex:0,
      list_select_item:[],
      checkBoxAll:false,
      delivery_personnel_map:{},
      vehicle_delivery_map:{},
      key_table:0,
      responsiveFilter: null,
    };
  },
  async created() {
    console.log("this.icon",this.icon)
    console.log("modelmodelmodelmodelmodelmodelmodelmodelmodel:",this.model)
    console.log(this.tableData);
    this.test = this.tableData;
    //this.listDataService = await this.dataList();
    console.log("this.optionsthis.options:",this.options)
    if (!this.$commonFuction.isEmpty(this.options)){
      if (!this.$commonFuction.isEmpty(this.options.delivery_personnel)){
          var delivery_personnel = this.options.delivery_personnel
          this.delivery_personnel_map =  this.map_option(delivery_personnel);
      }
      if (!this.$commonFuction.isEmpty(this.options.vehicle_delivery)){
        var vehicle_delivery = this.options.vehicle_delivery
        this.vehicle_delivery_map =  this.map_option(vehicle_delivery);
      }
    }
  },
  mounted () {
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    //  filtersTmp:this.filters
    };
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
        console.log("this.lazyParamsthis.lazyParams:"+this.page_transaction,this.lazyParams);
         this.firstRecordIndex = this.lazyParams.first;
        console.log("this.lazyParams:22222222",this.lazyParams);
        this.callbackFilter()
        window.history.pushState({"html":"","pageTitle":""},"", '/admin/list-order');
       // window.history.pushState({"html":"","pageTitle":""},"", '/admin/list-order');
       /* if (this.lazyParams){
          console.log("this.lazyParams.filters",this.lazyParams.filters);

        }*/
      }
    }
    this.loading = false
    this.countDropdown++;
    this.countCalendar++;
    console.log("this.countDropdowthis.countDropdow:",this.countDropdown)
    this.onLazyEvent();
    this.getResponsiveFilter();
  },
  methods: {
     callbackFilter(){
      var list_filter =  this.lazyParams.list_filter
      //var obj_filter = {};
      for (let i = 0; i < list_filter.length; i++) {
        var fil = list_filter[i]
        for (let key in fil) {
          var item_data = fil[key]
          this.filtersCustom[key] = fil[key]
          if (item_data.type_input == 'DateRange'){
            var date_to =  moment(item_data?.value?.to).subtract(24, 'hours').format("YYYY-MM-DD");
            var array_data_tmp = [new Date(item_data?.value?.from),new Date(date_to)];
            this.$set(this.filters, key,array_data_tmp );
          }else {
            this.$set(this.filters, key,fil[key].value );
          }
        }
      }
    },
    click_tag_a(data){
      var pageTransaction = (new Date()).getTime();

      var filter = this.lazyParams.filters
      var filter_root = this.filters ?? []
      console.log("filter_rootfilter_root11:",filter_root)
      var list_filter = []
      for (let key in filter) {
        //if (filter.hasOwnProperty(key)) { // Ensure the property belongs to the object itself, not inherited
        console.log(`wwwwwww ${key}: ${filter[key]}`);
        //}
        var itemdata={};
        itemdata[key] = filter[key];
        list_filter.push(itemdata)
      }
      this.lazyParams.list_filter = list_filter;
      this.lazyParams.filter_root = filter_root;
      console.log("payload.filterfilter",filter);
      console.log("payload.lazxsdsss",JSON.stringify(this.lazyParams));
      localStorage.setItem(pageTransaction.toString(), JSON.stringify(this.lazyParams));

      var url_ = `/admin/list-order/view/${data.id}?page_transaction=${pageTransaction}`
      window.open(url_, '_blank');
      //this.$router.push({ path: '/admin/list-order/view/'+data.id ,query: { key_tmp: (new Date()).getTime() } })
    },
    success_scan(data){
      /*alert("thành công");
      alert(data);*/
      this.filters['code'] = data
      //this.onLazyEvent();
      this.onFilter(null,'input','code')
    },
    click_icon(data){
      this.list_order_code.push(data.code)
      console.log("listDataService",this.listDataService[0].order_status)
    console.log("list_order_code",this.list_order_code)
      this.process_emit_check_box()
    },

    remove_click(data){
      var list_code = []
      for (var i = 0 ; i < this.list_order_code.length ; i++){
        if (data.code != this.list_order_code[i]){
          list_code.push(this.list_order_code[i])
        }
      }
      this.list_order_code = list_code
      this.process_emit_check_box()
    },

    click_icon_all(is_active){
      this.checkBoxAll = is_active
      var list_code = []
     if (this.checkBoxAll == true){

       for (var i = 0 ; i< this.listDataService.length ; i++){

         list_code.push(this.listDataService[i].code)
         console.log("saiwqwqw",this.listDataService[i].code)
       }
       console.log("list_order_code",list_code)
       this.list_order_code = list_code

     }
     else {
       console.log("saksaksa")
       this.list_order_code = []
     }
      this.process_emit_check_box()
    },

    getResponsiveFilter(){
      var displayFields = this.model.getDisplayFields();
      var tempFilter = [];
      for (var i = 0; i < displayFields.length; i++) {
        if (displayFields[i].key == "code") {
          tempFilter.push(displayFields[i]);
        }
      }
      this.responsiveFilter = tempFilter[0];
      // console.log("SHIBABA", this.responsiveFilter);
      // console.log("SHIBABABA", this.responsiveFilter.filter.type);
    },
    map_option(list_data){
      var list_map = {}
      for (var i=0;i<list_data.length;i++){
       // console.log("list_data[i]list_data[i]:",list_data[i])
        var value = list_data[i]["value"]
        //console.log("valuevaluevalue:",value)
        var label = list_data[i]["label"]
        list_map[value]=label
      }
      //console.log("list_maplist_map",list_map)
      return list_map;
    },
    process_change_checkbox_item(){
      this.process_emit_check_box()
      return 1;
    },
    process_change_checkbox_all(event){
      console.log("444444444444444")
      //console.log("process_change_checkbox_all:",this.checkBoxAll)
      if (event);
      if (this.checkBoxAll == true){
        console.log("listDataService",this.listDataService)
        var list_code_order = [];
        for (var i=0;i<this.listDataService.length;i++){
          var code = this.listDataService[i]['code']
          list_code_order.push(code);
        }
        this.list_select_item = list_code_order;

      }else {
        this.list_select_item = [];
      }
      this.process_emit_check_box()
    },
    process_emit_check_box(){
      var obj = {
        list_select_item:this.list_order_code,
        list_map_code_order:this.list_map_code_order,
      }
      this.$emit("action-list_select_item", obj);
    },
    callbackDataSelect(c,data){
      console.log("delivery_personnel_map:",this.delivery_personnel_map)
      console.log("vehicle_delivery_map:",this.vehicle_delivery_map)
      var prefix_code = data.code.substring(0, 2);
      var text = "";
      switch(c.key) {
        case 'total_number_request':
          var total_number_request =  (!this.$commonFuction.isEmpty(data.total_number_request)) ? data.total_number_request:0;
          var total_number_process =  (!this.$commonFuction.isEmpty(data.total_number_process)) ? data.total_number_process:0;
          if (total_number_request>0 ){
            text = total_number_process+"/"+total_number_request;
          }else {
            text = "-";
          }
          break;
        case 'current_order_status_id':
           text =  (!this.$commonFuction.isEmpty(data.order_status)) ? data.order_status.name:'';
          break;
        case 'total_deliveries':
          text =  (!this.$commonFuction.isEmpty(data.total_deliveries)) ? data.total_deliveries:'';
          break;
        case 'suggest_delivery_type':
          var suggest_delivery_type = (data.suggest_delivery_type) ? parseInt(data.suggest_delivery_type):null;
          if (suggest_delivery_type){
            if (suggest_delivery_type == 1){
              if (prefix_code == this.$constants.REFIX_CODE_ORDER_CK){
                text = this.$constants.DELIVERY_TYPE_TEXT[99].label;
              }else {
                text = this.$constants.DELIVERY_TYPE_TEXT[suggest_delivery_type].label;
              }
            }else {
              text = this.$constants.DELIVERY_TYPE_TEXT[suggest_delivery_type].label;
            }
          }else {
            text =""
          }
          break;
        case 'delivery_personnel':
          // if (data.order_ship_item){
          //   if (data.order_ship_item.order_shipping_key){
          //     var uid = data.order_ship_item.order_shipping_key.uid;
          //     // text = this.delivery_personnel_map[uid]
          //   }
          // }
          if (data.order_shipping_item){
            if (data.order_shipping_item.order_shipping) {
              if (data.order_shipping_item.order_shipping.user) {
                text = data.order_shipping_item.order_shipping.user.fullname;
                // console.log("delivery_personnel",data.code + "---------" + data.delivery_personnel)

              }
            }
          }
          // text = "21wqwq"
          break;
        case 'vehicle_delivery':
          console.log("AAA");

          if (data.order_shipping_item){
            if (data.order_shipping_item.order_shipping) {
              if (data.order_shipping_item.order_shipping.vehicle_delivery_id) {
                text = data.order_shipping_item.order_shipping.vehicle_delivery_id.name;
                console.log("vehicle_delivery",data.vehicle_delivery)
              }
            }
          }
          // if (data.order_ship_item){
          //   if (data.order_ship_item.order_shipping_key){
          //     var vehicle_id = data.order_ship_item.order_shipping_key.vehicle_id;
          //     text = this.vehicle_delivery_map[vehicle_id]
          //   }
          // }
          break;
        case 'date_expected':
            var date_expected = data.date_expected;
            var date_now = moment().format("YYYY-MM-DD");
            var date_expected_format = !this.$commonFuction.isEmpty(date_expected) ?  moment(date_expected).format("YYYY-MM-DD"):null;
            // console.log("DATE1", date_expected);
            // console.log("DATE2", date_now);
            // console.log("DATE3", date_expected_format);
            // console.log("DATECOMPARE", date_expected_format > date_now);
            if (date_expected_format) {
              var expired = moment(date_expected_format).isBefore(date_now);
              date_expected_format = moment(date_expected_format).format("DD-MM-YYYY");
              if (expired) {
                text = '<span style="color: #F5564E;">' + date_expected_format + '</span>';
              } else {
                text = date_expected_format;
              }
            }else {
              text = '';
            }
          break;
        default:
      }
      return text;
    },
    select_date(event,col_name) {
      console.log("even cua t dau", event, col_name);
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if(col.inputOptions?.mode == 'icon') {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange)  f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      console.log("colcolcol:",col);
      //console.log("rowrowrowrow:",row);
      if (row.code=='MHLMS2311.400' && col.key == 'date_expected'){
        console.log("sssssssssssssss:",!this.$commonFuction.isEmpty(row[col.key]));
      }
      var value = '';
      if (col.type == "Date" ) {
        value = !this.$commonFuction.isEmpty(row[col.key]) ? moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy"):'';
      } else if (col.type == "Datetime") {
        value =!this.$commonFuction.isEmpty(row[col.key]) ? moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy HH:mm:ss"):'';
      } else if (col.type == "CustomDate" ) {
        value =!this.$commonFuction.isEmpty(row[col.key]) ?  moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm - DD/MM/yyyy"):'';
      } else if(col.type == "Hour" ){
        value =!this.$commonFuction.isEmpty(row[col.key]) ?  moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm"):'';
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
        var filtered = col.options.filter( a => a.value  === row[col.key]);
        if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
          value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
        }
      } 
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      return this.$CoreService.getFilterCommonPlaceHolder(col);
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.lazyParams.filter_root = this.filters;
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams:this.lazyParams,
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
    are_arrs_match(arr1, arr2){
      return arr1.sort().toString() === arr2.sort().toString()
    },
   async dataList(lazyParams) {
      console.log('lazyParams');
      console.log('lazyParams:',lazyParams);
     var offset = lazyParams.first;
     var limit = lazyParams.rows;
     console.log("this.model.tableName:",this.model.tableName)
     var gqLQueryList = DataServices.getList(this.model.tableName);
     var where = {deleted:{_eq:false}};
     this.$AuthenticationApp.getStateLoginNew()
     var state_login = ( this.$AuthenticationApp.getStateLoginNew()) ? (( this.$AuthenticationApp.getStateLoginNew())):null;
      var role = state_login?.role ?? '';
      //var department_arr = this.$store?.getters?.department ?? [];
      var department_arr = state_login?.list_department ?? [];
      console.log("department_arrdepartment_arr",department_arr)
     if (!["supper_admin"].includes(role)) {
      if (this.are_arrs_match(department_arr,["ST001", "ST002", "ST003", "ST007", "ST013", "ST014", "ST016"])) {
        var new_department_arr = department_arr.filter(f=> f != "ST013");
        where._or = [{order_status: {code: {_in: new_department_arr}}}, {order_status: {code: {_eq: "ST013"}}, code: {_niregex: "GX|TL|NK|MH|PN|GN|CK"}}];
      } else if (this.are_arrs_match(department_arr, ["ST001","ST002","ST005","ST006","ST007","ST010","ST011"])) {
        where._or = [{order_status: {code: {_in: department_arr}}, code: {_niregex: this.$constants.REFIX_CODE_ORDER_RETRUN}}, {order_status: {code: {_in: ["ST001", "ST002"]}}, code: {_iregex: this.$constants.REFIX_CODE_ORDER_RETRUN}}];
      } else {
        where.order_status = {
          code: {_in: department_arr}
        }
      }
     }
     var listDisplayCompany = AuthenticationApp.getListCompany();
     if (listDisplayCompany != null)
      {
        where.company_id = {_in: listDisplayCompany};
      }
     var filters = lazyParams.filters;
     if (filters){
       for (const [key, value] of Object.entries(filters)) {
         if (!this.$commonFuction.isEmpty(value.value)) {
           if (value.type_input == 'input') {
              if (key == 'code_custom'){
                where.code =  {
                  _iregex: this.$commonFuction.search(value.value),
                }
              } else if (key == 'customer_name'){
                // where['customer_name'] = {_like: "%"+this.$commonFuction.SkipVN(value.value)+"%"};
                where.customer_name =  {
                  _iregex: this.$commonFuction.search(value.value),
               };
              } else  if (key == 'fullname'){
                where['plain_fullname'] = {_like: "%"+this.$commonFuction.SkipVN(value.value)+"%"};
              }else {
                where[key] = { _iregex: this.$commonFuction.search(value.value)}
              }
            } else if (value.type_input == 'select') {
             if (key == 'name_group_filter') {
               where['group_id'] = {_eq: value.value};
             }

             if (key == 'current_order_status_id') {
               where['current_order_status_id'] = {_eq: value.value};
             }

             if (key == 'total_deliveries') {
               where['total_deliveries'] = {_eq: value.value};
             }
             if (key == 'suggest_delivery_type') {
               if (value.value == 99){
                 //filter đơn chuyển kho
                 where['suggest_delivery_type'] = {_eq: 1};
                 where['prefix_order'] = {_eq: this.$constants.REFIX_CODE_ORDER_CK};
               }else if (value.value == 1){
                 where['suggest_delivery_type'] = {_eq: 1};
                 where['prefix_order'] = {_neq: this.$constants.REFIX_CODE_ORDER_CK};
               }
               else {
                 where['suggest_delivery_type'] = {_eq: value.value};
               }
             }

             if (key == 'total_number_request') {
               where['is_enough_total_documents'] = {_eq: value.value};
             }

             if (key == 'delivery_personnel') {
               console.log("delivery_personnel1122",value.value)
               if (!where['order_shipping_item']){
                 where['order_shipping_item'] = {}
               }
               if (!where['order_shipping_item']["order_shipping"]){
                 where['order_shipping_item']["order_shipping"] = {}
               }
               where['order_shipping_item']["order_shipping"]["uid"] = {_eq: value.value};
             }
             if (key == 'vehicle_delivery') {
               console.log("delivery_personnel1122",value.value)
               if (!where['order_shipping_item']){
                 where['order_shipping_item'] = {}
               }
               if (!where['order_shipping_item']["order_shipping"]){
                 where['order_shipping_item']["order_shipping"] = {}
               }
               where['order_shipping_item']["order_shipping"]["vehicle_id"] = {_eq: value.value};
             }

            /* if (key == 'vehicle_delivery') {
               console.log("vehicle_delivery111",value.value)
               where['vehicle_id'] = {_eq: value.value};
             }*/
            }else if (value.type_input == 'DateRange'){
              if(value.value !== undefined && value.value !== null){
                // console.log("dsadasdas");
                where[key] = {'_gte': value.value.from,'_lt': value.value.to}
              }
            } else if (value.type_input == 'Date'){
              if(value.value !== undefined && value.value !== null){
                // console.log("dsadasdas");
                where[key] = {_eq: value.value}
              }

            }
          }
        }
      }
     var resData = await this.$apollo.mutate({
       mutation: gqLQueryList,
       variables: {
         where_key:where ,
         offset:offset,
         limit:limit,
         orderBy:this.orderBy
       }
     });
     var listData = resData.data[this.model.tableName];

     var gqLQueryCount = DataServices.countData(this.model.tableName);
     var countRes = await this.$apollo.mutate({
       mutation: gqLQueryCount,
       variables: {
         where_key:where
       }
     });
     var total = countRes.data[this.model.tableName+'_aggregate']['aggregate']['count'];

     var dataRes = {
       listData: listData,
       total: total,
     };
     console.log('dataRes:',dataRes);
     return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      console.log('this.lazyParams:', this.lazyParams)
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then(async data => {
        console.log("datadata:", data)
        //var count = 0;
        if (data.listData.length < 1) {
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;
        }
        var stt = this.lazyParams.first;
        //var list_id = []
        var list_map_code_order = {}
        var list_customer_id = [];
        for (var i = 0; i < data.listData.length; i++) {
          stt++;
          data.listData[i].stt = stt;
          list_map_code_order[data.listData[i].code] = data.listData[i]
          var customer_id = data.listData[i]["customer_id"]
          if (!this.$commonFuction.isEmpty(customer_id)){
            list_customer_id.push(customer_id)
          }
          //list_id.push( data.listData[i].id)
        }
        var map_docs = null;//
        if (list_customer_id.length>0){
          var where_cus_docs = {customer_id:{_in: list_customer_id}}
          var dataSumCusDocs = await this.$CoreService.getSumCutomerDocs(where_cus_docs);
          map_docs = dataSumCusDocs["map_docs"]
        }
        var list_status_check_docs = ["ST001","ST002","ST003","ST004","ST005","ST006","ST007","ST008","ST009"]
        for (var j = 0; j < data.listData.length; j++) {
          var item_data_j = data.listData[j];
          var customer_id_ = item_data_j['customer_id']
          var code_order = item_data_j['code']
          var current_status_code = item_data_j['order_status']["code"]
          var prefix_code = code_order.substring(0, 2);
          if (prefix_code !=this.$constants.REFIX_CODE_ORDER_RETRUN){
            if (!this.$commonFuction.isEmpty(customer_id_)) {
              if (list_status_check_docs.indexOf(current_status_code) != -1) {
                data.listData[j].total_number_request = (map_docs[customer_id_]) ? parseInt(map_docs[customer_id_]["total_qty_delivery"]):0;
                data.listData[j].total_number_process = 0;
              }
            }
          }

        }
        this.list_map_code_order = list_map_code_order
        /*
        var dataRequest = {
          list_id: list_id,
        };
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        var item_order_ship = await ApiRepository.post('/_api/order/get-order-ship', dataRequest, {headers});
        var order_ship_map  = (item_order_ship["data"]["data"]) ? item_order_ship["data"]["data"]:null
        console.log("order_ship_map:",order_ship_map)
       for (var j = 0; j < data.listData.length; j++) {
          var order_id = data.listData[j].id
          if (order_ship_map){
            var order_ship_item = order_ship_map[order_id]
            if (!this.$commonFuction.isEmpty(order_ship_item)){
              data.listData[j].order_ship_item = order_ship_item
              console.log("order_ship_item.freight_key:",order_ship_item.freight_key)
              if (!this.$commonFuction.isEmpty(order_ship_item.freight_key)){
                data.listData[j].address_delivery1 = order_ship_item.freight_key.address
               // data.listData[j].address_delivery1 = 'asdasdasd'
              }
            }

          }
        }*/
        //reset checkbox empty
        this.list_order_code = []
        this.process_emit_check_box()

        that.listDataService = data.listData;
        that.totalRecords = data.total;
        that.loading = false;
        that.model.processData(data.listData);
        that.countDropdown++;
        that.countCalendar++;
        console.log("this.countCalendar:",this.countCalendar)

        //that.key_table++;
      });
    },
    onPage(event) {
      console.log("onPage eventeventeventevent",event);
      this.lazyParams = event;
      this.onLazyEvent();
    },
    async onFilter(event,type_input,key) {
      //this.loading = true;
      this.tableData = this.test;
      console.log('onFilter this.test',this.test);
      console.log('this.test',this.tableData);
      console.log('tableData',this.whereKeyList);
      console.log('filters:',this.filters);
      if(type_input == "DateRange"){
        if(this.filters[key] !== null && this.filters[key][1]== null){
          return false;
        }
      }
      // this.filtersCustom[key] = {
      //   type_input:type_input,
      //   value:this.filters[key],
      // };

      // console.log('this.filtersCustom:',this.filtersCustom);
      // this.lazyParams.first = 0;
      // this.onLazyEvent();
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      this.lazyParams.first = 0;

      this.onLazyEvent();

      /*setTimeout(() => {
        this.customers = this.datasource.slice(event.first, event.first + event.rows);
        this.loading = false;
      }, 500);*/
    },
    sortTable(event) {
      var list_order_by = (!this.$commonFuction.isEmpty(event.multiSortMeta)) ? event.multiSortMeta:[];
      console.log("sortTable eventeventevent:",event)
      console.log("list_order_bylist_order_by:",list_order_by)
      if (list_order_by.length > 0 ){
        this.orderBy = [];
        var list_custom_sort = [];
        for (var i=0;i<list_order_by.length;i++){
          var sortby = 'asc_nulls_first'
          this.orderBy = {}
          if(list_order_by[i].order == -1){
            sortby = 'desc_nulls_last'
          }
          var name_column_sort = list_order_by[i].field
         /* if (name_column_sort == 'serviceCode'){
            name_column_sort = 'code';
          }else if (name_column_sort == 'created_date'){
            name_column_sort = 'created_date';
          }else if (name_column_sort == 'processStatus'){
            name_column_sort = 'status';
          }*/


          var itemDataSort = {};
          /*if (name_column_sort == 'organizationRequest'){
            itemDataSort["request_organization"] = {obj_view_organization:{name:sortby}} ;
          }else if (name_column_sort == 'organizationAppraisal'){
            itemDataSort["appraisal_organization"] = {obj_view_organization:{name:sortby}} ;
          }else if (name_column_sort == 'products'){
            itemDataSort["obj_view_appraisal"] = {text_appraisal_product_total:sortby};
          }
          else {
            itemDataSort[name_column_sort] = sortby;
          }*/
          //itemDataSort[name_column_sort] = sortby;
          if (name_column_sort =='suggest_address_customer'){
            if(list_order_by[i].order != -1) {
              itemDataSort['country_name_suggest'] = "desc_nulls_last";
              list_custom_sort.push(itemDataSort);
              itemDataSort = {};
              itemDataSort['state_name_suggest'] = "desc_nulls_last";
              list_custom_sort.push(itemDataSort);
              itemDataSort = {};
              itemDataSort['district_name_suggest'] = "desc_nulls_last";
              list_custom_sort.push(itemDataSort);
            }else {
              itemDataSort['country_name_suggest'] = "asc_nulls_first";
              list_custom_sort.push(itemDataSort);
              itemDataSort = {};
              itemDataSort['state_name_suggest'] = "asc_nulls_first";
              list_custom_sort.push(itemDataSort);
              itemDataSort = {};
              itemDataSort['district_name_suggest'] = "asc_nulls_first";
              list_custom_sort.push(itemDataSort);
            }
          }else {
            itemDataSort[name_column_sort] = sortby;
          }

        }
        this.orderBy = list_custom_sort;
      }
      this.lazyParams.first = 0;
      this.onLazyEvent()
    },
  }
};
</script>
<style lang="scss">
.txt-xs-col{
  /* max-width: 60px !important; */
  min-width: 60px !important;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.txt-small-col {
  max-width: 120px !important;
  min-width: 120px !important;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.txt-medium-col {
  max-width: 150px !important;
  min-width: 150px !important;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.txt-large-col {
  max-width: 300px !important;
  min-width: 300px !important;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.list-button{
  background-color: rgba(227, 149, 182, 0.2);
  border-radius: 10px;
  padding-right: 16px !important;
  margin-bottom: 8px;
}

.p-sortable-column-icon, .p-column-title{
  display: inline !important;
}
.p-paginator-bottom{padding: 0 !important;}
.p-datatable.p-datatable-responsive {
    .p-datatable-tbody > tr > td {
      .p-checkbox{
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
      }
    }
  }
  @media (min-width: 961px) {

    .txt-mi-medium-col {
      max-width: 180px !important;
      min-width: 180px !important;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  .responsive-label,
  .responsive-container {
    display: none !important;
  }
}

@media (max-width: 960px) {
  .p-datatable.p-datatable-responsive {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

    .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      border: 0 none !important;
      width: 100% !important;
      float: left;
      clear: left;
      border: 0 none;

      .p-column-title {
        padding: 0.4rem;
        min-width: 30%;
        display: inline-block;
        margin: -0.4rem 1rem -0.4rem -0.4rem;
        font-weight: bold;
      }
      .p-sortable-column-icon{
        display: none;
      }
    }
  }
  .responsive-label {
    display: inline;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }
  .responsive-filter-label {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }
  .responsive-action-label {
    font-weight: bold;
    font-size: 1rem;
    padding-top: 0.5rem;
    margin-right: 1rem;
  }
  .text-box {
    width: 100% !important;
  }
  .txt-small-col,
  .txt-large-col,
  .txt-medium-col {
    max-width: 100% !important;
    min-width: unset !important;
  }
  .p-datatable.p-datatable-responsive {
    .p-datatable-tbody > tr > td {
      padding: 0 1em !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
    }
    .p-datatable-tbody > tr > td:last-child {
      padding-top: 11px !important;
      padding-bottom: 10px !important;
      border: 1pt solid #eeeeee !important;
      margin-bottom: 1rem !important;
      .responsive-label {
        margin-bottom: 0 !important;
        padding-top: 0.5rem !important;
      }
    }
  }
}
.p-datatable .p-sortable-column .p-sortable-column-icon{
  color: black;
  font-weight: bold;
  margin-bottom: 0.25rem !important;
}
.p-datatable {
  .p-datatable-thead > tr > td{
    .p-column-title{
      display: inline !important;
    }
  }
}

.p-dropdown-items-wrapper{
  // max-width: 300px!important;
   max-height: 350px!important;
}
.disabled-element {
  pointer-events: none;
  opacity: 0.5; /* Hoặc bất kỳ thuộc tính CSS nào khác để chỉ ra sự vô hiệu hóa */
}
.click_tag_a{
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 16px/21px Segoe UI;
  letter-spacing: 0px;
  color: #C40380;
  cursor: pointer;
}
.style-customer-order{
   div{
    span{
  -webkit-line-clamp: 3 !important;
   }
  }
}
</style>