<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div >
        <div>
          <label class="txt-right mt-3">Mã công ty <span class="span-cus">*</span></label>
          <v-select :clearable="false" v-model="selCompany" :options="listCompany" @search="fetchOptionsCompany" :get-option-label="(option) => option.code" class="text-box" placeholder="Chọn">
            <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
            </span>
          </v-select>
          <input class="input_tmp_validator" id="company_id">
        </div>
        
        <div>
          <label class="txt-right mt-3">Thời gian <span class="span-cus">*</span></label>
          <Calendar v-model="date_costs" id="date_costs" dateFormat="dd/mm/yy" class="text-box" placeholder="DD/MM/YYYY" appendTo="body" :showIcon="true" :showClear="true" :showButtonBar="true" />
        </div>
        <div class="col-lg-7 col-12 mt-3 row p-0 m-0">
          <div class="d-lg-flex d-none col-12 row p-0 m-0">
            <div class="col-lg-3 col-12 pl-0 pr-lg-2 pr-0">
              <label class=" mt-3">Tên dịch vụ <span class="span-cus">*</span></label>
            </div>
            <div class="col-lg-2 col-6 pl-lg-2 pr-lg-2 px-0">
              <label class=" mt-3">Mã dịch vụ</label>
            </div>
            <div class="col-lg-2 col-6 pl-lg-2 pr-lg-2 px-0">
              <label class=" mt-3">SL tính phí <span class="span-cus">*</span></label>
            </div>
            <div class="col-lg-4 col-6 pl-lg-2 px-0">
              <label class=" mt-3">Tạm tính</label>
            </div>
          </div>
          
          <div class="col-12 mb-3 row p-0 m-0" v-for="(item, index) in services" :key="index">
            <div class="col-lg-3 col-12 pl-0 pr-lg-2 px-0">
              <label v-if="index==0" class="d-lg-none d-flex txt-right mt-lg-3">Tên dịch vụ <span class="span-cus">*</span></label>
              <v-select id="select--service" :clearable="false" class="text-box w-100" v-model="item.selShipping" @option:selected="changeShippingCode(item)" :options="listShipping" @search="(search, loading) => { fetchOptionsShipping(search, loading)}" :get-option-label="(option) => option.name" placeholder="Chọn" @open="getListShipping()">
                <span slot="no-options" @click="$refs.select.open = false">
                      Không có dữ liệu
                  </span>
              </v-select>
            </div>
            <div class="col-lg-2 col-6 pl-lg-2 pr-lg-2 pl-0 pr-1">
              <label v-if="index==0" class="d-lg-none d-flex txt-right mt-lg-3">Mã dịch vụ</label>
              <InputText class="text-box w-100 disabled" v-model="item.code" disabled="disabled" />
            </div>
            <div class="col-lg-2 col-6 pl-lg-2 pr-lg-2 pl-1 pr-0">
              <label v-if="index==0" class="d-lg-none d-flex txt-right mt-lg-3">SL tính phí <span class="span-cus">*</span></label>
              <InputNumber
                    mode="decimal"
                    :minFractionDigits="4"
                    :maxFractionDigits="4"
                    locale="de-DE"
                  class="text-box w-100"
                  v-model="item.qty"
                  :min="0"
                  placeholder="Nhập"
                  @blur="changeItemQty(item)"
              />
            </div>
            <div class="col-lg-4 col-7 pl-lg-2 px-0 mt-lg-0 mt-1">
              <label v-if="index==0" class="d-lg-none d-block mt-lg-3">Tạm tính</label>
              <div class="p-inputgroup">
                  <InputNumber
                      locale="de-DE"
                      class="text-box w-100 disabled"
                      disabled="disabled"
                      v-model="item.price_total"
                      mode="decimal" :minFractionDigits="0" 
                  />
                  <span class="p-inputgroup-addon rounded" style="border: 1px solid #D0D4D9 !important">
                      đ
                  </span>
                  <Button
                      type="button"
                      icon="pi pi-trash"
                      class="ml-2 p-button-danger px-4 rounded"
                      @click="delRow(index)"
                      severity="danger"
                  />
              </div>
            </div>
            <!-- <div class="col-1 p-0 d-flex justify-content-end">
              <Button icon="pi pi-trash" class="p-button-danger" @click="delRow(index)"/>
            </div> -->
          </div>
          <input class="input_tmp_validator position-absolute bottom-0" id="services_id">
          <a class="text-primary-new pointer" @click="AddRow()">Thêm mới</a>
        </div>
      </div>

      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
            :label="$constants.TEXT_BUTTON.BACK"
            @click="backToList()"
            class="p-button-warning callback-btn"
          />
          <Button
            v-if="mode == 'edit'"
            :label="$constants.TEXT_BUTTON.EDIT"
            @click="saveData()"
            class="main-style-button"
          />
          <Button
            v-else
            :label="$constants.TEXT_BUTTON.ADD"
            @click="saveData()"
            class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
import DataServices from "@/core/DataServices";
// import gql from "graphql-tag";
import moment from "moment";
export default {
  props: {
    mode: String,
    model: Object,
  },
  components: {
  },
  data() {
    return {
      other_costs_services: [],
      company_id: null,
      selCompany: null,
      date_costs: null,
      services: [{
        id: null,
        selShipping: null,
        code: null,
        qty: null,
        price: null,
        price_total: null
      }],
      active: true,
      formMode: this.mode,
      list_group_role: [],
      saving: false,
      listCompany: [],
      listCompanyAll: [],
      listShipping: [],
      listShippingAll: [],
      loadEdit: false,
    };
  },
  async mounted() {
    await this.getListCompany();
  },
  async created() {
    if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
      await this.getData(this.$route.params.id);
    }
  },
  watch: {
    "selCompany"() {
      this.getListShipping();
      if (!this.loadEdit) {
        this.services.map(item =>{ 
            item.selShipping = null;
            item.price = null;
            item.code = null;
            item.price_total = null;
        });
      }
      this.loadEdit = false;
    }
  },
  methods: {
    changeItemQty(item) {
      item.price_total = item.qty ? item.price * item.qty : null;
    },
    AddRow() {
      this.services.push({
        id: null,
        selShipping: null,
        code: null,
        qty: null,
        price: null,
        price_total: null
      });
    },
    changeShippingCode(item) {
      item.code = item.selShipping?.code || null;
      item.price = item.selShipping?.price || null;
      item.price_total = item.qty ? item.price * item.qty : null;
    },
    delRow(index) {
      this.services.splice(index, 1);
    },
    async getListCompany(){
      var where_key = {
        deleted : {_eq : false}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('company',{"fields" : `id name code`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_unit = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_unit.push({id : e.id, name :e.name, code: e.code});
      }
      this.listCompany = list_unit;
      this.listCompanyAll = list_unit;
    },
    async fetchOptionsCompany(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('company',{"fields" : `id name code`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({id : e.id, name :e.name, code: e.code});
        }
        this.listCompany = list_unit;
      }else{
        // cosnole.log("dasdasd")
        this.listCompany = this.listCompanyAll
      }
      return this.listCompany;
    },
    
    async getListShipping(){
      if (this.$commonFuction.isEmptyObject(this.selCompany)) {
        this.listShipping = [];
        this.listShippingAll = [];
        return;
      }
      var where_key = {
        shipping_method_prices: {company_id: {_eq: this.selCompany?.id} },
        // id: {_nin: ids},
        deleted : {_eq : false},
        key : {_eq : 0},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('shipping_method',{"fields" : `id name text_code_name code shipping_method_prices(where: {company_id: {_eq: "${this.selCompany?.id}" } }) { price }`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_unit = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_unit.push({id : e.id, name :e.text_code_name, code: e.code, price: e.shipping_method_prices[0]?.price || 0});
      }
      this.listShipping = list_unit;
      this.listShippingAll = list_unit;
    },
    async fetchOptionsShipping(search, loading){
      console.log(loading);
      if (!this.selCompany?.id) {
        this.listShipping = [];
        this.listShippingAll = [];
        return;
      }
      if(search !== '' && search !== null){
        // var list_ids = [];
        // if (index != null) {
        //     list_ids = this.services.filter((e) => !this.$commonFuction.isEmptyObject(e.selShipping?.id) && index && this.services[index]?.selShipping?.id != e.selShipping?.id);
        // } else {
        //     list_ids = this.services.filter((e) => !this.$commonFuction.isEmptyObject(e.selShipping?.id));
        // }
        // var ids = list_ids.map(e => {return e.selShipping?.id;});
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          key : {_eq : 0},
          deleted : {_eq : false},
          // id: {_nin: ids},
          shipping_method_prices: {company_id: {_eq: this.selCompany.id} },
          text_code_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('shipping_method',{"fields" : `id name text_code_name code shipping_method_prices(where: {company_id: {_eq: "${this.selCompany.id}" } }) { price }`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({id : e.id, name :e.text_code_name, code: e.code, price: e.shipping_method_prices[0]?.price || 0});
        }
        this.listShipping = list_unit;
      }else{
        // cosnole.log("dasdasd")
        this.listShipping = this.listShippingAll
      }
      return this.listShipping;
    },
    
    
    backToList() {
      this.$emit("back");
    },
    async checkCode(code) {
      var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          code:{_ilike: "%"+this.$commonFuction.SkipVN(code).trim()+"%"},
          id: {_neq: this.$route.params.id},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('other_costs',{"fields" : `id company{name code} date_costs other_costs_services{shipping_method{id name code shipping_method_prices{price}}, qty}`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_sr = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_sr.push({id : e.id, name :e.name});
        }
        if (list_sr.length > 0) {
            return true;
        }
        return false;
    },
    validateDate(dateString) {
      const dateFormat = 'DD/MM/YYYY';  
      const parsedDate = moment(dateString, dateFormat, true);
      if (parsedDate.format(dateFormat) == "Invalid date") {
        return false;
      }
      // console.log("validating date", parsedDate, parsedDate.isValid(), parsedDate.format(dateFormat), dateString);
      return parsedDate.isValid();
    },
    async validateData() {
      this.validationErrors = {};
      
      let company_add = document.getElementById("company_id");
      if (
        this.$commonFuction.isEmptyObject(this.selCompany)
      ) {
        company_add.setCustomValidity("Mã công ty không được để trống!");
      } else {
        company_add.setCustomValidity("");
      }
      
      let date_costs_add = document.getElementById("date_costs");
      if (
        this.$commonFuction.isEmpty(this.date_costs)
      ) {
        date_costs_add.setCustomValidity("Thời gian không được để trống!");
      }  else if (!this.validateDate(this.date_costs)){
        date_costs_add.setCustomValidity("Vui lòng nhập đúng định dạng!");
      } else {
        date_costs_add.setCustomValidity("");
      }
      this.services = this.services.filter( m => { return (!this.$commonFuction.isEmptyObject(m.selShipping) || !this.$commonFuction.isEmpty(m.qty))})
      if (this.services.length == 0) this.AddRow();
      let services_add = document.getElementById("services_id");
      if (
        this.services.length < 1
      ) {
        services_add.setCustomValidity("Dịch vụ không được để trống!");
      } else {
        let check_price = this.services.filter( m => { return (!this.$commonFuction.isEmptyObject(m.selShipping) && !this.$commonFuction.isEmpty(m.qty))})
        if (check_price.length != this.services.length) {
          services_add.setCustomValidity("Tên dịch vụ và SL tính phí không được để trống!");
        } else {
          services_add.setCustomValidity("");
        }
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log("test=====================");
        inpObj.reportValidity();
        return true;
      }
    },
    async saveData() {
      // console.log(this.shipping_method_prices);
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        // this.saving = false;
        this.$commonFuction.is_loading(false);
        return;
      }

      if (Object.entries(this.validationErrors).length !== 0)
        this.$commonFuction.is_loading(false);

      // map each element of this.services with a key of selShipping.id and sum value of qty
      var services = this.services.reduce((acc, cur) => {
        acc[cur.selShipping.id] = (acc[cur.selShipping.id] || 0) + cur.qty;
        return acc;
      }, {});

      services = Object.entries(services).map(([key, value]) => {
        return {selShipping: {id: key}, qty: value};
      });
      
      var params = {
        other_costs_service: services.map(e => {return {shipping_method_id: e.selShipping?.id, qty: e.qty}}),
        company_id: this.selCompany?.id || null,
        date_costs: this.date_costs ? moment(this.date_costs, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
        active: this.active,
      };
      this.$emit("save", params);
      // }
    },
    async getData(id) {
      var { data } = await this.$apollo.query({
        query: DataServices.getList('other_costs',{"fields" : `id company{id name code} date_costs other_costs_services{shipping_method{id name code shipping_method_prices{price}}, qty}`}),
        variables: {
          where_key: {
            id: { _eq: id }
          },
        },
        fetchPolicy: "network-only",
      });
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        this.loadEdit = true;
        this.selCompany = this.models?.company ? {id: this.models?.company?.id, name: this.models?.company?.name, code: this.models?.company?.code} : null;
        this.date_costs = this.models.date_costs;
        //format date_costs displayed value to DD/MM/YYYY but don't change value of date_costs
        console.log("date_costs", moment(this.date_costs).format("DD/MM/YYYY"));
        this.date_costs = moment(this.date_costs).format("DD/MM/YYYY");
        this.services = this.models.other_costs_services.map(m => { return { selShipping: {id: m?.shipping_method.id, name: m?.shipping_method?.name, code: m?.shipping_method?.code, price: 0}, code: m?.shipping_method?.code, qty: m.qty, price_total: ((m.qty * m?.shipping_method?.shipping_method_prices[0]?.price) || null), price: m?.shipping_method?.shipping_method_prices[0]?.price || 0} });
        this.active = this.models.active;
      }
      this.componentKey++;
    }
    
  },
  /*apollo: {
        category: {
          query() {
            return DataServices.getList('category', {"fields" : "id name"});
          },
          update(data) {
            if(data.category){
              this.categories = data.category
            }
          },
          fetchPolicy: "network-only"
        },
      }*/
};
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.category-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container {
  height: 40px;
}

label {
  font-weight: bold;
}

.radio-option {
  margin-bottom: 0 !important;
}
.required:after {
  content: " *";
  color: red;
}

.vs--single:not(.vs--open) .vs__selected {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 85%;
  height: fit-content;
  align-self: center;
}

.vs__dropdown-option {
  min-width: max-content;
  width: 100%;
}
.bottom-0 {
  bottom: 0;
}
.disabled {
  background-color: #eeeeee;
}

.text-box.w-100{
  width: 100% !important;
}
#select--service ul {
  width: fit-content;
}
</style>