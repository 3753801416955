<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!--{{listData}}-->
        <BaseDatatable
          :key="componentKey"
          :tableData="listData"
          :model="dataModel"
          :options="options"
          :getFilterOptions="getFilterOptions"
          :actions="getRowActions()"
          :whereKeyList="whereKeyList"
          v-on:action-click="onActionClicked"
          @togglePopup="togglePopup"
          @filterLocation="callbackOptionsCustom"
        ></BaseDatatable>
        <Dialog
          :key="keyPopup"
          header="Số lượng khách hàng"
          :visible.sync="showCustomerDialog"
          :contentStyle="{ overflow: 'visible',maxHeight: '80vh', overflowY: 'auto' }"
          :modal="true"
        >
          <CustomerDatatable
            v-if="freightCustomerModel"
            :key="freightCustomerKey"
            :tableData="listData"
            :model="freightCustomerModel"
            :options="options"
            :actions="getRowActions()"
            :whereKeyList="whereKeyList"
            :obj_id="obj_id"
            v-on:action-click="onActionClicked"
          ></CustomerDatatable>
          <template #footer>
            <Button
              label="Xác nhận"
              @click="showCustomerDialog = false"
              icon="pi pi-check"
              class="p-button-success main-style-button"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from "@/core/DataServices";
import BaseDatatable from "./list";
import CustomerDatatable from "./list-freight-customer";
// import gql from "graphql-tag";
// import Popup from './popup'
export default {
  components: {
    BaseDatatable,
    CustomerDatatable,
    // Popup
  },
  data() {
    return {
      keyPopup: 0,
      freightCustomerKey: 0,
      obj_id: null,
      freightCustomerModel: null,
      showCustomerDialog: false,
      filterOptions: {},
      options: [],
      listData: [],
      modelName: "",
      dataModel: null,
      title: "",
      componentKey: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: "edit",
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      whereKeyList: {
        where_key: {
          /*accounts: {
              role: {_eq: "admin"},
              deleted: {_eq: false}
            },*/
          deleted: { _eq: false },
        },
      },
      page_transaction: null,
      role: {
        view: false,
        add: false,
        edit: false,
      },
    };
  },
  mounted() {
    this.initParams();
  },
  async created() {
    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    console.log("this.dataModel:", this.dataModel);
    this.title = this.dataModel.name;
    this.$store.commit("setLoading", true);
    if (this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(
      this.$constants.KEY_MENU.LIST_FREIGHT
    );
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0) {
      this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
    }
    this.role.add =
      arr_role.indexOf(this.$constants.ROLE.ADD) < 0 ? false : true;
    this.role.edit =
      arr_role.indexOf(this.$constants.ROLE.EDIT) < 0 ? false : true;
    this.role.delete =
      arr_role.indexOf(this.$constants.ROLE.DELETE) < 0 ? false : true;
  },
  watch: {
    $route: "initParams",
  },
  methods: {
    togglePopup(params) {
      console.log("togglePopuptogglePopuptogglePopuptogglePopup", params);
      this.obj_id = params.id;
      console.log("THIS OBJ_ID", this.obj_id);
      this.showCustomerDialog = true;
      // this.modelName = this.$route.meta.model;
      // var where_ = { freight_id: { _eq: this.obj_id } };
      // this.freightCustomerModel.whereKeyList = {
      //   where_key: where_,
      // };
      // this.$store.commit("setLoading", true);
      // this.callbackOptionsCustomer();
      this.freightCustomerModel = DataServices.getModel("freightcustomer_");
      // console.log("AM VBEEE", this.optionsCustomer);
      // this.freightCustomerKey++;
      this.keyPopup++;
      this.freightCustomerKey++;
    },
    initParams() {
      this.$store.commit("setLoading", true);
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
      this.title = this.dataModel.label;
      this.callbackOptions();
    },
    onChange() {
      this.$store.commit("setLoading", true);
      this.$apollo.queries.listData.refresh();
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name);
      var result = [];
      if (field.type == "Selection") {
        //result = field.options;
        if (field.options) result = field.options;
        else if (field.models && this.options[name])
          result = this.options[name];
      } else {
        result =
          this.filterOptions[name] != null ? [...this.filterOptions[name]] : [];
      }
      console.log("field.type:", field.type);
      console.log("resultresultresultresult11:", result);
      return result;
    },
    getRowActions() {
      var actions = {};
      var that = this;
      // var is_not_button = 1
      /*this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD).then( (res)  => {
         if (res){
           actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
         }
        })*/
      if (this.role.add) {
        // is_not_button = 0
        actions.header = {
          name: "add",
          icon: "plus",
          path: `list-${this.modelName}/add`,
        };
      }
      //if ((await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD))){

      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {};
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        if (that.role.edit) {
        //  is_not_button = 0
          buttons.edit = {
            name: "edit",
            icon: "pencil",
            key: "id",
            path: `list-${that.modelName}/edit/`,
          };
        }
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        // if (that.role.delete) {
        //   buttons.delete = { name: "delete", icon: "trash", key: "id" };
        // }

        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData);
          Object.assign(buttons, modelaction);
        }
        return buttons;

      };
      // if (is_not_button == 1 ){
      //   return  null;
      // }

      return actions;

    },
    async callbackOptionsCustom(params){
      console.log("tettststststst:ssssss");
      var fields = this.dataModel.getDisplayFields();
      console.log("tettststststst:", fields);
      var attributes = [];
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          var optionsData = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          //var where_status = {}
          /*if( element['name']== "rel_status"){
              where_status =  {
                type: {'_eq': 'ORDER'}
              }
            }*/
          var where_ = {};
          if (element["name"] == "name_group_filter") {
            where_ = {
              active: { _eq: true },
              deleted: { _eq: false },
            };
            element.models.tableName = "group";
          }
          else if (element["name"] == "country_id"){
            where_ = {
              active: { _eq: true},
              deleted: { _eq: false},
            }
          }
          console.log("LMAOLMAOLMAOLMAO");
          if (element["name"] == "state_id"){
            where_ = {
              active: { _eq: true},
              deleted: { _eq: false},
            }
          }
          if (params.location == "country_id"){
            if (element["name"] ==  "state_id"){
              where_ = {
                active: { _eq: true},
                deleted: { _eq: false},
                country_id: { _eq: params.location_value}
              }
            }
            else if (element["name"] == "district_id"){
              where_ = {
                state_id: { _eq: 0},
              }
            }
            else if (element["name"] == "ward_id"){
              where_ = {
                district_id: { _eq: 0},
              }
            }
          }
          else if (params.location == "state_id"){
            if (element["name"] == "district_id"){
              where_ = {
                active: { _eq: true},
                deleted: { _eq: false},
                state_id: { _eq: params.location_value}
              }
            }
            else if (element["name"] == "ward_id"){
              where_ = {
                district_id: { _eq: 0},
              }
            }
          }
          else if (params.location == "district_id"){
            if (element["name"] == "ward_id"){
              where_ = {
                active: { _eq: true},
                deleted: { _eq: false},
                district_id: { _eq: params.location_value}
              }
            }
          }
          
          console.log("element.models:", element.models);
          var { data } = await this.$apollo.query({
            query: DataServices.getList(element.models.tableName, {
              fields: attributes.join(","),
            }),
            variables: { where_key: where_ },
          });
          if (data) {
            var result = data[Object.keys(data)];
            if (result) {
              for (let index = 0; index < result.length; index++) {
                const ele = result[index];
                optionsData.push({
                  value: ele[attributes[0]],
                  label: ele[attributes[1]],
                });
              }
            }
          }
          console.log("optionsDataoptionsData:", optionsData);
          this.options[element.key] = optionsData;
        }
        this.componentKey++;
      }
    },
    async callbackOptions() {
      console.log("tettststststst:ssssss");
      var fields = this.dataModel.getDisplayFields();
      console.log("tettststststst:", fields);
      var attributes = [];
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          var optionsData = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          //var where_status = {}
          /*if( element['name']== "rel_status"){
              where_status =  {
                type: {'_eq': 'ORDER'}
              }
            }*/
          var where_ = {};
          if (element["name"] == "name_group_filter") {
            where_ = {
              active: { _eq: true },
              deleted: { _eq: false },
            };
            element.models.tableName = "group";
          }
          else if (element["name"] == "country_id"){
            where_ = {
              active: { _eq: true},
              deleted: { _eq: false},
            }
          }
          
          console.log("element.models:", element.models);
          var { data } = await this.$apollo.query({
            query: DataServices.getList(element.models.tableName, {
              fields: attributes.join(","),
            }),
            variables: { where_key: where_ },
          });
          if (data) {
            var result = data[Object.keys(data)];
            if (result) {
              for (let index = 0; index < result.length; index++) {
                const ele = result[index];
                optionsData.push({
                  value: ele[attributes[0]],
                  label: ele[attributes[1]],
                });
              }
            }
          }
          console.log("optionsDataoptionsData:", optionsData);
          this.options[element.key] = optionsData;
        }
        this.componentKey++;
      }
    },
    async onActionClicked(payload) {
      var action = payload.action;
      var data = null;
      if (payload.data) {
        data = payload.data;
      }
      var pageTransaction = new Date().getTime();
      switch (action.name) {
        case "view":
          this.dialogMode = action.name;
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] });
          } else {
            this.editId = data.data[action.key];
            this.displayDialog = true;
            this.editDialogKey++;
          }
          break;
        case "edit":
          this.dialogMode = action.name;
          if (action.path !== undefined && action.path) {
            console.log(
              "payload.lazyParams:111" + pageTransaction,
              payload.lazyParams
            );
            localStorage.setItem(
              pageTransaction.toString(),
              JSON.stringify(payload.lazyParams)
            );
            this.$router.push({
              path: action.path + data.data[action.key],
              query: { page_transaction: pageTransaction.toString() },
            });
          } else {
            this.editId = data.data[action.key];
            this.displayDialog = true;
            this.editDialogKey++;
          }
          break;
        case "add":
          this.dialogMode = action.name;
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path });
          } else {
            this.displayDialog = true;
            this.editDialogKey++;
          }
          break;
        case "custom":
          await action.handler(data.data, this);
          this.componentKey++;
          break;
      }
    },
  },
};
</script>
<style lang="scss">

</style>
