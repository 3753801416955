<template>
  <form id="frm_action" class="parent_page_detail">
    <div class="p-formgrid">
      <div class="row p-col-12 group-button-detail-top"  v-if="mode == 'detail'" >
        <Button v-for="lad in list_action_detail" :key="lad.code_change"
            :label="lad.action"
            class="style-button-common main-style-button mt-lg-0 mt-2"
            @click="click_change_status(lad.code_change)"
        />
      </div>
      <Panel class="group-panel-detail"  :class="mode=='edit' ? 'group-panel-edit':'group-panel-detail-style'">
        <template #header>
          THÔNG TIN CHUNG
        </template>
        <div class="p-card-field style-block-panel">
          <!-- <div class="p-field p-grid" v-if="(!$commonFuction.isEmpty(code))">
            <label class="p-col-fixed txt-right">Mã chủ thú cưng (*)</label>
            <div class="p-col">
              <InputText disabled="disabled" v-model="code"   class="p-col-width" />
            </div>
          </div> -->
          <div class="block-input">
            <div class="style-col-input left-group-input">
              <div class="group-input-panel">
                <barcode :key="key_barcode" :width="width_barcode" :hieght="hieght_barcode"  class="style-bacode" :value="obj_data.code" format="CODE39"  displayValue="false">
                  Show this if the rendering fails.
                </barcode>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Mã đơn hàng</label>
                <div class="p-col">
                  <InputText disabled required v-model="obj_data.code"  id="code" type="text" class="p-col-width" placeholder="Nhập tên" />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Số chứng từ</label>
                <div class="p-col">
                  <InputText :disabled="disable_common"  required v-model="obj_data.doc_code"  id="doc_code" type="text" class="p-col-width" placeholder="Nhập" />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Ngày chứng từ</label>
                <div class="p-col">

                  <Calendar
                      :disabled="disable_common"
                      :showOnFocus="false"
                      :monthNavigator="true"
                      :yearNavigator="true"
                      dateFormat="dd/mm/yy"
                      placeholder="Nhập"
                      v-model="obj_data.doc_created_at"
                      class="p-column-filter calendar-light-btn"
                      :showIcon="true"
                      selectionMode="single"
                      appendTo="body"
                      :showClear="true"
                      :showButtonBar="true"
                      :manualInput="false"
                      style="width: 200px;"
                  />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Diễn giải</label>
                <div class="p-col">
                  <Textarea :disabled="disable_common" v-model="obj_data.description" :autoResize="true"  cols="30"  />
                </div>
              </div>


  <!--            <label class="p-col-fixed txt-right">Họ tên1(*)</label>
              <div class="p-col">
                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />
              </div>-->
            </div>
            <div class="style-col-input right-group-input">
              <div class="group-input-panel">
              <label class="p-col-fixed txt-right">Nhân viên phụ trách</label>
              <div class="p-col">
                <Dropdown :disabled="disable_common" v-model="obj_data.staff_id" :options="list_user" optionLabel="text_lable" optionValue="id" placeholder="Chọn nhân viên" />
<!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
              </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Ngày đơn hàng</label>
                <div class="p-col">

                  <Calendar disabled
                      :showOnFocus="false"
                      :monthNavigator="true"
                      :yearNavigator="true"
                      dateFormat="dd/mm/yy"
                      placeholder="Nhập"
                      v-model="obj_data.date_order"
                      class="p-column-filter calendar-light-btn"
                      :showIcon="true"
                      selectionMode="single"
                      appendTo="body"
                      :showClear="true"
                      :showButtonBar="true"
                      :manualInput="false"
                      style="width: 200px;"
                  />
                  <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Ngày giao hàng dự kiến</label>
                <div class="p-col">
                  <Calendar
                      :disabled="disable_common"
                      :showOnFocus="false"
                      :monthNavigator="true"
                      :yearNavigator="true"
                      dateFormat="dd/mm/yy"
                      placeholder="Nhập"
                      v-model="obj_data.date_expected"
                      class="p-column-filter calendar-light-btn"
                      :showIcon="true"
                      selectionMode="single"
                      appendTo="body"
                      :showClear="true"
                      :showButtonBar="true"
                      :manualInput="false"
                      style="width: 200px;"
                  />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Ngày giao hàng thành công</label>
                <div class="p-col">
                  <Calendar
                      :disabled="true"
                      :showOnFocus="false"
                      :monthNavigator="true"
                      :yearNavigator="true"
                      dateFormat="dd/mm/yy"
                      placeholder="Nhập"
                      v-model="obj_data.date_done"
                      class="p-column-filter calendar-light-btn"
                      :showIcon="true"
                      selectionMode="single"
                      appendTo="body"
                      :showClear="true"
                      :showButtonBar="true"
                      :manualInput="false"
                      style="width: 200px;"
                  />
                </div>
              </div>

              <div class="group-input-panel" disabled>
                <div class="p-col">
                  <Checkbox :disabled="true" id="request_payment" v-model="obj_data.request_payment" :binary="true" />
                  <label for="request_payment" class="title_check_box">Yêu cầu xác nhận thanh toán</label>
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Xác nhận thanh toán lúc</label>
                <div class="p-col">
                  <Calendar disabled
                      :showTime="true"
                      :showOnFocus="false"
                      :monthNavigator="true"
                      :yearNavigator="true"
                      dateFormat="dd/mm/yy"
                      placeholder="Nhập"
                      v-model="obj_data.date_paid"
                      class="p-column-filter calendar-light-btn"
                      :showIcon="true"
                      selectionMode="single"
                      appendTo="body"
                      :showClear="true"
                      :showButtonBar="true"
                      :manualInput="false"
                      style="width: 200px;"
                  />
                </div>
              </div>

            </div>

          </div>
          <div class="p-grid block-input clear-div-custom">
          </div>
        </div>
      </Panel>

      <Panel class="group-panel-detail" :class="mode=='edit' ? 'group-panel-edit':'group-panel-detail-style'">
        <template #header>
          TIẾN TRÌNH ĐƠN HÀNG
        </template>
        <div class="p-card-field style-block-panel">
          <!-- <div class="p-field p-grid" v-if="(!$commonFuction.isEmpty(code))">
            <label class="p-col-fixed txt-right">Mã chủ thú cưng (*)</label>
            <div class="p-col">
              <InputText disabled="disabled" v-model="code"   class="p-col-width" />
            </div>
          </div> -->
          <div class="p-grid block-input">
<!--            <Timeline :value="events" align="left" >
              <template class="234234" #content="slotProps">
                {{slotProps.item.status}}
              </template>
            </Timeline>-->
            <div class="box-status">
              <div v-for="c in list_status" :key="c.id" class="item-status" :class="get_class_status(c)">
                <div class="icon-timeline"></div>
                <div class="box-content-status">
                    <div class="name-status">{{c.name}}</div>
                    <div class="date-status">{{c.text_time}}<span v-if="c.obj_created_by"> -{{c.obj_created_by ? c.obj_created_by.fullname : "-"}}
                    </span><span v-if="c.department_name"> -{{c.department_name}}</span></div>
                    <div v-if="!($commonFuction.isEmpty(c.text_reason))" class="reason-text">
                      <span class="reason-text-bold">Lý do:</span><span> {{c.text_reason}}</span>
<!--                      <br>
                      <span class="reason-text-bold">Lý do:</span><span> {{c.text_reason}}</span>-->

                    </div>
                   <div v-if="!($commonFuction.isEmpty(c.text_note_delivery))" class="reason-text"><span class="reason-text-bold">Ghi chú giao hàng:</span><span> {{c.text_note_delivery}}</span></div>
                </div>
              </div>
            </div>
<!--            <Timeline :value="events">
              <template #marker="slotProps">
		<span class="custom-marker shadow-2" :class="render_mark_class(slotProps.item)" >
		</span>
              </template>
              <template #opposite="slotProps">
                <small class="p-text-secondary">{{slotProps.item.date}}</small>
              </template>
              <template #content="slotProps">
                <div :class="render_status_class(slotProps.item)">
                  <div class="name-status" >{{slotProps.item.status}}</div>
                  <div class="date-status" >{{slotProps.item.status}}</div>
                </div>
              </template>
            </Timeline>-->

<!--            <Timeline :value="events" align="left" class="customized-timeline">
              <template #marker="slotProps">
		<span class="custom-marker shadow-2" :class="slotProps.icon" >
&lt;!&ndash;			<i :class="slotProps.item.icon"></i>&ndash;&gt;
		</span>
              </template>
              <template #content="slotProps">
                <Card>
                  <template #title>
                    {{slotProps.item.status}}
                  </template>
                  <template #subtitle>
                    {{slotProps.item.date}}
                  </template>
                  <template #content>
                    <img v-if="slotProps.item.image" :src="'demo/images/product/' + slotProps.item.image" :alt="slotProps.item.name" width="200" class="shadow-2" />
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                      quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
                    <Button label="Read more" class="p-button-text"></Button>
                  </template>
                </Card>
              </template>
            </Timeline>-->
          </div>

          <div>
            <span v-if="list_status.length!=list_status_tmp.length" @click="click_more_status" class="see-more">Xem thêm</span>
          </div>
        </div>
      </Panel>


      <Panel v-if="list_pakage.length>0" class="group-panel-detail" :class="mode=='edit' ? 'group-panel-edit':'group-panel-detail-style'">
        <template #header>
          CẬP NHẬT KIỆN
        </template>
        <div class="p-card-field style-block-panel" v-if="is_combine==false">
          <div class=" block-input">
            <div class="style-col-input left-group-input responsive-padding" style="width: 50% !important;">
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Kiện hàng</label>

              </div>
            </div>

            <div class="style-col-input right-group-input responsive-padding" style="width: 50% !important;">
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Số lượng</label>
              </div>
            </div>
            <div v-for="lp in list_pakage" :key="lp.id"  class="item_group_package">
              <div v-if="lp.parcel">
                <div class="style-col-input left-group-input responsive-padding" style="width: 50% !important;">
                  <div class="group-input-panel">
                    <div class="p-col">
                      <InputText disabled required v-model="lp.parcel_name"  type="text" class="p-col-width" placeholder="Nhập tên" />
                    </div>
                  </div>
                </div>
                <div class="style-col-input right-group-input responsive-padding" style="width: 50% !important;">
                  <div class="group-input-panel">
                    <div class="p-col">
                      <InputText disabled required v-model="lp.number"  type="text" class="p-col-width" placeholder="Nhập tên" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="p-grid block-input clear-div-custom">
          </div>
        </div>
        <div class="p-card-field style-block-panel" v-else>
          Ghép kiện đơn hàng <a @click.prevent="redirect_order_code($event,code_root)" href="#" class="style-package-code">{{code_root}}</a>
        </div>
      </Panel>

      <Panel v-if="list_coordinator_convert.length>0" class="group-panel-detail"  :class="mode=='edit' ? 'group-panel-edit':'group-panel-detail-style'">
        <template #header>
          THÔNG TIN ĐIỀU PHỐI
        </template>
        <div class="p-card-field style-block-panel">
          <!-- <div class="p-field p-grid" v-if="(!$commonFuction.isEmpty(code))">
            <label class="p-col-fixed txt-right">Mã chủ thú cưng (*)</label>
            <div class="p-col">
              <InputText disabled="disabled" v-model="code"   class="p-col-width" />
            </div>
          </div> -->
          <div class=" block-input">
            <div v-for="(item_c, index_c) in list_coordinator_convert" :key="index_c" class="style-col-input left-group-input" >
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">GIAO HÀNG LẦN {{item_c.stt}}</label>
                <div class="p-col">
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Chuyến giao</label>
                <div class="p-col">
                  <InputText disabled  required v-model="item_c.code_shipping"   type="text" class="p-col-width" placeholder="Nhập tên" />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Nhân viên giao hàng</label>
                <div class="p-col">
                  <InputText disabled  required v-model="item_c.fullname"   type="text" class="p-col-width" placeholder="Nhập tên" />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Phương tiện giao hàng</label>
                <div class="p-col">
                  <InputText disabled  required v-model="item_c.vehicle_name"  type="text" class="p-col-width" placeholder="Nhập tên" />
                </div>
              </div>

              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Loại giao hàng</label>
                <div class="p-col">
                  <InputText disabled  required v-model="item_c.type_delivery_text"   type="text" class="p-col-width" placeholder="Nhập tên" />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Địa chỉ điều phối</label>
                <div class="p-col">
                  <InputText disabled  required v-model="item_c.freight_address"  type="text" class="p-col-width" placeholder="Nhập tên" />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Ghi chú điều phối</label>
                <div class="p-col">
                  <Textarea disabled v-model="item_c.note" :autoResize="true"  cols="30"  />
                </div>
              </div>
            </div>


          </div>
          <div class="p-grid block-input clear-div-custom">
          </div>
        </div>
      </Panel>

      <Panel class="group-panel-detail" :class="mode=='edit' ? 'group-panel-edit':'group-panel-detail-style'">
        <template #header>
          {{title_panel_customer}}
        </template>
        <div class="p-card-field style-block-panel">
          <!-- <div class="p-field p-grid" v-if="(!$commonFuction.isEmpty(code))">
            <label class="p-col-fixed txt-right">Mã chủ thú cưng (*)</label>
            <div class="p-col">
              <InputText disabled="disabled" v-model="code"   class="p-col-width" />
            </div>
          </div> -->
          <div class=" block-input">
            <div v-if="this.prefix_code == $constants.REFIX_CODE_ORDER_CK" class="style-col-input left-group-input">
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Tên kho</label>
                <div class="p-col">
                  <InputText :disabled="disable_common" v-model="obj_warehouse.name"   type="text" class="p-col-width"  />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Mã kho</label>
                <div class="p-col">
                  <InputText disabled required v-model="obj_warehouse.code"   type="text" class="p-col-width"  />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Địa chỉ kho</label>
                <div class="p-col">
                  <InputText disabled required v-model="obj_warehouse.address"   type="text" class="p-col-width"  />
                </div>
              </div>


              <!--            <label class="p-col-fixed txt-right">Họ tên1(*)</label>
                          <div class="p-col">
                            <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />
                          </div>-->
            </div>
            <div v-else class="style-col-input left-group-input">
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Tên khách hàng</label>
                <div class="p-col">
                  <InputText :disabled="disable_common" v-model="obj_data.customer_name"  id="customer_name" type="text" class="p-col-width" placeholder="Nhập tên" />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Mã khách hàng</label>
                <div class="p-col">
                  <InputText disabled required v-model="obj_data.customer_code"  id="customer_code" type="text" class="p-col-width" placeholder="Nhập tên" />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Số điện thoại</label>
                <div class="p-col">
                  <InputText disabled required v-model="obj_data.customer_phone"  id="customer_phone" type="text" class="p-col-width" placeholder="Nhập tên" />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Địa chỉ khách hàng</label>
                <div class="p-col">
                  <Dropdown :disabled="disable_common" v-model="obj_data.customer_delivery_id" :options="list_address_customer" optionLabel="text_lable" optionValue="id" placeholder="Chọn" />
                  <!--                  <InputText :disabled="disable_common" v-model="obj_data.customer_address"  id="customer_address" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                </div>
              </div>


              <!--            <label class="p-col-fixed txt-right">Họ tên1(*)</label>
                          <div class="p-col">
                            <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />
                          </div>-->
            </div>
            <!-- <div class="style-col-input right-group-input">

            </div> -->
            <div class="style-col-input right-group-input">
<!--              <div class="group-input-panel title-group-doc">
                <label class="p-col-fixed txt-right">CHỨNG TỪ CẦN CÓ</label>
              </div>-->
              <div class="group-input-panel title-group-doc">
                <label class="p-col-fixed txt-right">CHỨNG TỪ CẦN CÓ</label>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Đã nhận</label>
                <div v-for="c in list_docs" :key="c.id" class="p-col item_docs">
                  <div class="required_documents">
                    <div class="total_doc">{{c.number_process}}/ {{c.number_request}}</div>
                    <div class="content_doc">{{c.document.name}}</div>
                  </div>
                </div>
<!--                <div class="p-col item_docs">
                  <div class="required_documents">
                    <div class="total_doc">0/ 2</div>
                    <div class="content_doc">Phiếu xuất kho bán hàng (Có giá)</div>
                  </div>
                </div>
                <div class="p-col item_docs">
                  <div class="required_documents">
                    <div class="total_doc">0/ 2</div>
                    <div class="content_doc">Phiếu xuất kho bán hàng (Có giá)</div>
                  </div>
                </div>-->
              </div>
              <div class="group-input-panel" v-if="obj_data.note_delivery">
                <label class="p-col-fixed txt-right">Ghi chú giao hàng</label>
                <div class="p-col">
                  <Textarea disabled="true" v-model="obj_data.note_delivery" :autoResize="true"  cols="30"  />
                </div>
              </div>
              <div class="group-input-panel" v-if="obj_data.note_profile">
                <label class="p-col-fixed txt-right">Ghi chú hồ sơ chứng từ</label>
                <div class="p-col">
                  <Textarea disabled="true" v-model="obj_data.note_profile" :autoResize="true"  cols="30"  />
                </div>
              </div>

              <div class="group-input-panel" v-if="obj_data.fee_postage">
                <label class="p-col-fixed txt-right">Phí bưu điện</label>
                <div class="p-col">
                  <div class="p-inputgroup">
                    <!--                    <span class="p-inputgroup-addon">$</span>-->
                    <InputText placeholder="Price" v-model="obj_data.fee_postage" />
                    <span class="p-inputgroup-addon">đ</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-grid block-input clear-div-custom">
          </div>
        </div>
      </Panel>

      <Panel class="group-panel-detail" :class="mode=='edit' ? 'group-panel-edit':'group-panel-detail-style'">
        <template #header>
          THÔNG TIN HÀNG HÓA
        </template>
        <div class="p-card-field style-block-panel">
          <!-- <div class="p-field p-grid" v-if="(!$commonFuction.isEmpty(code))">
            <label class="p-col-fixed txt-right">Mã chủ thú cưng (*)</label>
            <div class="p-col">
              <InputText disabled="disabled" v-model="code"   class="p-col-width" />
            </div>
          </div> -->
          <div class=" block-input"  v-if="dataModelOrderDetail && obj_id">
            <BaseDatatable
                :key="componentKeyOrderDetail"
                class="p-datatable-responsive"
                :tableData="listDataOrderDetail"
                :model="dataModelOrderDetail"
                :options="optionsOrderDetail"
                :obj_id="obj_id"
                :actions="getRowActionsOrderItem()"
                v-on:action-click="onActionClickedOrderDetail"
            >
            <template #body="" >
              <span class="responsive-label">{{ listDataOrderDetail }}</span>
            </template>
            </BaseDatatable>
            <div v-if="this.mode == 'detail'"  class="style_total_price">
              <div class="p-inputgroup input-dif">
              </div>
              <span >Tổng giá trị đơn hàng:</span> <span>{{obj_data.total_price_text}}đ</span></div>
            <div v-if="this.mode == 'edit'" class="p-card-field width_size ">
              <label class="p-col-fixed div-label txt-right margin dif " id="label">Tổng giá trị đơn hàng</label>
              <div class="p-inputgroup input-dif">
                <InputNumber v-model="obj_data.total_price" id="size" placeholder="Nhập"  class="p-col-width input_distance width_dif border_color"/>
                <span class="p-inputgroup-addon" id="km">đ</span>
              </div>
            </div>
          </div>
          <div class="p-grid block-input clear-div-custom">
          </div>
        </div>
      </Panel>




      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-warning callback-btn"
          />
          <Button
              v-if="mode == 'edit'"
              label="Lưu"
              @click="saveData()"
              class="p-button-success main-style-button"
          />
<!--          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="p-button-success main-style-button"
          />-->
          <Dialog
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-change-product" :header="title_popup_add_product" footer="Footer" :visible.sync="data_popup_product.display_add_product">
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Mã kho</label>
              <div class="p-col">
                <Dropdown @change="change_warehouse_popup($event)" v-model="data_popup_product.obj_product.warehouse_id" :options="data_popup_product.list_warehouse" optionLabel="warehouse_name" optionValue="warehouse_id" placeholder="Chọn" />
                <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                <input class="input_tmp_validator" id="popup_product_warehouse_id">
              </div>
            </div>
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Sản phẩm</label>
              <div class="p-col">
                <v-select :clearable="false"  class="input_width" @search="fetchOptionsProduct" v-on:input="change_product_popup" :reduce="item_product => item_product.id" id= "id_product" placeholder="Chọn" v-model="data_popup_product.obj_product.product_id" :options="data_popup_product.list_product" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
                </v-select>
<!--                <Dropdown
                    @change="change_product_popup($event)"
                    v-model="data_popup_product.obj_product.product_id" :options="data_popup_product.list_product" dataKey="id" optionLabel="name" optionValue="id" placeholder="Chọn"  />-->
                <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                <input class="input_tmp_validator" id="popup_product_product_id">
              </div>
            </div>
            <div class="group-input-popup">
              <div class="item-left-popup" style="">
                <label class="p-col-fixed txt-right">Mã sản phẩm</label>
                <div class="p-col">
                  <InputText disabled="true" v-model="data_popup_product.obj_product.product_code"  type="text" class="p-col-width" placeholder="Nhập" />
                </div>
              </div>
              <div class="item-right-popup" style="">
                <label class="p-col-fixed txt-right">Đơn vị tính</label>
                <div class="p-col">
                  <InputText disabled="true" v-model="data_popup_product.obj_product.unit_name"  type="text" class="p-col-width" placeholder="Nhập" />
                  </div>
              </div>
            </div>

            <div class="group-input-popup">
              <div class="item-left-popup" style="">
                <label class="p-col-fixed txt-right">Số lô</label>
                <div class="p-col">
                  <Dropdown @change="change_lot_popup($event)"  v-model="data_popup_product.obj_product.lot_number" :options="list_lot_number_group" optionLabel="key_lot" optionValue="key_lot" placeholder="Chọn" />
                  <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                  <input class="input_tmp_validator" id="popup_product_lot_number">
                </div>
              </div>
              <div class="item-right-popup" style="">
                <label class="p-col-fixed txt-right">Hạn sử dụng</label>
                <div class="p-col">
                  <Dropdown @change="change_date_popup($event)" v-model="data_popup_product.obj_product.expiry_date_key" :options="list_expiry_date_group" optionLabel="text_expiry_date" optionValue="key_expiry_date" placeholder="Chọn" />
                  <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                  <input class="input_tmp_validator" id="popup_product_expiry_date">
                </div>
              </div>
            </div>

            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Số lượng</label>
              <div class="p-col">
                <InputNumber
                    class="p-col-width"
                    mode="decimal"
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    v-model="data_popup_product.obj_product.qty"
                    locale="de-DE"
                    placeholder="Nhập"
                    id="popup_product_qty"
                /><!--:min="0.00"-->
<!--                <InputNumber  :min="1" :max="100000000000" v-model="data_popup_product.obj_product.qty"  type="text" class="p-col-width" placeholder="Nhập" id="popup_product_qty" />-->
                </div>
            </div>

            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="data_popup_product.display_add_product = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="save_product_popup()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>


          <Dialog
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-confirm-payment" header="Xác nhận thanh toán" footer="Footer" :visible.sync="display_confirm_payment">
              <div class="text_confirm_popup">Bạn xác nhận rằng đơn hàng này đã được thanh toán đầy đủ bởi khách hàng.</div>
            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="display_confirm_payment = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="process_confirm_payment()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>

          <Dialog
              id="cancel_order_id"
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-cancel-order" :header="data_popup_cancel_order.title" footer="Footer" :visible.sync="data_popup_cancel_order.display_cancel_order">
            <div v-if="data_popup_cancel_order.status_code != 'REASON_RETURN'" class="text_confirm_popup">Bạn muốn huỷ đơn hàng này, vui lòng cập nhật lý do:</div>
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Lý do *</label>
              <div class="p-col">
              <!--@change="change_product_popup($event)"                -->
                <Dropdown
                    class="reason_cancel_list"
                    appendTo="cancel_order_id"
                    v-model="data_popup_cancel_order.reason_id" :options="data_popup_cancel_order.list_reason" optionLabel="name" optionValue="id" placeholder="Chọn"  />
                <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
               <input class="input_tmp_validator" id="data_popup_cancel_order_reason_id">
              </div>
            </div>
            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="data_popup_cancel_order.display_cancel_order = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="process_cancel_order()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>

          <Dialog
              id="custom-popup-common-address"
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-update-address-coordinator" header="Cập nhật địa chỉ điều phối" footer="Footer" :visible.sync="data_popup_update_address_coordinator.display">
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Loại điều phối</label>
              <div class="p-col" >

                <div class="field-radiobutton" >
                  <RadioButton id="type_delivery1"  value="1" v-model="data_popup_update_address_coordinator.type_delivery" />
                  <label for="type_delivery1">{{ radio_text_update_addres }}</label>
                </div>
                <div class="field-radiobutton"  ><!--v-if="!(prefix_code==$constants.REFIX_CODE_ORDER_CK)"-->
                  <RadioButton id="type_delivery2"  value="2" v-model="data_popup_update_address_coordinator.type_delivery" />
                  <label for="type_delivery2">Giao hàng đến chành</label>
                </div>
                <div class="field-radiobutton" v-if="!(type_order == 2 )"><!--|| prefix_code==$constants.REFIX_CODE_ORDER_CK-->
                  <RadioButton id="type_delivery3"  value="3" v-model="data_popup_update_address_coordinator.type_delivery" />
                  <label for="type_delivery3">Gửi bưu điện</label>
                </div>
                <div class="field-radiobutton" v-if="!(type_order == 2 )"><!--|| prefix_code==$constants.REFIX_CODE_ORDER_CK-->
                  <RadioButton id="type_delivery4"  value="4" v-model="data_popup_update_address_coordinator.type_delivery" />
                  <label for="type_delivery4">Tự lấy hàng</label>
                </div>

              </div>
            </div>

            <div class="group-input-popup" v-if="data_popup_update_address_coordinator.type_delivery==2">
              <label class="p-col-fixed txt-right">Chành *</label>
              <div class="p-col">
                <!--@change="change_product_popup($event)"                -->
<!--                 appendTo="custom-popup-common-address"-->
                <Dropdown


                    v-model="data_popup_update_address_coordinator.freight_id"
                    :options="data_popup_update_address_coordinator.list_freight" optionLabel="name" optionValue="id" placeholder="Chọn"  >
                  <template #option="slotProps">
                    <div class="p-clearfix">
              <span v-if="slotProps.option.name">{{ slotProps.option.name }}</span>
                    </div>
                  </template>
                </Dropdown>
                <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                <!--                <input class="input_tmp_validator" id="popup_product_product_id">-->
              </div>
            </div>
            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="data_popup_update_address_coordinator.display = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Lưu & về danh sách"
                  @click="process_update_address_coordinator(1)"
                  class="p-button-success main-style-button"
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Lưu"
                  @click="process_update_address_coordinator(0)"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>


          <Dialog
          @hide="close_popup_"
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-update-pakage" header="Cập nhật kiện hàng" footer="Footer" :visible.sync="data_popup_update_pakage.display">
            <div class="text_confirm_popup">Bạn xác nhận đơn hàng đã được soạn hàng xong với thông tin số kiện được liệt kê bên dưới và sẵn sàng cho hoạt động điều phối.</div>
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Mã đơn hàng</label>
              <div class="p-col">
                <InputText disabled="true" v-model="obj_data.code"  type="text" class="p-col-width" placeholder="Nhập" />
                </div>
            </div>
            <div class="group-input-popup">
              <div class="p-col">
                <Checkbox id="data_popup_update_pakage_combine_packages" v-model="data_popup_update_pakage.obj_data.combine_packages" :binary="true" />
                <label for="data_popup_update_pakage_combine_packages" class="title_check_box">Ghép kiện</label>
              </div>
            </div>

            <div class="group-input-popup" v-if="data_popup_update_pakage.obj_data.combine_packages ==true">
              <label class="p-col-fixed txt-right">Đơn hàng muốn ghép</label>
              <div class="p-col">
                <div class="group-input-popup d-flex">
                  <InputText id="code_combine_packages" v-model="data_popup_update_pakage.obj_data.code_combine_packages"  type="text" class="p-col-width" placeholder="Nhập"  @input="change_code_combine_packages()" />
                  <Button style=" min-width: 40px;" icon="fa fa-qrcode" v-show="is_mobile_web" @click="scan_visible = !scan_visible"/>
                </div>
                <Scan @result_code="success_scan" :invisible="scan_visible" v-if="scan_visible"/>
              </div>
            </div>
<!--

<li v-for="({ message }, index) in items">
  {{ message }} {{ index }}
</li>
-->

            <div class="group-input-popup" v-for="(item_pakage, index_pa) in data_popup_update_pakage.list_pakage_data" :key="item_pakage.index_pa">
              <div class="item-left-popup" style="">
                <label class="p-col-fixed txt-right" v-if="index_pa==0">Kiện hàng</label>
                <div class="p-col ">
                  <Dropdown @before-hide="before_hide_parcel"  :disabled="data_popup_update_pakage.obj_data.combine_packages ==true" v-model="item_pakage.parcel_id" :options="item_pakage.list_parcel_key" @before-show="before_show_parcel(index_pa,item_pakage.parcel_id)" optionLabel="name" optionValue="id" placeholder="Chọn" />
                  <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                </div>
              </div>
              <div class="item-right-popup" style="">
                <label class="p-col-fixed txt-right" v-if="index_pa==0">Số lượng</label>
                <div class="p-col style_group_pakage">
                  <InputNumber :disabled="data_popup_update_pakage.obj_data.combine_packages ==true"  v-model="item_pakage.parcel_qty"  type="text" class="p-col-width popup_qty_pakage" placeholder="Nhập" />
                <img v-if="data_popup_update_pakage.obj_data.combine_packages ==false"  @click="delete_pakage_popup(item_pakage.key_data)" class="style_icon_del_pakage" style="" src="/assets/images/icon/ic_delete.svg">
                </div>
              </div>
            </div>

            <div class="group-input-popup" v-if="data_popup_update_pakage.obj_data.combine_packages ==false">
                <div class="add_item_popup" @click="add_pakage_popup()">Thêm kiện hàng</div>
            </div>
          <div class="class_tmp_popup" v-if="display_tmp_popup" style=""></div>  
            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="data_popup_update_pakage.display = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="process_submit_pakage()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>
          <Dialog
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-confirm-profile" header="Chuyển hồ sơ chứng từ lên kế toán" footer="Footer" :visible.sync="data_popup_confirm_profile.display">
            <div class="text_confirm_popup">Bạn xác nhận chuyển toàn bộ Chứng từ của Đơn hàng đã chọn cho Kế toán?</div>
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Nhập số lượng chứng từ đã nhận:</label>
              <div class="p-col" v-for="du in list_docs_update" :key="du.id">
                <span v-if="du.number_request > 0">
                  <!--@change="change_product_popup($event)"                -->
                  <InputNumber min="0" :max="du.number_process"  v-model="du.number_process"  type="text" class="p-col-width number_profile" placeholder="Nhập"  />
                  <div class="group_text_profile">
                    <span class="text_profile_request">/{{du.number_request}}  </span>
                    <span class="name_profile">{{(!$commonFuction.isEmpty(du.document)) ? du.document.name:''}}</span>
                  </div>
                </span>
              </div>
            </div>
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Ghi chú hồ sơ chứng từ</label>
              <div class="p-col">
                <Textarea v-model="data_popup_confirm_profile.obj_data.note" :autoResize="true"  cols="30"  />
              </div>
            </div>
            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="data_popup_confirm_profile.display = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="process_update_profile()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>
          <Dialog
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-confirm-close-profile" header="Xác nhận đóng đơn & đủ chứng từ" footer="Footer" :visible.sync="data_popup_confirm_close_profile.display">
            <div class="text_confirm_popup">Bạn xác nhận rằng đã nhận đủ chứng từ giao hàng & đóng đơn hàng này. Đơn hàng đã đóng sẽ không được cập nhật chỉnh sửa bất kỳ thông tin gì.</div>
            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy bỏ"
                  @click="data_popup_confirm_close_profile.display = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="process_submit_enough_profile()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>
          <Dialog
              header="Xóa"
              :visible.sync="showDeleteDialog"
              :contentStyle="{overflow: 'visible'}"
              :modal="true"
          >
            Bạn có chắc muốn xóa <strong></strong>
            <template
                #footer
            >
              <div >
              <Button  label="Xóa" @click="deleteData" icon="pi pi-check" class="p-button-danger" />
              <Button
                  label="Hủy"
                  @click="showDeleteDialog = false"
                  icon="pi pi-times"
                  class="p-button-warning"
              />
              </div>
            </template>
          </Dialog>
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices'
import gql from "graphql-tag";
import BaseDatatable from './list_order_item'
import ApiRepository from "@/core/ApiRepository";
import moment from "moment-timezone";
import AuthenticationApp from "@/core/AuthenticationApp";
import Scan from '@/components/Scan';
export default {
  components: {
    BaseDatatable,
    Scan
    // Popup
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      display_tmp_popup: false,
      scan_visible: false,
      is_mobile_web: false,
      offset_status:0,
      limit_status:3,
      key_barcode:0,
      width_barcode:1*2,
      hieght_barcode:30*2,
      title_popup_add_product:"Thêm mới",
      title_panel_customer:" THÔNG TIN KHÁCH HÀNG",
      list_address_customer :[],
      code_root:false,
      is_combine:false,
      list_coordinator_convert :[],
      list_user_nv :[],
      key_data_delete :null,
      list_user_nv_role :[],
      showDeleteDialog:false,
      disable_common:false,
      data_popup_confirm_close_profile:{
        display:false,
        obj_data:{

        }
      },
      data_popup_confirm_profile:{
        display:false,
        obj_data:{

        }
      },
      data_popup_update_pakage:{
        display:false,
        list_pakage_data:[],
        list_parcel:[],
        obj_data:{
          combine_packages:false,
          code_combine_packages:'',
          id_combine_packages:''
        }
      },
      data_popup_update_address_coordinator:{
        display:false,
        list_freight:[],
        freight_id :null,
        type_delivery:null,
        key:0
      },
      data_popup_cancel_order:{
        title:'Hủy đơn',
        display_cancel_order:false,
        list_reason:[],
        reason_id :null,
        status_code:null
      },
      display_confirm_payment:false,
      map_warehouse:{},
      group_data_lot_number:{},
      list_expiry_date_group_lot:{},
      group_lot_number_date:{},
      map_expiry_date_group_tmp:{},
      list_lot_number_group_tmp:[],
      list_expiry_date_group_tmp:[],
      map_expiry_date_group:{},
      list_lot_number_group:[],
      list_expiry_date_group:[],
      data_popup_product:{
        map_convert_data:{},
        display_add_product:false,
          list_warehouse:[],
          list_product:[],
          list_lot_number:[],
          obj_product:{
           /* key_data:null,
            id:null,
            product_id:null,
            stt:null,
            product_code:null,
            product_name:null,
            /!*unit_name:item_order.unit.name,*!/
            unit_name:null,
            lot_number:null,
            expiry_date:null,
            material_code: null,
            warehouse_code:  null,
            warehouse_id:  null,*/
          }

      },
      list_action_detail:[],
      list_pakage:[],
      obj_id:null,
      list_docs: [],
      list_docs_update: [],
      listDataOrderDetail: [],
      modelNameOrderDetail: '',
      dataModelOrderDetail: null,
      componentKeyOrderDetail: 0,
      optionsOrderDetail: [],
      obj_data :{},
      list_user:[],
      staff_id :null,//NV phụ trách
      list_status: [ ],
      list_status_tmp: [ ],
      type_order:1,
      import:false,
      canceled:true,
      edit_order:true,
      confirm_payment:true,
      delivery_status:true,
      obj_warehouse:null,//xuất
      obj_warehouse_import:null,//nhập
      radio_text_update_addres:'Giao hàng đến địa chỉ khách',
      role:{
        VIEW: false,
        ADD: false,
        EDIT: false,
        DELETE: false,
        EXPORT: false,
        ORDER_HAS_BEEN_PRINTED: false,
        CONFIRM_PREPARATION_GOODS: false,
        PACKAGED: false,
        CONFIRM_DISPATCHER_ADDRESS: false,
        UPDATED_DISPATCHER_ADDRESSS: false,
        COORDINATION_COMPLETED: false,
        GOODS_OUT_WAREHOUSE: false,
        GOODS_LEAVE_COMPANY: false,
        DELIVERY_STATUS: false,
        TRANSFER_HSCT_ACCOUNTING:false,
        ENOUGH_DOCUMENTS_CLOSE_APPLICATION: false,
        CANCELED:false,
        EDIT_ORDER: false,
        IMPORT: false,
        CLOSEOUT: false,
        CONFIRM_PAYMENT: false,
        CONFIRMATION_RECEIPT: false,
        REASON_RETURN: false,
      },
      user:{
        fullname: '',
        phone: '',
        email: '',
        password: '',
        password_confirm: '',
        group_id:null
      },
      code: '',
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role:[],
      saving:false,
      prefix_code:'',
      addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}

    }
  },
  watch: {
    "obj_data.staff_id"() {
      console.log("this.list_userthis.list_userthis.list_user",this.list_user);
      this.obj_data.staff_name = this.list_user.filter(f => f.id == this.obj_data.staff_id)[0]?.name || null;
      console.log("this.objthis.objthis.objthis.objthis.obj", this.obj_data);
    }
  },
  async mounted() {
    var screenWidth = window.innerWidth;
    var isMobile = window.orientation > -1;
    if (isMobile) {
      this.is_mobile_web = true;
    }
// Hiển thị chiều rộng trong console
    console.log("Chiều rộng màn hình là: " + screenWidth + " pixels");
    if (screenWidth<500){
      this.width_barcode = 1*1.3;
      this.hieght_barcode = 30*1.3;
      this.key_barcode++;
    }
    console.log("this.modethis.mode:",this.mode)
    if (this.mode == 'detail'){
      this.disable_common = true;
    }
    //var that =this;
    //Lấy danh sách nv
    var gqLQueryListUser = DataServices.getList('staff');
    var where_user = {deleted:{_eq:false}};
    var resData = await this.$apollo.mutate({
      mutation: gqLQueryListUser,
      variables: {
        where_key:where_user ,
        /*offset:offset,
        limit:limit,*/
        orderBy:{created_at: 'desc'}
      }
    });
    var listDataUser = resData.data['staff'];
    var list_user_custom = [];
    for (var i=0;i<listDataUser.length;i++){
      var item_user_custom = listDataUser[i];
      var fullname = (item_user_custom.name) ? item_user_custom.name:'';
      var code = (item_user_custom.code) ? item_user_custom.code:'';
      var text_lable = code+" - "+fullname
      item_user_custom.text_lable= text_lable
      list_user_custom.push(item_user_custom)
    }
    this.list_user = list_user_custom
    console.log("listDatalist_userlist_userlist_user:",list_user_custom)

    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.modelthis.model:this.model",this.model)
      this.obj_id = this.model.id
      console.log("this.obj_id:",this.obj_id)
      this.obj_data = this.model
      this.obj_data.doc_created_at = this.obj_data.doc_created_at !== null && new Date(this.obj_data.doc_created_at) || null;
      this.obj_data.date_order = this.obj_data.date_order !== null && new Date(this.obj_data.date_order) || null;
      this.obj_data.date_expected = this.obj_data.date_expected !== null && new Date(this.obj_data.date_expected) || null;
      this.obj_data.date_done = this.obj_data.date_done !== null && new Date(this.obj_data.date_done) || null;
      this.obj_data.date_paid = this.obj_data.date_paid !== null && new Date(this.obj_data.date_paid) || null;
      console.log("this.obj_data.total_price:",this.obj_data.total_price)
      //this.obj_data.date_paid = moment(this.obj_data.date_paid).format("DD/MM/YYYY hh:mm:ss")
      this.obj_data.total_price_text = !this.$commonFuction.isEmpty(this.obj_data.total_price) ? this.$commonFuction.numberFormatCore(this.obj_data.total_price,0,',','.'):""  ;
      this.prefix_code = this.obj_data.code.substring(0, 2)
      if (this.prefix_code == this.$constants.REFIX_CODE_ORDER_CK){
        this.radio_text_update_addres = 'Giao hàng đến địa chỉ kho'
        this.title_panel_customer = 'THÔNG TIN KHO'
      }
      var code_root = (this.obj_data.order_group_parcel) ? this.obj_data.order_group_parcel.code_root:''
      if (code_root != this.obj_data.code){
        this.is_combine = true;
        this.code_root = code_root;
      }
      //Lấy danh sách chứng từ
      this.load_docs();
      this.load_pakage();
      this.load_status_order();
      this.load_list_detail_order();
      this.load_button_detail();
      this.load_list_coordinator();
      this.load_address_customer()
      /*
      this.model.email_old = this.model.email;

      var dataGroupUser = await this.getGroupUser(this.model.id);
      if (dataGroupUser && dataGroupUser.length){
        this.model.group_id = dataGroupUser[0].group_id;
      }
      this.user = this.$commonFuction.convertJsonObject(this.model);



      this.$CoreService.getAccountByUid(this.model.id).then(res => {
        console.log("getAccount",res)
        if (res && res.length){
          this.user.user_id = res[0].uid;
        }
      });*/
    }
    /*this.listGroupRole().then(res => {
      console.log("resres",res)
      this.list_group_role = res;
    });*/
    var gqLQueryList = DataServices.getList('freight');
    console.log("gqL",gqLQueryList)
    var resData_freight = await this.$apollo.mutate({
      mutation: gqLQueryList,
      variables: {
        where_key:{deleted:{_eq:false}},
        orderBy: {created_at: 'desc'},

      }
    });
    var listData = resData_freight.data['freight'];


    for (var s = 0 ; s < listData.length ; s++){
      listData[s].name = listData[s].code+"-"+listData[s].name
    }

    this.data_popup_update_address_coordinator.list_freight = listData;
    console.log("âsasasa",this.data_popup_update_address_coordinator.list_freight)




  },
  methods: {
    async before_hide_parcel() {
 this.display_tmp_popup =false;
    },
    async close_popup_() {
      console.log("close_popup_:")
      this.data_popup_update_pakage.display = false
      this.display_tmp_popup = false;
    },
    get_class_status(c){
      var text_class = '';
      if (c.active==true){
        text_class += " active_status";
      }
      if (!this.$commonFuction.isEmpty(c.text_note_delivery)){
        text_class += " max_height_box";
      }
      return text_class;
    },
    success_scan(data){
      this.data_popup_update_pakage.obj_data.code_combine_packages = data;
      this.scan_visible = false;
    },
    async fetchOptionsProduct(search, loading){
      console.log(loading)
      var whereAttach = null;
      if(search !== '' && search !== null){
        whereAttach = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
         // deleted : {_eq : false},
          name:{_iregex: this.$commonFuction.search(search)},
        };
      }
      this.load_popup_product(whereAttach)
    },
    async redirect_order_code(event,code_order) {
      event.preventDefault();
      //this.$commonFuction.is_loading(true);
      var list_code = [];
      list_code.push(code_order)
      var listdata_convert = await this.load_list_order_by_code(list_code, 'order')
      var item_order = (listdata_convert.length > 0) ? listdata_convert[0] : null;
      if (item_order){
        var id_order = item_order.id
        //window.location.href = '/admin/list-order/edit/'+id_order
        window.open('/admin/list-order/edit/'+id_order, '_blank');
      }
    },
    async before_show_parcel(index_pa,parcel_id=null) {
      this.display_tmp_popup =true;
      this.$commonFuction.is_loading(true);
      var that = this;
       setTimeout(function () {
                          that.$commonFuction.is_loading(false);
                      }, 300);
      console.log("gggggggggg:",index_pa);
      var list_parcel = this.data_popup_update_pakage.list_pakage_data;
      //lấy danh sách id ko thuộc id hiện tại
      var list_id_not_current = [];
      console.log("list_parcellist_parcel:",list_parcel)
      for (var i=0;i<list_parcel.length;i++){
        var item_data = list_parcel[i];
        var id_ = item_data.parcel_id;
        if (id_ != parcel_id){
          list_id_not_current.push(id_)
        }
      }
      console.log("list_id_not_current:",list_id_not_current)
      console.log("parcel_id:",parcel_id)
      var list_parcel_default = this.data_popup_update_pakage.list_parcel
      var list_res = [];
      for (var j=0;j<list_parcel_default.length;j++){
        var item_data1 = list_parcel_default[j];
        var id_check = item_data1.id
        if (list_id_not_current.indexOf(id_check) ==-1){
          //lấy item data ko tồn tại trong mảng
          list_res.push(item_data1)
        }
      }
      this.data_popup_update_pakage.list_pakage_data[index_pa]["list_parcel_key"] = list_res;
    },
    async load_list_order_by_code(list_code,modelName) {
      //this.obj_id
      var gqLQueryListOrderDocs = DataServices.getList(modelName);
      var listDisplayCompany = AuthenticationApp.getListCompany();

      var where_user_docs = {
        code: {_in: list_code},
      }

      if (listDisplayCompany != null)
      {
        where_user_docs['company_id'] = {_in: listDisplayCompany};
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['order'];
      var listdata_convert = []
      //var count_stt = 0;
      for (var i=0;i<listData.length;i++){
        var item_order = listData[i]

        /*var order_shipping_code = '';
        var name_warehouse = '';
        var detail_text = '';
        if (this.modelName=='order_custom_warehouse'){
          //lấy mã chuyến
          if (!this.$commonFuction.isEmpty(item_order.obj_order_shipping_items)){
            order_shipping_code = (!this.$commonFuction.isEmpty(item_order.obj_order_shipping_items.order_shipping)) ? item_order.obj_order_shipping_items.order_shipping.code:'';
          }
          //lấy tên kho
          if (!this.$commonFuction.isEmpty(item_order.obj_order_item)){

            name_warehouse = (!this.$commonFuction.isEmpty(item_order.obj_order_item.warehouseByWarehouseId)) ? item_order.obj_order_item.warehouseByWarehouseId.name:'';
          }
          //chi tiet
        }
        item_order["order_shipping_code"] = order_shipping_code;
        item_order["name_warehouse"] = name_warehouse;
        console.log('name_warehousename_warehouse:',name_warehouse)
        item_order["detail_text"] = detail_text;*/
        listdata_convert.push(item_order)
      }
      return listdata_convert;
    },
    async change_code_combine_packages() {
      console.log("asdasdaschange_code_combine_packages:")
      var code_order = this.data_popup_update_pakage.obj_data.code_combine_packages;
      if (!this.$commonFuction.isEmpty(code_order)) {
        var list_code = [];
        list_code.push(code_order)
        var listdata_convert = await this.load_list_order_by_code(list_code, 'order')
        var item_order = (listdata_convert.length > 0) ? listdata_convert[0] : null;
        if (item_order){
          console.log("item_order:",item_order);
          //var order_group_parcel_id = item_order.order_group_parcel_id
          if (item_order.order_group_parcel_id){
            var data_res =await this.$CoreService.getPackage(item_order.order_group_parcel_id);
            for (var k=0;k<data_res.length;k++){
              data_res[k]["list_parcel_key"] = this.data_popup_update_pakage.list_parcel
            }
            this.data_popup_update_pakage.list_pakage_data = data_res
          }else {
            this.data_popup_update_pakage.list_pakage_data = [];
          }
        }else {
          this.data_popup_update_pakage.list_pakage_data = [];
        }
      }else {
        this.data_popup_update_pakage.list_pakage_data = [];
      }
    },
    deleteData(){
      console.log("this.key_data_delete",this.key_data_delete)
      console.log("delete_key_data",key_data)
      var key_data = this.key_data_delete
      var list_delete = []
      for (var i = 0 ; i < this.listDataOrderDetail.length ; i++){
        if (key_data != this.listDataOrderDetail[i].key_data){
          list_delete.push(this.listDataOrderDetail[i])
        }
      }
      this.listDataOrderDetail =  list_delete
      this.dataModelOrderDetail.processData(this.listDataOrderDetail)
      this.componentKeyOrderDetail++;
      this.data_popup_product.display_add_product=false;
      this.showDeleteDialog = false
      console.log("list_delete",this.listDataOrderDetail)
    },

    change_test(){
      console.log("data_popup_product.obj_product.expiry_date:",this.data_popup_product.obj_product.expiry_date)
    },
    async get_order_ship() {
      try {
        console.log("this.model.suggest_delivery_type:",this.model.suggest_delivery_type)
        this.data_popup_update_address_coordinator.type_delivery = (this.model.suggest_delivery_type) ? this.model.suggest_delivery_type + "" : null
        //this.data_popup_update_address_coordinator.key++;
        //this.data_popup_update_address_coordinator.type_delivery = 2
        //console.log("this.data_popup_update_address_coordinator.type_delivery:",this.data_popup_update_address_coordinator.type_delivery)
        this.data_popup_update_address_coordinator.freight_id = (this.model.suggest_freight_id) ? this.model.suggest_freight_id : null
/*
        var list_id = [];
        list_id.push(this.model.id)
        var dataRequest = {
          list_id: list_id,
        };
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        var item_order_ship = await ApiRepository.post('/_api/order/get-order-ship', dataRequest, {headers});
        var order_ship_map  = (item_order_ship["data"]["data"]) ? item_order_ship["data"]["data"]:null
        console.log("order_shiporder_ship:",order_ship_map)
        if (order_ship_map){
          if (!this.$commonFuction.isEmpty(order_ship_map[this.model.id])) {
            var order_ship = order_ship_map[this.model.id]
            this.data_popup_update_address_coordinator.type_delivery = (order_ship.delivery_type) ? order_ship.delivery_type + "" : null
            //this.data_popup_update_address_coordinator.key++;
            //this.data_popup_update_address_coordinator.type_delivery = 2
            //console.log("this.data_popup_update_address_coordinator.type_delivery:",this.data_popup_update_address_coordinator.type_delivery)
            this.data_popup_update_address_coordinator.freight_id = (order_ship.freight_id) ? order_ship.freight_id : null
          }
          }*/
        //
      } catch (err) {
        console.log(err);
      }

    },
    async process_update_address_coordinator(is_back_list=0) {
      //cập nhật hsct
      var that = this
      var code_order = this.obj_data.code
      var prefix_code = code_order.substring(0, 2);
      if (this.$commonFuction.isEmpty(this.data_popup_update_address_coordinator.type_delivery)){
        alert("Vui lòng chọn loại điều phối!");
        return  false;
      }

      if (parseInt(this.data_popup_update_address_coordinator.type_delivery) == 1){
          if (this.$commonFuction.isEmpty(this.obj_data.address)){
            if (prefix_code == "CK"){
              var obj_data = await this.$CoreService.get_warehouse_order_ck(this.obj_data.id,2);
              console.log("obj_dataobj_data:",obj_data)
              var wareHouseAddress = obj_data?.data?.warehouse?.address ?? null;
              var wareHouseDistrictId = obj_data?.data?.warehouse?.district_id ?? null;
              var wareHouseStateId = obj_data?.data?.warehouse?.state_id ?? null;
              if (
                  this.$commonFuction.isEmpty(wareHouseAddress)
                  || this.$commonFuction.isEmpty(wareHouseDistrictId)
                  || this.$commonFuction.isEmpty(wareHouseStateId)
              ){
                alert("Vui lòng cập nhật địa chỉ kho");
                return  false;
              }
            }else {
              alert("Vui lòng cập nhật địa chỉ khách hàng");
              return  false;
            }
          }
      }else if(parseInt(this.data_popup_update_address_coordinator.type_delivery) == 2){
        if (this.$commonFuction.isEmpty(this.data_popup_update_address_coordinator.freight_id)){
          alert("Vùi lòng cập nhật chành xe");
          return  false;
        }
      }
      var dataRequest = {
        id:this.model.id,
        type_delivery:this.data_popup_update_address_coordinator.type_delivery,
        freight_id:this.data_popup_update_address_coordinator.freight_id,
      }
      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/process-update-address-coordinator', dataRequest, {headers});
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });
        if(is_back_list == 1){
          window.location.href = '/admin/list-order'
        }else{
          location.reload()
        }

        return true
      } catch (err) {
        var message = err.response.data.message;
        console.log("messagemessage:",message)
        if (message=='UPDATE_STATUS_ERR'){
          //var list_err = err.response.data.data
          alert("Không thể cập nhật trạng thái")
        }else {
          that.$toast.add({
            severity: "error",
            summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
            detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
            life: 3000,
          });
        }
        console.log(err);
        return false;
      }
    },
    async list_freight_customer(id_customer) {
      var gqLQueryListData = DataServices.getList('freightcustomer_');
      var where_data = {
        customer_id: {_eq: id_customer},
        deleted: {_eq: false},
      }
      var resData_data = await this.$apollo.mutate({
        mutation: gqLQueryListData,
        variables: {
          where_key:where_data ,
          /*offset:offset,
          limit:limit,*/
        //  orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_data.data['freight_customer'];
      var data_convert = [];
      for (var i=0;i<listData.length;i++){
          var item_data = listData[i];
          if ((!this.$commonFuction.isEmpty(item_data.freight))){
            var code = (!this.$commonFuction.isEmpty(item_data.freight)) ? item_data.freight.code+' - ':'';
            var name = (!this.$commonFuction.isEmpty(item_data.freight)) ? item_data.freight.name:'';
            var text =code + name;
            var item_data_convert = {
              id:item_data.freight.id,
              text:text,
              name:text,
            }
            data_convert.push(item_data_convert)
          }

      }
      console.log("data_convertdata_convert:",data_convert)
      return data_convert;

    },
    async process_update_profile() {
      //cập nhật hsct
      var that = this


      var dataRequest = {
        id:this.model.id,
        list_docs_update:this.list_docs_update,
        note:this.data_popup_confirm_profile.obj_data.note,
      }
        try {
          const headers = {
            "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
          };
          await ApiRepository.post('/_api/order/process-update-profile-order', dataRequest, {headers});
          that.$toast.add({
            severity: "success",
            summary: "Thông báo",
            detail: "Cập nhật thành công",
            life: 3000,
          });
          location.reload()
          return true
        } catch (err) {
          var message = err.response.data.message;
          console.log("messagemessage:",message)
          if (message=='UPDATE_STATUS_ERR'){
            //var list_err = err.response.data.data
            alert("Không thể cập nhật trạng thái")
          }else {
            that.$toast.add({
              severity: "error",
              summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000,
            });
          }
          console.log(err);
          return false;
        }
    },
    async process_submit_enough_profile() {
      var that = this
      var dataRequest = {
        id:this.model.id,
        code_change:this.$constants.STATUS_ORDER.ENOUGH_DOCUMENTS_CLOSE_APPLICATION,
      }
      var status =  await this.update_status_order(dataRequest)
      if (status == true){
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });
        location.reload()
      }else {
        that.$toast.add({
          severity: "error",
          summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000,
        });
      }
    },
    async validateDataPopupPakage() {
      //var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;
     if (this.data_popup_update_pakage.obj_data.combine_packages){
       var code_combine_packages = document.getElementById("code_combine_packages");
       // var is_err = 0;
       if (this.$commonFuction.isEmpty(this.data_popup_update_pakage.obj_data.code_combine_packages)) {
         //     is_err = 1;
         code_combine_packages.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
       } else {
         var where_obj = {code: {'_eq': this.data_popup_update_pakage.obj_data.code_combine_packages},id: {_neq: this.model.id}};
         var obj_data = await this.$CoreService.getObjectData('order',where_obj);
         if (this.$commonFuction.isEmpty(obj_data)){
           code_combine_packages.setCustomValidity("Đơn hàng ghép kiện không hợp lệ");
         }else {
           this.data_popup_update_pakage.obj_data.id_combine_packages= obj_data.id
           code_combine_packages.setCustomValidity("");
         }

       }
     }


      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    async process_submit_pakage() {
      var that = this
      if (await this.validateDataPopupPakage()) {
        // this.saving = false;
        // this.$commonFuction.is_loading(false);
        return
      }
      if (this.data_popup_update_pakage.list_pakage_data.length <1){
        alert("Vui lòng thêm kiện hàng");
        return;
      }else {
        if (this.data_popup_update_pakage.obj_data.combine_packages==false){
          var list_package_ = this.data_popup_update_pakage.list_pakage_data
          for (var k=0;k<list_package_.length;k++){
            var item_pa = list_package_[k];
            var parcel_qty = item_pa.parcel_qty
            var parcel_id_key = item_pa.parcel_id
            if ( this.$commonFuction.isEmpty(parcel_qty) || this.$commonFuction.isEmpty(parcel_id_key)) {
              alert("Vui lòng nhập đầy đủ thông tin kiện hàng")
              //this.$commonFuction.is_loading(false);
              return;
            }
            if (parcel_qty <= 0 ){
              alert("Vui lòng nhập số lượng kiện hàng lớn hơn 0")
              //this.$commonFuction.is_loading(false);
              return;
            }
          }
        }
      }
      var dataRequest = {
        id:this.model.id,
        combine_packages:this.data_popup_update_pakage.obj_data.combine_packages,
        //combine_packages:this.obj_data.code_combine_packages,
        list_pakage_data:this.data_popup_update_pakage.list_pakage_data,
        id_combine_order:this.data_popup_update_pakage.obj_data.id_combine_packages,
      }

      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/process-update-pakage', dataRequest, {headers});
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });
        location.reload()
        return true
      } catch (err) {
        var message = err.response.data.message;
        console.log("messagemessage:",message)
        if (message=='UPDATE_STATUS_ERR'){
          //var list_err = err.response.data.data
          alert("Không thể cập nhật trạng thái")
        }else if (message=='JOINT_ORDER_COMBINE_ERR') {
          alert("Bạn không thể ghép kiện với đơn hàng "+this.data_popup_update_pakage.obj_data.code_combine_packages)
        }
        else if (message=='JOINT_ORDER_COMBINE_OTHER_CUSTOMER_ERR') {
          alert("Đơn hàng không cùng khách hàng bạn không thể ghép kiện.")
        }
        else {
          that.$toast.add({
            severity: "error",
            summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
            detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
            life: 3000,
          });
        }
        console.log(err);
        return false;
      }
    },
    async delete_pakage_popup(key_data) {
      var list_pakage_data = [];
      var list_pakage_data_convert = this.data_popup_update_pakage.list_pakage_data;
      for (var i=0;i<list_pakage_data_convert.length;i++){
        var item_data = list_pakage_data_convert[i];
        var key_data_check = item_data.key_data;
        if (key_data!==key_data_check){
          list_pakage_data.push(item_data)
        }
      }
      this.data_popup_update_pakage.list_pakage_data = list_pakage_data
    },
    async add_pakage_popup() {
      var item_pakage = {
            parcel_id:null,
            parcel_qty:0,
            key_data:(new Date()).getTime()+this.$commonFuction.makeId(8),
            list_parcel_key:[]
          }
      this.data_popup_update_pakage.list_pakage_data.push(item_pakage)
    },
    async validateDataPopupCancelOrder() {
      //var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;
      var data_popup_cancel_order_reason_id = document.getElementById("data_popup_cancel_order_reason_id");
      // var is_err = 0;
      if (this.$commonFuction.isEmpty(this.data_popup_cancel_order.reason_id)) {
        //     is_err = 1;
        data_popup_cancel_order_reason_id.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        data_popup_cancel_order_reason_id.setCustomValidity("");
      }
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    async process_cancel_order() {
      var that = this
      if (await this.validateDataPopupCancelOrder()) {
        // this.saving = false;
        // this.$commonFuction.is_loading(false);
        return
      }

      var dataRequest = {
        id:this.model.id,
        reason_id:this.data_popup_cancel_order.reason_id,
        code_change:this.data_popup_cancel_order.status_code,
        //code_change:this.$constants.STATUS_ORDER.CANCELED,
      }
      var status =  await this.update_status_order(dataRequest)
      if (status == true){
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });
        location.reload()
      }else {
        that.$toast.add({
          severity: "error",
          summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000,
        });
      }
    },
    async update_status_order(dataRequest) {
      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/update-status-order', dataRequest, {headers});
        return true
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async process_confirm_payment() {
      var that = this
      var order_id = this.model.id;
      var data_update = {
          paid:true,
          date_paid:(new Date()).toISOString()
      }
      let variables = {
        data_update: data_update,
        where_key: {
          id: { _eq:order_id },
        },
      };
      await this.$apollo
          .mutate({
            mutation: DataServices.updateData("order"),
            variables: variables,
          })
          .then(async (res) => {/*
            var uid = this.$store.getters.user;
            var data_insert = {
              order_id:order_id,
              name_process_custom:"Xác nhận thanh toán",
              created_by:uid,
              updated_by:uid,
            }
            await  this.$CoreService.insert_process_order(data_insert);
*/
            var dataRequest = {
              id:this.model.id,
              code_change:this.$constants.STATUS_ORDER.CONFIRM_PAYMENT,
              name_process_custom:"Xác nhận thanh toán",
            }
           await this.update_status_order(dataRequest)
            console.log("Thành công");
            console.log(res); //
            this.$commonFuction.is_loading(false);
            that.$toast.add({
              severity: "success",
              summary: "Thông báo",
              detail: "Cập nhật thành công",
              life: 3000,
            });
            location.reload()
            //this.$router.push({ path: "/admin/list-reason" });
          })
          .catch(async (error) => {
            console.log("error", error);
            that.$toast.add({
              severity: "error",
              summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000,
            });
            this.$commonFuction.is_loading(false);
            // this.summitEvent = false;
          });

    },
    async list_parcel() {
      var gqLQueryListData = DataServices.getList('parcel');
      var where_data = {
        active: {_eq: true},
        deleted: {_eq: false},
      }
      var resData_data = await this.$apollo.mutate({
        mutation: gqLQueryListData,
        variables: {
          where_key:where_data ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{ordinal: 'asc'}
        }
      });
      var listData = resData_data.data['parcel'];
      var map_key = {};
      for (var i=0;i<listData.length;i++){
        var item_data = listData[i]
        var name = item_data.name
        if (!map_key[name]){
          map_key[name] = 0;
        }
        map_key[name] = map_key[name]+1;
        listData[i]["name"] = name.padEnd(name.length + map_key[name]);
      }
      return listData;

    },
    async list_reason(type=null) {
      var gqLQueryListData = DataServices.getList('reason');
      var where_data = {
        active: {_eq: true},
        deleted: {_eq: false},
      }
      if (!this.$commonFuction.isEmpty(type)) {
        where_data["type"] =  {_eq: type}
      }
      var resData_data = await this.$apollo.mutate({
        mutation: gqLQueryListData,
        variables: {
          where_key:where_data ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_data.data['reason'];
      return listData;

    },
    async validateDataPopupProduct() {
      //var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;
      var popup_product_id = document.getElementById("popup_product_product_id");
     // var is_err = 0;
      if (this.$commonFuction.isEmpty(this.data_popup_product.obj_product.product_id)) {
   //     is_err = 1;
        popup_product_id.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        popup_product_id.setCustomValidity("");
      }
      var popup_product_warehouse_id = document.getElementById("popup_product_warehouse_id");
      if (this.$commonFuction.isEmpty(this.data_popup_product.obj_product.warehouse_id)) {
      //  is_err = 1;
        popup_product_warehouse_id.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        popup_product_warehouse_id.setCustomValidity("");
      }
      /*var popup_product_lot_number = document.getElementById("popup_product_lot_number");
      if (this.$commonFuction.isEmpty(this.data_popup_product.obj_product.lot_number)) {
     //   is_err = 1;
        popup_product_lot_number.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        popup_product_lot_number.setCustomValidity("");
      }
      var popup_product_expiry_date = document.getElementById("popup_product_expiry_date");
      if (this.$commonFuction.isEmpty(this.data_popup_product.obj_product.expiry_date_key)) {
      ///  is_err = 1;
        popup_product_expiry_date.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        popup_product_expiry_date.setCustomValidity("");
      }*/
      var popup_product_qty = document.getElementById("popup_product_qty");
      if (this.$commonFuction.isEmpty(this.data_popup_product.obj_product.qty)) {
      //  is_err = 1;
        popup_product_qty.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        if (this.prefix_code == this.$constants.REFIX_CODE_ORDER_CK){
          //Chuyển kho có thể nhập số âm => nếu số âm thì đó là TH xuất
          if (parseFloat(this.data_popup_product.obj_product.qty) == 0 ){
            popup_product_qty.setCustomValidity(this.$constants.MSG_TEXT.GREATER_OTHER_0_VALIDATE);
          }else {
            popup_product_qty.setCustomValidity("");
          }
        }else {
          if (parseFloat(this.data_popup_product.obj_product.qty) <= 0 ){
            popup_product_qty.setCustomValidity(this.$constants.MSG_TEXT.GREATER_THAN_0_VALIDATE);
          }else {
            popup_product_qty.setCustomValidity("");
          }
        }

      }


/*
      var text_validator_email = "";
      if (!this.$commonFuction.isEmpty(this.user.email)) {
        console.log("this.model:",this.model);
        this.account_exist = await this.checkEmailAdminExist(this.user.email.trim(), this.model);
        if (this.account_exist) {
          text_validator_email = this.$constants.MSG_TEXT.EMAIL_EXIST_SYSTEM_MSG;
        }
      }

      var email = document.getElementById("email");
      if (!this.$commonFuction.isEmpty(text_validator_email)) {
        email.setCustomValidity(text_validator_email);
      } else {
        email.setCustomValidity("");
      }
      var password_confirm_text_va = "";
      if (!this.$commonFuction.isEmpty(this.user.password) && !this.$commonFuction.isEmpty(this.user.password_confirm)) {
        if (this.user.password !== this.user.password_confirm) {
          password_confirm_text_va = this.$constants.MSG_TEXT.PASSWORD_NOT_MATCH_MSG;
          //password_confirm.setCustomValidity(this.$constants.MSG_TEXT.PASSWORD_NOT_MATCH_MSG);
        }
      }
      var password_text_va = "";
      if (this.formMode !== 'add') {
        if (this.user.email != this.user.email_old){
          //trường hợp khác thì bắt nhập lại mật khẩu
          if (this.$commonFuction.isEmpty(this.user.password)){
            password_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG;
          }
          if (this.$commonFuction.isEmpty(this.user.password_confirm)){
            password_confirm_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG
          }
        }else {
          //
          if (!this.$commonFuction.isEmpty(this.user.password)){
            //trường hợp thay đổi thì bắt nhập passcomfirt
            if (this.$commonFuction.isEmpty(this.user.password_confirm)){
              password_confirm_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG
            }
          }
          if (!this.$commonFuction.isEmpty(this.user.password_confirm)){
            //trường hợp thay đổi thì bắt nhập password
            if (this.$commonFuction.isEmpty(this.user.password)){
              password_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG;
            }
          }
        }
      }
      var password_confirm = document.getElementById("password_confirm");
      if (!this.$commonFuction.isEmpty(password_confirm_text_va)){
        password_confirm.setCustomValidity(password_confirm_text_va);
      }else {
        password_confirm.setCustomValidity("");
      }

      var password = document.getElementById("password");
      if (!this.$commonFuction.isEmpty(password_text_va)){
        password.setCustomValidity(password_text_va);
      } else {
        password.setCustomValidity("");
      }*/

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    async save_product_popup() {
      //this.obj_id

      if (await this.validateDataPopupProduct()) {
        // this.saving = false;
       // this.$commonFuction.is_loading(false);
        return
      }
      var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;
      console.log("key_data",key_data)
     /* var key_check_duplicate = this.data_popup_product.obj_product.product_id+this.data_popup_product.obj_product.warehouse_id+this.data_popup_product.obj_product.lot_number+this.data_popup_product.obj_product.expiry_date
      if ((this.$commonFuction.isEmpty(key_data)) ){
        //them moi
        console.log("sssssss")
        for (var i=0;i<this.listDataOrderDetail.length;i++){
            var item_data_check =  this.listDataOrderDetail[i]
          console.log("item_data_check",item_data_check)
          var expiry_date_root =  moment(item_data_check.expiry_date,"yyyy-MM-DD").format("yyyy-MM-DD");
            var key_check_add = item_data_check.product_id+item_data_check.warehouse_id+item_data_check.lot_number+expiry_date_root
          console.log("key_check_add:",key_check_add)
          console.log("key_check_duplicate:",key_check_duplicate)
            if (key_check_add == key_check_duplicate){
              alert("Sản phẩm đã tồn tại!")
              return false;
            }
        }
      }else {
        //edit
        console.log("listDataOrderDetaillistDataOrderDetaillistDataOrderDetail")
        for (var j=0;j<this.listDataOrderDetail.length;j++){
          console.log("listDataOrderDetaillistDataOrderDetaillistDataOrderDetail")
          var item_data_check_ =  this.listDataOrderDetail[j]
          var key_data_check_edit = item_data_check_.key_data
          if (key_data_check_edit != key_data){
            console.log("66666666666666666666")
            var expiry_date_root_ =  moment(item_data_check.expiry_date,"yyyy-MM-DD").format("yyyy-MM-DD");
            var key_check_edit = item_data_check_.product_id+item_data_check_.warehouse_id+item_data_check_.lot_number+expiry_date_root_
            console.log("key_check_edit:",key_check_edit)
            console.log("key_check_duplicate:",key_check_duplicate)
            if (key_check_edit == key_check_duplicate){
              alert("Sản phẩm đã tồn tại!")
              return false;
            }
          }
        }
      }*/
      console.log("save_product_popup:")
      //var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;
      var warehouse_id = this.data_popup_product.obj_product.warehouse_id
      var item_warehouse = this.map_warehouse[warehouse_id];
      console.log("item_warehouse:",item_warehouse)
      this.data_popup_product.obj_product.warehouse_code = item_warehouse.warehouse.code
      this.data_popup_product.obj_product.warehouse_id = item_warehouse.warehouse.id
      if ((this.$commonFuction.isEmpty(key_data)) ){
        this.data_popup_product.obj_product.key_data = (new Date()).getTime()+""
        //Thêm mới
        this.process_save_product(1,this.data_popup_product.obj_product)
      }else {
        //chỉnh sửa
        this.process_save_product(2,this.data_popup_product.obj_product)
      }

    },
    async process_save_product(type,params=null) {
      console.log("paramsparams:",params)
      console.log("paramsparams:",type)
     if (type==1){
       //console.log("this.map_expiry_date_group[this.data_popup_product.obj_product.expiry_date_key]:",this.map_expiry_date_group[this.data_popup_product.obj_product.expiry_date_key])
       if (this.data_popup_product.obj_product.expiry_date_key) {
         params["expiry_date"] = (this.map_expiry_date_group[this.data_popup_product.obj_product.expiry_date_key]["expiry_date_root"]) ? this.map_expiry_date_group[this.data_popup_product.obj_product.expiry_date_key]["expiry_date_root"] : null
       }
       params = this.$commonFuction.convertJsonObject(params)
       this.listDataOrderDetail.push(params)
       console.log("this.listDataOrderDetail:",this.listDataOrderDetail)
     }else {
       var key_check = params.key_data
       var list_data_res = []
       console.log('listDataOrderDetail', this.listDataOrderDetail.length)
       for (var i=0;i<this.listDataOrderDetail.length;i++){
          var item_data =  this.listDataOrderDetail[i];
          var key_data = item_data.key_data
          if (key_check == key_data){
            if (type==2){
              console.log("this.data_popup_product.obj_product.expiry_date_key:",this.data_popup_product.obj_product.expiry_date_key)
              if (this.data_popup_product.obj_product.expiry_date_key) {
                params["expiry_date"] = (this.map_expiry_date_group[this.data_popup_product.obj_product.expiry_date_key]["expiry_date_root"]) ? this.map_expiry_date_group[this.data_popup_product.obj_product.expiry_date_key]["expiry_date_root"] : null
              }
              //update
              list_data_res.push(params);
            }else {
              //xóa || ko xử lý
              console.log("xoa")
            }
          }else {
            list_data_res.push(item_data)
          }
       }
       console.log("list_data_res:",list_data_res)
       this.listDataOrderDetail = list_data_res;

     }
      this.dataModelOrderDetail.processData(this.listDataOrderDetail)
     this.componentKeyOrderDetail++;
     this.data_popup_product.display_add_product=false;

    },
    async change_lot_popup() {
      var list_expired = this.group_lot_number_date[this.data_popup_product.obj_product.lot_number];
      const uniqueArray = [...new Set(list_expired)];
      console.log("list_expiredlist_expired:",uniqueArray)
      var list = []
      for (var i=0;i<uniqueArray.length;i++){
        var item_data = this.map_expiry_date_group[uniqueArray[i]]
        list.push(item_data)
      }
      this.list_expiry_date_group = list
    },
    async change_date_popup() {
      var list_ = this.list_expiry_date_group_lot[this.data_popup_product.obj_product.expiry_date_key];
      const uniqueArray = [...new Set(list_)];
      console.log("list_expiredlist_expired:",uniqueArray)
      var list = []
      for (var i=0;i<uniqueArray.length;i++){
        var item_data = this.group_data_lot_number[uniqueArray[i]]
        list.push(item_data)
      }
      this.list_lot_number_group = list
    },
    async change_warehouse_popup() {
      this.data_popup_product.obj_product.product_id = null;
      this.data_popup_product.obj_product.product_code = null;
      this.data_popup_product.obj_product.unit_name = null;
      this.data_popup_product.obj_product.lot_number = null;
      this.data_popup_product.obj_product.expiry_date_key = null;
      this.list_lot_number_group = this.list_lot_number_group_tmp
      this.list_expiry_date_group = this.list_expiry_date_group_tmp
      this.map_expiry_date_group = this.map_expiry_date_group_tmp
      this.load_popup_product();
    },
    async change_product_popup() {
      //this.obj_id
      console.log(this.data_popup_product);
      var product_id = this.data_popup_product.obj_product.product_id
      //this.data_popup_product.obj_product = {}
     /* console.log()
      if ((!this.$commonFuction.isEmptyObject(this.data_popup_product.obj_product))){
        console.log("asssssssssssssssss,",this.data_popup_product.obj_product)
        this.data_popup_product.obj_product.lot_number = null
        this.data_popup_product.obj_product.expiry_date = null
        this.data_popup_product.obj_product.material_code = null
        this.data_popup_product.obj_product.warehouse_code = null
        this.data_popup_product.obj_product.warehouse_id = null
      }else {
        console.log("asdddddddddddd")
      }*/

      this.data_popup_product.obj_product.product_id = product_id
      var item_product = this.map_convert_data[product_id];
      console.log("item_productitem_product:",item_product)
      this.data_popup_product.obj_product.product_code = item_product.code
      this.data_popup_product.obj_product.product_name = item_product.name
      this.data_popup_product.obj_product.unit_name = (!this.$commonFuction.isEmpty(item_product.unit)) ? item_product.unit.name:null;
      this.data_popup_product.obj_product.unit_id = (!this.$commonFuction.isEmpty(item_product.unit)) ? item_product.unit.id:null;
      this.data_popup_product.obj_product.material_code = (!this.$commonFuction.isEmpty(item_product.material)) ? item_product.material.code:null;
      this.data_popup_product.obj_product.material_id = (!this.$commonFuction.isEmpty(item_product.material)) ? item_product.material.id:null;
      console.log("this.map_convert_data",this.map_convert_data[product_id])
      //this.load_warehouse(product_id,1)
      this.list_lot_number_group = []
      this.data_popup_product.obj_product.lot_number = null;
      this.data_popup_product.obj_product.expiry_date_key = null;
      this.load_expiry_lot(0)
    },
    async load_warehouse(product_id,is_change_product=0) {
      console.log(is_change_product)
      //this.obj_id
      console.log("product_id",product_id)
      var gqLQueryListOrderDocs = DataServices.getList('stock_quant');
      var where_user_docs = {
      //  product_id: {_eq: product_id},
        //qty: {_gt: 0},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['stock_quant'];
      var map_warehouse = {}
      var list_convert_order = []
      var group_data_lot_number = {};
      var group_lot_number = {};
      var group_lot_number_date = {};
      var group_expiry_date = {};
      var list_lot_number_group =[];
      var list_expiry_date_group =[];
      var list_expiry_date_group_lot ={};
      var map_expiry_date_group =[];
      var check_dulicate_warehouse_id={};
      for (var i=0;i<listData.length;i++){
        var item_data = listData[i];
        item_data["warehouse_name"] = (!this.$commonFuction.isEmpty(item_data.warehouse)) ? item_data.warehouse.name:null;
        var warehouse_id = (!this.$commonFuction.isEmpty(item_data.warehouse)) ? item_data.warehouse.id:null;
        item_data["warehouse_id"] = warehouse_id;
        map_warehouse[warehouse_id] = item_data
        var lot_number = item_data.lot_number
        //var expiry_date_root = item_data.expiry_date
        var expiry_date_root =  (item_data.expiry_date) ? moment(item_data.expiry_date,"yyyy-MM-DD").format("yyyy_MM_DD") :null
        //var expiry_date_root =  moment(item_data.expiry_date,"yyyy-MM-DD").format("DD_MM_yyyy");
        var expiry_date = (item_data.expiry_date) ?  moment(item_data.expiry_date,"yyyy-MM-DD").format("DD/MM/yyyy"):null;
        console.log("expiry_date:",expiry_date)
        if (this.$commonFuction.isEmpty(group_lot_number[lot_number])){
          group_lot_number[lot_number] = []
          var item_lot = {
            key_lot :lot_number
          }
          group_data_lot_number[lot_number] = item_lot
          list_lot_number_group.push(item_lot)
          group_lot_number_date[lot_number] = []
        }

        var group_lot_number_date_list = group_lot_number_date[lot_number]
        group_lot_number_date_list.push(expiry_date_root);
        group_lot_number_date[lot_number] = group_lot_number_date_list
        var list_lot_number = group_lot_number[lot_number]
        list_lot_number.push(expiry_date)

        if (this.$commonFuction.isEmpty(group_expiry_date[expiry_date_root])){
          group_expiry_date[expiry_date_root] = []
          var item_expiry = {
            expiry_date_root :item_data.expiry_date,
            key_expiry_date :expiry_date_root,
            text_expiry_date :expiry_date,
          }
          map_expiry_date_group[expiry_date_root] = item_expiry
          list_expiry_date_group.push(item_expiry)
          list_expiry_date_group_lot[expiry_date_root] = []
        }
        var list_expiry_date_group_lot_list = list_expiry_date_group_lot[expiry_date_root]
        list_expiry_date_group_lot_list.push(lot_number);
        list_expiry_date_group_lot[expiry_date_root] = list_expiry_date_group_lot_list
        var list_expiry_date = group_expiry_date[expiry_date_root]
        list_expiry_date.push(lot_number)
        if (this.$commonFuction.isEmpty(check_dulicate_warehouse_id[item_data.warehouse_id])) {
          check_dulicate_warehouse_id[item_data.warehouse_id] = item_data
          list_convert_order.push(item_data);
        }
      }
      this.map_warehouse = map_warehouse
      this.data_popup_product.list_warehouse = list_convert_order
      console.log("this.data_popup_product.list_warehouse",this.data_popup_product.list_warehouse)

      /*if (is_change_product==0){
        this.list_lot_number_group = list_lot_number_group
        console.log("list_expiry_date_grouplist_expiry_date_group:",list_expiry_date_group)
        this.list_expiry_date_group = list_expiry_date_group
      }
      this.map_expiry_date_group = map_expiry_date_group
      this.list_lot_number_group_tmp = list_lot_number_group
      this.list_expiry_date_group_tmp = list_expiry_date_group
      this.map_expiry_date_group_tmp = map_expiry_date_group
      this.list_expiry_date_group_lot = list_expiry_date_group_lot
      this.group_lot_number_date = group_lot_number_date
      this.group_data_lot_number = group_data_lot_number*/

    },
    async load_expiry_lot(product_id,is_change_product=0) {
      //this.obj_id
      console.log("product_id",product_id)
      var gqLQueryListOrderDocs = DataServices.getList('stock_quant');
      var where_user_docs = {
          product_id: {_eq: this.data_popup_product.obj_product.product_id},
        warehouse_id: {_eq: this.data_popup_product.obj_product.warehouse_id},
        //qty: {_gt: 0},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['stock_quant'];
      var map_warehouse = {}
      var list_convert_order = []
      var group_data_lot_number = {};
      var group_lot_number = {};
      var group_lot_number_date = {};
      var group_expiry_date = {};
      var list_lot_number_group =[];
      var list_expiry_date_group =[];
      var list_expiry_date_group_lot ={};
      var map_expiry_date_group =[];
      var check_dulicate_warehouse_id={};
      for (var i=0;i<listData.length;i++){
        var item_data = listData[i];
        item_data["warehouse_name"] = (!this.$commonFuction.isEmpty(item_data.warehouse)) ? item_data.warehouse.name:null;
        var warehouse_id = (!this.$commonFuction.isEmpty(item_data.warehouse)) ? item_data.warehouse.id:null;
        item_data["warehouse_id"] = warehouse_id;
        map_warehouse[warehouse_id] = item_data
        var lot_number = item_data.lot_number
        //var expiry_date_root = item_data.expiry_date
        var expiry_date_root =  (item_data.expiry_date) ? moment(item_data.expiry_date,"yyyy-MM-DD").format("yyyy_MM_DD"):null
        //var expiry_date_root =  moment(item_data.expiry_date,"yyyy-MM-DD").format("DD_MM_yyyy");
        var expiry_date = (item_data.expiry_date) ?  moment(item_data.expiry_date,"yyyy-MM-DD").format("DD/MM/yyyy"):null;
        console.log("expiry_date:",expiry_date)
        if (this.$commonFuction.isEmpty(group_lot_number[lot_number])){
          group_lot_number[lot_number] = []
          var item_lot = {
            key_lot :lot_number
          }
          group_data_lot_number[lot_number] = item_lot
          list_lot_number_group.push(item_lot)
          group_lot_number_date[lot_number] = []
        }

        var group_lot_number_date_list = group_lot_number_date[lot_number]
        group_lot_number_date_list.push(expiry_date_root);
        group_lot_number_date[lot_number] = group_lot_number_date_list
        var list_lot_number = group_lot_number[lot_number]
        list_lot_number.push(expiry_date)

        if (this.$commonFuction.isEmpty(group_expiry_date[expiry_date_root])){
          group_expiry_date[expiry_date_root] = []
          var item_expiry = {
            expiry_date_root :item_data.expiry_date,
            key_expiry_date :expiry_date_root,
            text_expiry_date :expiry_date,
          }
          map_expiry_date_group[expiry_date_root] = item_expiry
          list_expiry_date_group.push(item_expiry)
          list_expiry_date_group_lot[expiry_date_root] = []
        }
        var list_expiry_date_group_lot_list = list_expiry_date_group_lot[expiry_date_root]
        list_expiry_date_group_lot_list.push(lot_number);
        list_expiry_date_group_lot[expiry_date_root] = list_expiry_date_group_lot_list
        var list_expiry_date = group_expiry_date[expiry_date_root]
        list_expiry_date.push(lot_number)
        if (this.$commonFuction.isEmpty(check_dulicate_warehouse_id[item_data.warehouse_id])) {
          check_dulicate_warehouse_id[item_data.warehouse_id] = item_data
          list_convert_order.push(item_data);
        }
      }
      //this.map_warehouse = map_warehouse
      //this.data_popup_product.list_warehouse = list_convert_order
      console.log("this.data_popup_product.list_warehouse",this.data_popup_product.list_warehouse)

      if (is_change_product==0){
        this.list_lot_number_group = list_lot_number_group
        console.log("list_expiry_date_grouplist_expiry_date_group:",list_expiry_date_group)
        this.list_expiry_date_group = list_expiry_date_group
      }
      this.map_expiry_date_group = map_expiry_date_group
      this.list_lot_number_group_tmp = list_lot_number_group
      this.list_expiry_date_group_tmp = list_expiry_date_group
      this.map_expiry_date_group_tmp = map_expiry_date_group
      this.list_expiry_date_group_lot = list_expiry_date_group_lot
      this.group_lot_number_date = group_lot_number_date
      this.group_data_lot_number = group_data_lot_number

    },
    async onActionClickedOrderDetail(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      console.log("payloadpayloadpayload:",payload,action.name)
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.title_popup_add_product= "Chỉnh sửa"
          /*this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }*/
          this.data_popup_product.obj_product = this.$commonFuction.convertJsonObject(data.data)
          console.log("this.data_popup_product.obj_product:",this.data_popup_product.obj_product)
          this.data_popup_product.display_add_product = true;
          this.load_warehouse(0)
          this.load_popup_product()
          this.load_expiry_lot(0)
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          this.key_data_delete = data.data.key_data
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          console.log("key_data_delete",this.key_data_delete)

          break
        case 'add':
          this.title_popup_add_product= "Thêm mới"
          /*this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }*/
          this.data_popup_product.list_warehouse = [];
          this.list_lot_number_group = [];
          this.list_expiry_date_group = [];
          this.data_popup_product.obj_product = {}
          this.data_popup_product.display_add_product = true
          this.load_warehouse(0)
          this.load_popup_product()
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    async load_popup_product(whereAttach = null) {
      var warehouse_id = this.data_popup_product.obj_product.warehouse_id
      var gqLQueryListData = DataServices.getList('product');
      var where_data = {
        active: {_eq: true},
        deleted: {_eq: false},
      }
      var listData = [];
      if (!this.$commonFuction.isEmpty(warehouse_id)) {
        where_data["stock_quants"] = {warehouse_id: {_eq: warehouse_id}};
        if (whereAttach) {
          for (const [key, value] of Object.entries(whereAttach)) {
            where_data[key] = value;
          }
        }
        var resData_data = await this.$apollo.mutate({
          mutation: gqLQueryListData,
          variables: {
            where_key: where_data,
            /*offset:offset,
          limit:limit,*/
            orderBy: {created_at: 'desc'}
          }
        });
        listData = resData_data.data['product'];
      }
      var map_convert_data = {}
      for (var i=0;i<listData.length;i++){
        var item_data = listData[i];
        var id = item_data.id
        map_convert_data[id] = item_data
      }
      this.map_convert_data = this.$commonFuction.convertJsonObject( map_convert_data )
      this.data_popup_product.list_product = this.$commonFuction.convertJsonObject(listData)
      /*if (this.data_popup_product.obj_product.product_id){

      }*/
      this.load_data_popup_by_product();
      console.log("listDatalistData:",listData)
    },
    async  load_data_popup_by_product() {
      console.log("this.data_popup_product.obj_product.product_id:",this.data_popup_product.obj_product.product_id)
      if (this.data_popup_product.obj_product.product_id){
        /*var product_id = this.data_popup_product.obj_product.product_id;
        var get_item_product = this.map_convert_data[product_id];
        this.data_popup_product.obj_product.product_code = get_item_product["code"]
        this.data_popup_product.obj_product.unit_name = (!this.$commonFuction.isEmpty(get_item_product["unit"])) ? get_item_product["unit"]["name"]:null
        this.data_popup_product.obj_product.unit_id = (!this.$commonFuction.isEmpty(get_item_product["unit"])) ? get_item_product["unit"]["id"]:null
*/
        /*if (!this.$commonFuction.isEmpty(this.data_popup_product.obj_product.product_id)) {
          this.load_warehouse(this.data_popup_product.obj_product.product_id)
        }*/

        console.log("aaaass")
      }else {
      console.log("aaaa")
      }
    },
    getRowActionsOrderItem() {
      if (this.mode == "edit")
      {
      var actions = {}
      var that = this;
      /*this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD).then( (res)  => {
       if (res){
         actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
       }
      })*/
      //if (this.role.add) {
        actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`,disabled:this.disable_common};
      //}
      //if ((await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD))){

      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        console.log("5555555555555555555")
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
         //if (that.role.edit) {
           buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`,disabled:this.disable_common};
         //}
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
         //if (that.role.delete) {
           buttons.delete = {name: 'delete', icon: 'trash', key: 'id',disabled:this.disable_common}
         //}
        console.log("buttonsbuttonsbuttons:",that.dataModelOrderDetail)
        console.log("that.dataModel.actions:",that.dataModelOrderDetail.actions)
        if (that.dataModelOrderDetail.actions) {
          var modelaction = that.dataModelOrderDetail.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
      }
    },
    async click_change_status(code) {
      var code_order = this.obj_data.code
      var prefix_code = code_order.substring(0, 2);
      switch (code) {
        case this.$constants.STATUS_ORDER.EDIT:
          var url_edit = `/admin/list-order/edit/${this.model.id}`
          if (!this.$commonFuction.isEmpty(this.$route.query)){
            if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
              url_edit +=`?page_transaction=${this.$route.query.page_transaction}`
            }
          }
          console.log("url_editurl_edit:",url_edit)
          window.location.href = url_edit
          ///this.$router.push({ path: '/admin/list-order/edit/'+this.model.id })
          //this.$router.push({ path: '/admin/list-order/edit/'+this.model.id ,query: { key_tmp: (new Date()).getTime() } })
          break;
        case this.$constants.STATUS_ORDER.CANCELED:
          //load danh sách lý do
          this.data_popup_cancel_order.status_code = code
          this.data_popup_cancel_order.title = 'Hủy đơn'
          this.data_popup_cancel_order.list_reason = await this.list_reason(3)
          this.data_popup_cancel_order.display_cancel_order = true;
          break;
        case this.$constants.STATUS_ORDER.UPDATED_DISPATCHER_ADDRESS:
          if (prefix_code!==this.$constants.REFIX_CODE_ORDER_RETRUN) {
            if (((this.obj_data.paid == false || this.$commonFuction.isEmpty(this.obj_data.paid)) && this.obj_data.request_payment == true)) {
              alert("Vui lòng xác nhận thanh toán!");
              return false;
            }
          }
          //Lấy địa chỉ chành
            if (prefix_code==this.$constants.REFIX_CODE_ORDER_CK){
              //đơn hàng chuyển kho thì ko có KH || defaut là Kh
              console.log("asdasd")
              await this.get_order_ship()
              this.data_popup_update_address_coordinator.display = true;
              this.data_popup_update_address_coordinator.key++
              //this.data_popup_update_address_coordinator.type_delivery = '1'
            }else {
              await this.get_order_ship()
              this.data_popup_update_address_coordinator.list_freight =await this.list_freight_customer(this.model.customer_id);
              this.data_popup_update_address_coordinator.display = true;
              this.data_popup_update_address_coordinator.key++
            }
          break;
        case this.$constants.STATUS_ORDER.PACKAGED:
          this.data_popup_update_pakage.list_parcel = await this.list_parcel()
          this.data_popup_update_pakage.obj_data.combine_packages =false;
          this.data_popup_update_pakage.obj_data.code_combine_packages ='';
          this.data_popup_update_pakage.list_pakage_data =[];
          this.add_pakage_popup()
          this.data_popup_update_pakage.display = true;
          break;
        case this.$constants.STATUS_ORDER.ENOUGH_DOCUMENTS_CLOSE_APPLICATION:
          this.data_popup_confirm_close_profile.display = true;
          break;
        case this.$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING:
          this.data_popup_confirm_profile.display = true;
          break;
        case 'CONFIRM_PAYMENT':
          this.display_confirm_payment = true;
          break;
        case 'CONFIRMATION_RECEIPT':
          this.$router.push({ path: '/admin/list-order/confirm-order/'+this.model.id ,query: {type:1, key_tmp: (new Date()).getTime() } })
          break;
        case 'REASON_RETURN':
          //load danh sách lý do
          this.data_popup_cancel_order.status_code = code
          this.data_popup_cancel_order.title = 'Lý do trả hàng'
          this.data_popup_cancel_order.list_reason = await this.list_reason(1)
          this.data_popup_cancel_order.display_cancel_order = true;
          //this.$router.push({ path: '/admin/list-order/confirm-order/'+this.model.id ,query: {type:2, key_tmp: (new Date()).getTime() } })
          break;
        default:
          //text = "Looking forward to the Weekend";
      }
    },
    async load_button_detail() {
     // console.log("obj_dataobj_data:",this.obj_data)
      try {
        var is_reason_return = 0;
        if (this.prefix_code == this.$constants.REFIX_CODE_ORDER_RETRUN){
            //lấy trạng thái đã cập nhật lý do trả hàng chưa
          var where_reason_return = {
            code_status: {'_eq': 'REASON_RETURN'},
            order_id: {_eq: this.model.id}
          };
          var obj_reason_return = await this.$CoreService.getObjectData('history_status_order', where_reason_return);
          if (!this.$commonFuction.isEmpty(obj_reason_return)){
            is_reason_return = 1;
          }
        }
        var status_code = this.obj_data.order_status.code;
        var dataRequest = {
          order_id: this.model.id,
        };
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
       var list_action_detail = await ApiRepository.post('/_api/order/list_status_next_order', dataRequest, {headers});
       var list_action = (list_action_detail["data"]["data"]["list_action_detail"]) ? list_action_detail["data"]["data"]["list_action_detail"]:[]
       console.log("ddddddddddddddddddddddddddddd:",list_action)
        var type_order = (list_action_detail["data"]["data"]["type_order"]) ? list_action_detail["data"]["data"]["type_order"]:[]
        this.type_order = type_order
        var list_action_convert = [];
        for (var i=0;i<list_action.length;i++){
          var item_data = list_action[i];
          var code_change =  item_data.code_change;
          if (code_change == this.$constants.STATUS_ORDER.CONFIRM_PAYMENT){
            if (!this.$commonFuction.isEmpty(this.obj_data.request_payment ) && this.obj_data.request_payment ==true){
              console.log("asdddddddddd:",this.obj_data.request_payment)
              console.log("this.obj_data.paid:",this.obj_data.paid)
              if (this.$commonFuction.isEmpty(this.obj_data.paid) || this.obj_data.paid==false){
                console.log("ddddddddddddddd")
                list_action_convert.push(item_data);
              }
            }
                /*if (!( this.obj_data.paid==true || this.obj_data.request_payment==false )){
                  list_action_convert.push(item_data);
                }*/
          }else if (code_change == this.$constants.STATUS_ORDER.CONFIRMATION_RECEIPT){
            console.log("this.obj_data.confirm_receipt:",this.obj_data.confirm_receipt)
           // console.log("this.obj_data.confirm_receipt:",this.$commonFuction.isEmpty( this.obj_data.confirm_receipt))
            if (this.$commonFuction.isEmpty( this.obj_data.confirm_receipt) || this.obj_data.confirm_receipt==false){
              list_action_convert.push(item_data);
            }
          }else if (code_change == this.$constants.STATUS_ORDER.REASON_RETURN){
            // console.log("this.obj_data.confirm_receipt:",this.obj_data.confirm_receipt)
            // console.log("this.obj_data.confirm_receipt:",this.$commonFuction.isEmpty( this.obj_data.confirm_receipt))
            if (is_reason_return==0){
              list_action_convert.push(item_data);
            }
          }
          else {
            if (type_order==2){
              //đơn trả hàng
              if (status_code == this.$constants.STATUS_ORDER.DONE){
                if (!this.$commonFuction.isEmpty(this.obj_data.confirm_receipt)){
                  list_action_convert.push(item_data);
                }
              }else {
                list_action_convert.push(item_data);
              }
            }else {
              list_action_convert.push(item_data);
            }
          }
        }
       this.list_action_detail = list_action_convert
        console.log("list_action_detail",this.list_action_detail)
        //check quyền
        var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ORDER);
        console.log("CANCELED21",arr_role.indexOf(this.$constants.ROLE.CANCELED))
        if (this.mode == 'detail'){
          if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
            this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
          }
        }
       //if (arr_role.indexOf(this.$constants.ROLE.CANCELED) < 0) {
          this.role.CANCELED = (arr_role.indexOf(this.$constants.ROLE.CANCELED) < 0) ? false:true;
        //}

       // if (arr_role.indexOf(this.$constants.ROLE.EDIT_ORDER) < 0) {
          this.role.EDIT_ORDER = (arr_role.indexOf(this.$constants.ROLE.EDIT_ORDER) < 0) ? false:true;
      //  }

       // if (arr_role.indexOf(this.$constants.ROLE.PACKAGED) < 0) {
          this.role.PACKAGED = (arr_role.indexOf(this.$constants.ROLE.PACKAGED) < 0) ? false:true;
       // }
       // if (arr_role.indexOf(this.$constants.ROLE.CONFIRM_DISPATCHER_ADDRESS) < 0) {
          this.role.CONFIRM_DISPATCHER_ADDRESS = (arr_role.indexOf(this.$constants.ROLE.CONFIRM_DISPATCHER_ADDRESS) < 0) ? false:true;
       // }
        console.log('arr_role.indexOf(this.$constants.ROLE.UPDATED_DISPATCHER_ADDRESSS) < 0:',arr_role.indexOf(this.$constants.ROLE.UPDATED_DISPATCHER_ADDRESSS))
       // if (arr_role.indexOf(this.$constants.ROLE.UPDATED_DISPATCHER_ADDRESSS) < 0) {
          this.role.UPDATED_DISPATCHER_ADDRESSS = (arr_role.indexOf(this.$constants.ROLE.UPDATED_DISPATCHER_ADDRESSS) < 0) ? false:true;
      //  }
        //if (arr_role.indexOf(this.$constants.ROLE.COORDINATION_COMPLETED) < 0) {
          this.role.COORDINATION_COMPLETED = (arr_role.indexOf(this.$constants.ROLE.COORDINATION_COMPLETED) < 0) ? false:true;
        //}
       // if (arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS) < 0) {
          this.role.DELIVERY_STATUS = (arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS) < 0) ? false:true;
      //  }
       // if (arr_role.indexOf(this.$constants.ROLE.TRANSFER_HSCT_ACCOUNTING) < 0) {
          this.role.TRANSFER_HSCT_ACCOUNTING = (arr_role.indexOf(this.$constants.ROLE.TRANSFER_HSCT_ACCOUNTING) < 0) ? false:true;
       // }
        //if (arr_role.indexOf(this.$constants.ROLE.ENOUGH_DOCUMENTS_CLOSE_APPLICATION) < 0) {
          this.role.ENOUGH_DOCUMENTS_CLOSE_APPLICATION = (arr_role.indexOf(this.$constants.ROLE.ENOUGH_DOCUMENTS_CLOSE_APPLICATION) < 0) ? false:true;
      //  }
        //if (arr_role.indexOf(this.$constants.ROLE.CONFIRM_PAYMENT) < 0) {
          this.role.CONFIRM_PAYMENT = (arr_role.indexOf(this.$constants.ROLE.CONFIRM_PAYMENT) < 0) ? false:true;
       // }
        //if (arr_role.indexOf(this.$constants.ROLE.CONFIRMATION_RECEIPT) < 0) {
          this.role.CONFIRMATION_RECEIPT = (arr_role.indexOf(this.$constants.ROLE.CONFIRMATION_RECEIPT) < 0) ? false:true;

          this.role.REASON_RETURN = (arr_role.indexOf(this.$constants.ROLE.REASON_RETURN) < 0) ? false:true;
       // }
        /*if (arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS) < 0) {
          this.role.DELIVERY_STATUS = (arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS) < 0) ? false:true;
        }


        if (arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS) < 0) {
          this.delivery_status = (arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS) < 0) ? false:true;
        }
        if (arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS) < 0) {
          this.delivery_status = (arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS) < 0) ? false:true;
        }*/

      console.log("this.list_action_detailthis.list_action_detail",this.list_action_detail)
      console.log("this.arr_rolearr_role.arr_role",arr_role)

          var list_action_sang =[]
          for (var s = 0 ; s < this.list_action_detail.length ; s++){
            var code_change_key = this.list_action_detail[s].code_change
            if (code_change_key == this.$constants.STATUS_ORDER.EDIT){
              if (this.role.EDIT_ORDER == true) {
                list_action_sang.push(this.list_action_detail[s])
              }
            }

            if (code_change_key == this.$constants.STATUS_ORDER.PACKAGED){
              if (this.role.PACKAGED == true) {
                list_action_sang.push(this.list_action_detail[s])
              }
            }

            if (code_change_key == this.$constants.STATUS_ORDER.UPDATED_DISPATCHER_ADDRESS){
              console.log("3333333333333333333333333333:",this.role.UPDATED_DISPATCHER_ADDRESSS)
              if (this.role.UPDATED_DISPATCHER_ADDRESSS == true) {
                console.log("444444444444444")
                list_action_sang.push(this.list_action_detail[s])
              }
            }


            if (code_change_key == this.$constants.STATUS_ORDER.COORDINATION_COMPLETED){
              if (this.role.COORDINATION_COMPLETED == true) {
                list_action_sang.push(this.list_action_detail[s])
              }
            }

            if (code_change_key == this.$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING){
              if (this.role.TRANSFER_HSCT_ACCOUNTING == true) {
                list_action_sang.push(this.list_action_detail[s])
              }
            }


            if (code_change_key == this.$constants.STATUS_ORDER.ENOUGH_DOCUMENTS_CLOSE_APPLICATION){
              if (this.role.ENOUGH_DOCUMENTS_CLOSE_APPLICATION == true) {
                list_action_sang.push(this.list_action_detail[s])
              }
            }

            if (code_change_key == this.$constants.STATUS_ORDER.CANCELED){
              if (this.role.CANCELED == true) {
                list_action_sang.push(this.list_action_detail[s])
              }
            }

            if (code_change_key == this.$constants.STATUS_ORDER.CONFIRM_PAYMENT){
              if (this.role.CONFIRM_PAYMENT == true) {
                list_action_sang.push(this.list_action_detail[s])
              }
            }
            if (code_change_key == this.$constants.STATUS_ORDER.CONFIRMATION_RECEIPT){
              if (this.role.CONFIRMATION_RECEIPT == true) {
                list_action_sang.push(this.list_action_detail[s])
              }
            }

            if (code_change_key == this.$constants.STATUS_ORDER.REASON_RETURN){
              if (this.role.REASON_RETURN == true) {
                list_action_sang.push(this.list_action_detail[s])
              }
            }
/*

            if (this.list_action_detail[s].code_change == "ST002") {
              console.log("ST002")
              if (this.edit_order == true) {
                list_action_sang.push(this.list_action_detail[s])
              }
            }
            else  if ( this.list_action_detail[s].code_change == "ST005") {
              console.log("ST005")
              if (this.canceled == true){
                list_action_sang.push(this.list_action_detail[s])
              }
            }
            else  if ( this.list_action_detail[s].code_change == "ST016") {
              console.log("ST016")
              if (this.canceled == true){
                list_action_sang.push(this.list_action_detail[s])
              }
            }
              else if (this.list_action_detail[s].code_change == "CONFIRM_PAYMENT") {
              console.log("CONFIRM_PAYMENT")
                if (this.delivery_status == true) {

                  list_action_sang.push(this.list_action_detail[s])
                }
              }*/


            }
          this.list_action_detail = list_action_sang

        console.log("list_action_sang",list_action_sang)



       //console.log("list_action_detail:",list_action_detail["data"]["data"]["list_action_detail"])
      } catch (err) {
        console.log(err);
      }

    },
    async load_list_detail_order() {
      //this.obj_id
      var gqLQueryListOrderDocs = DataServices.getList('order_item');
      var where_user_docs = {
        order_id: {_eq: this.model.id},
        deleted: {_eq: false},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{ordinal: 'asc'}
        }
      });
      var listData = resData_docs.data['order_item'];
      var listdata_convert = []
      //var count_stt = 0;
      for (var i=0;i<listData.length;i++){
        var item_order =listData[i]
        /*var item_convert = { ...item_order };
        console.log("item_orderitem_order:",item_order)*/
        //listdata_convert.push(item_order)
        //count_stt++;
        var item_convert = {
          key_data:item_order.id,
          id:item_order.id,
          product_id:item_order.product_id,
         // stt:count_stt,
          product_code:item_order.product_code,
          product_name:item_order.product_name,
          /*unit_name:item_order.unit.name,*/
          unit_name:item_order?.unit?.name ?? '',
          unit_id:item_order?.unit?.id ?? null,
          lot_number:item_order.lot_number,
          expiry_date:item_order.expiry_date,
          expiry_date_key: (item_order.expiry_date) ?  moment(item_order.expiry_date,"yyyy-MM-DD").format("yyyy_MM_DD"):null,
          material_id:  (!this.$commonFuction.isEmpty(item_order.material)) ? item_order.material.id:'',
          material_code:  (!this.$commonFuction.isEmpty(item_order.material)) ? item_order.material.code:'',
          warehouse_code:  (!this.$commonFuction.isEmpty(item_order.warehouseByWarehouseId)) ? item_order.warehouseByWarehouseId.code:'',
          warehouse_id:  (!this.$commonFuction.isEmpty(item_order.warehouseByWarehouseId)) ? item_order.warehouseByWarehouseId.id:'',
        }
        /*for (const [key, value] of Object.entries(item_convert_key)) {
          item_convert[key] = value
        }*/
        console.log("item_order.expiry_dateitem_order.expiry_date:",item_order.expiry_date)
        var export_qty =  (!this.$commonFuction.isEmpty(item_order.export_qty)) ? parseFloat(item_order.export_qty):0;
        var import_qty =  (!this.$commonFuction.isEmpty(item_order.import_qty)) ? parseFloat(item_order.import_qty):0;
        var qty = 0;
        //is_qty 1:export,2:import
        if (import_qty > 0){
          qty = import_qty;
          if (!this.$commonFuction.isEmpty(item_order.warehouseByWarehouseId)){
            this.obj_warehouse_import=item_order.warehouseByWarehouseId;
          }
        }else {
          if (this.prefix_code == this.$constants.REFIX_CODE_ORDER_CK) {
            //nếu đơn chuyển kho TH xuất sẽ gán dấu - để biết kho xuất
            qty = -export_qty;
          }else {
            qty = export_qty;
          }
          if (!this.$commonFuction.isEmpty(item_order.warehouseByWarehouseId)){
            this.obj_warehouse=item_order.warehouseByWarehouseId;
          }
        }
        item_convert["qty"] = qty;
        console.log("item_convertitem_convert:",item_convert)
        listdata_convert.push(item_convert)
      }
      this.listDataOrderDetail = listdata_convert;
      console.log("listdata_convert:",listdata_convert)
      //this.list_pakage = listData
      this.dataModelOrderDetail = DataServices.getModel('order_item_custom');
      console.log("this.dataModelOrderDetail;",this.dataModelOrderDetail)
      this.dataModelOrderDetail.processData(listdata_convert)
      this.componentKeyOrderDetail++;
    },
    async load_address_customer() {
      //this.obj_id
      if (!this.$commonFuction.isEmpty(this.obj_data.customer_id)) {
        var gqLQueryListUser = DataServices.getList('customer_delivery');
        var where_user = {
          deleted: {_eq: false},
          customer_id: {_eq: this.obj_data.customer_id},
        };
        var resData = await this.$apollo.mutate({
          mutation: gqLQueryListUser,
          variables: {
            where_key: where_user,
            /*offset:offset,
          limit:limit,*/
            orderBy: {created_at: 'desc'}
          }
        });
        var listDataUser = resData.data['customer_delivery'];
        var list_user_custom = [];
        for (var i = 0; i < listDataUser.length; i++) {
          var item_user_custom = listDataUser[i];
        //  var customer_code = (item_user_custom.customer) ? item_user_custom.customer.code : '';
          var code = (item_user_custom.code) ? item_user_custom.code : '';
          var address = (item_user_custom.address) ? item_user_custom.address : '';
          var text_lable = code + " " + address
          //var text_lable = customer_code + " - " + code + " " + address
          item_user_custom.text_lable = text_lable
          list_user_custom.push(item_user_custom)
        }
        this.list_address_customer = list_user_custom
      }
    },
     async click_more_status() {
      //alert(4)
      this.load_pag_status(this.offset_status,this.limit_status);
     },
    async load_status_order() {

      var data_delivery =await this.$CoreService.getDeliveryCosts(this.model.id);
      var  map_history_status_order_id = data_delivery?.map_history_status_order_id ?? null

      //console.log("list_deliverylist_delivery:",list_delivery)
      //this.obj_id
      var gqLQueryListOrderDocs = DataServices.getList('history_status_order');
      var where_user_docs = {
        order_id: {_eq: this.model.id},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['history_status_order'];
      var listdata_convert = []
      for (var i=0;i<listData.length;i++){
        var item_status = listData[i];
        var id_history_order= item_status?.id ?? null;
        if (!this.$commonFuction.isEmpty(listData[i].name_process_custom)){
          item_status["name"] = (!this.$commonFuction.isEmpty(listData[i].name_process_custom)) ? listData[i].name_process_custom:''
        }else{
          item_status["name"] = (!this.$commonFuction.isEmpty(listData[i].order_status)) ? listData[i].order_status.name:''
        }

        var fullname = (!this.$commonFuction.isEmpty(listData[i].obj_created_by)) ? listData[i].obj_created_by.fullname:''
        var created_at = (!this.$commonFuction.isEmpty(listData[i].created_at)) ? listData[i].created_at:''
        var text_time = moment.tz(created_at, "Asia/Ho_Chi_Minh").format("DD/MM/yyyy HH:mm");
        if ((!this.$commonFuction.isEmpty(fullname))){
          text_time +=" - "
          //text_time +=" - "+fullname
        }
        item_status["text_time"] = text_time;
        if (i==0){
          item_status["active"] = true;
        }else {
          item_status["active"] = false;
        }
        var text_reason = "";
        if (!this.$commonFuction.isEmpty(item_status["reason"])){
          text_reason = !this.$commonFuction.isEmpty(item_status["reason"]["name"]) ? item_status["reason"]["name"]:''
        }
        item_status["text_reason"] = text_reason;
        var department_name = null;
        if (item_status["obj_created_by"]){
          if (item_status.obj_created_by.department){
            department_name = item_status.obj_created_by.department.name
          }
        }
        item_status["department_name"] = department_name;
        if (!this.$commonFuction.isEmpty(map_history_status_order_id[id_history_order])){
          item_status["text_note_delivery"] = (map_history_status_order_id[id_history_order].note_delivery) ? map_history_status_order_id[id_history_order].note_delivery:'';
        }
        listdata_convert.push(item_status)
      }
      //this.list_status = listdata_convert;
      this.list_status_tmp  =listdata_convert
      this.load_pag_status(this.offset_status,this.limit_status);
      //console.log("load_status_order:",this.list_status[0].obj_created_by.department.name)
      //this.list_pakage = listData
    },
    async load_pag_status(offset,limit) {
      offset = Math.max(offset, 0);
      limit = Math.max(limit, 0);

      // Sử dụng phương thức slice để lấy phần của mảng dựa trên offset và limit
      var list_slice = this.list_status_tmp.slice(offset, offset + limit);
      console.log("list_slice",list_slice)
      console.log("offset",offset)
      console.log("limit",limit)
      this.offset_status = offset + limit
      this.list_status = this.list_status.concat(list_slice)
    },
    async load_pakage() {
      //this.obj_id
      console.log("this.obj_data.order_group_parcel_id:",this.obj_data.order_group_parcel_id)
      if (this.$commonFuction.isEmpty(this.obj_data.order_group_parcel_id)) {
        this.list_pakage = [];
        return false;
      }
      var gqLQueryListOrderDocs = DataServices.getList('order_parcel');
      var where_user_docs = {
        order_group_parcel_id: {_eq: this.obj_data.order_group_parcel_id},
        deleted: {_eq: false},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,//
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['order_parcel'];
      //console.log("this.list_pakage:",listData)
      this.list_pakage = listData
    },
    async load_list_coordinator() {
        var gqLQueryListOrderDocs = DataServices.getList('order_shipping_item_custom');
      var where_user_docs = {
        order_id:{_eq: this.model.id},
        order_shipping: {deleted: {_eq: false}}
        //order_status: {code: {_in: [this.$constants.STATUS_ORDER.DELIVERY_FAILED_1,this.$constants.STATUS_ORDER.DELIVERY_FAILED_2,this.$constants.STATUS_ORDER.DELIVERY_FAILED_3]}}
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          offset:0,
          limit:3,
          orderBy:{created_at: 'asc'}
        }
      });
      console.log("resData_docs",resData_docs)
      var listData = resData_docs.data['order_shipping_item'];
      console.log("listDatalistData:",listData)
      var list_data_convert = []
      var stt = 0;
      for (var i=0;i<listData.length;i++){
        stt++;
        var item_data = listData[i];
        console.log("item_dataitem_data:",item_data?.order_shipping?.note)
        var code_shipping = '';
        var fullname = '';
        var vehicle_name = '';
        var type_delivery_text = '';
        var freight_address = '';
        if (!this.$commonFuction.isEmpty(item_data)){
          if (!this.$commonFuction.isEmpty(item_data.order_shipping)){
            console.log("item_dataa",item_data)
            code_shipping =item_data.order_shipping.code;
            fullname =(item_data.order_shipping.user) ? item_data.order_shipping.user.fullname:'';
            vehicle_name =(item_data.order_shipping.vehicle_delivery_id) ? item_data.order_shipping.vehicle_delivery_id.name:'';
            var address_delivery =(item_data.address_delivery) ? item_data.address_delivery:'';
            /*var freight_code =(item_data.freight) ? item_data.freight.code:'';
            var freight_address_full =(item_data.freight) ? item_data.freight.address:'';
            freight_address = freight_code+" - "+freight_address_full*/
            freight_address = address_delivery
          }
          var type_delivery_check =(item_data.delivery_type) ? parseInt(item_data.delivery_type):0;
          if (type_delivery_check == 1){
            if (this.prefix_code == this.$constants.REFIX_CODE_ORDER_CK){
              type_delivery_text = "Giao hàng đến địa chỉ kho"
            }else {
              type_delivery_text = "Giao hàng đến địa chỉ khách"
            }
          }else if (type_delivery_check == 2){
            type_delivery_text = "Giao hàng đến chành"
          }else if (type_delivery_check == 3){
            type_delivery_text = "Gửi bưu điện"
          }else if (type_delivery_check == 4){
            type_delivery_text = "Tự lấy hàng"
          }
        }
        var class_group = 'left-group-input'
        if (stt %2==0){
          class_group = 'right-group-input'
        }
        var item_data_convert = {
          stt:stt,
          class_group:class_group,
          code_shipping:code_shipping,
          fullname:fullname,
          vehicle_name:vehicle_name,
          type_delivery_text:type_delivery_text,
          freight_address:freight_address,
          note:item_data?.order_shipping?.note ?? '',
        }
        list_data_convert.push(item_data_convert)
      }
      console.log("list_data_convertlist_data_convert:",list_data_convert)
      this.list_coordinator_convert = list_data_convert

    },
    async load_docs() {
      var gqLQueryListOrderDocs = DataServices.getList('order_document');
      var where_user_docs = {
        order_id:{_eq: this.model.id},
        deleted:{_eq: false},
        active:{_eq: true},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['order_document'];
      this.list_docs = this.$commonFuction.convertJsonObject(listData)
      if (this.list_docs.length<1){
        if (!this.$commonFuction.isEmpty(this.obj_data.customer_id)){
          if (this.prefix_code !=this.$constants.REFIX_CODE_ORDER_RETRUN){
            var list_status_check_docs = ["ST001","ST002","ST003","ST004","ST005","ST006","ST007","ST008","ST009"]
            if (list_status_check_docs.indexOf(this.obj_data.order_status.code) != -1) {
              this.list_docs = await this.$CoreService.getListCustomerDocs(this.obj_data.customer_id);
              console.log("this.list_docs:",this.list_docs)
            }
          }
        }
      }
      var list_docs_update = this.$commonFuction.convertJsonObject(listData)
      var list_docs_update_convert = []
      for (var i=0;i<list_docs_update.length;i++){
          var item_data = list_docs_update[i];
          item_data["number_process"] = item_data["number_request"]
          list_docs_update_convert.push(item_data)
      }
      this.list_docs_update = list_docs_update_convert

    },
    render_mark_class(item) {
      var class_name = ``
      if (item.active ==true){
        class_name +=` active_mark `
      }
     // console.log("class_name:",class_name)
      return class_name;
    },
     render_status_class(item) {
      var class_name = ``
      if (item.active ==true){
        class_name +=` active_content `
      }
     // console.log("class_name:",class_name)
      return class_name;
    },
    async listGroupRole() {
      var where = {
        active: {_eq: true},
        deleted: {_eq: false}
      };
      var order_by = {id: 'desc'};
      var listData = await  this.$CoreService.getListData('group',where,null,null,order_by);
      return listData;
    },
    async getGroupUser(uid) {
      var table_cer = 'group_user';
      var where = {uid: {_eq: uid}};
      var gqLQueryList =await DataServices.getList(table_cer);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key:where ,
        }
      });
      var listData = resData.data[table_cer];
      return listData;
    },
    /*async getAccount(uid) {
      var table_cer = 'accounts';
      var where = {uid: {_eq: uid}};
      var gqLQueryList =await DataServices.getList(table_cer);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key:where ,
        }
      });
      var listData = resData.data[table_cer];
      return listData;
    },*/
    getAddressData: function (addressData, placeResultData, id) {
      this.addressData = addressData;
      this.placeResultData = placeResultData;
      console.log(JSON.stringify(addressData));
      console.log(JSON.stringify(placeResultData));
      console.log(id);
    },
    backToList() {
      var url_list = `/admin/list-order`
      if (!this.$commonFuction.isEmpty(this.$route.query)){
        if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
          url_list +=`?page_transaction=${this.$route.query.page_transaction}`
        }
      }
      window.location.href = url_list
      //this.$emit('back');
    },
    async checkEmailAdminExist(email,model) {//check tồn tại sdt
      if (!this.$commonFuction.isEmpty(email)) {
        var where = {
          account: {'_eq': email},
          type: {'_eq': 'EMAIL'},
          deleted: {'_eq': false}
        }
        if (model !== undefined) where = Object.assign(where, {uid: {'_neq': model.id}})

        var {data} = await this.$apollo.query({
          query: DataServices.getList('accounts', {"fields" : "id, account, type"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        });

        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      }
    },
    async validateData() {
      var group_id_va = document.getElementById("group_id_va");
      if (this.$commonFuction.isEmpty(this.user.group_id)) {
        group_id_va.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        group_id_va.setCustomValidity("");
      }

      /*var text_validator_email = "";
      if (!this.$commonFuction.isEmpty(this.user.email)) {
        console.log("this.model:",this.model);
        this.account_exist = await this.checkEmailAdminExist(this.user.email.trim(), this.model);
        if (this.account_exist) {
          text_validator_email = this.$constants.MSG_TEXT.EMAIL_EXIST_SYSTEM_MSG;
        }
      }

      var email = document.getElementById("email");
      if (!this.$commonFuction.isEmpty(text_validator_email)) {
        email.setCustomValidity(text_validator_email);
      } else {
        email.setCustomValidity("");
      }
      var password_confirm_text_va = "";
      if (!this.$commonFuction.isEmpty(this.user.password) && !this.$commonFuction.isEmpty(this.user.password_confirm)) {
        if (this.user.password !== this.user.password_confirm) {
          password_confirm_text_va = this.$constants.MSG_TEXT.PASSWORD_NOT_MATCH_MSG;
          //password_confirm.setCustomValidity(this.$constants.MSG_TEXT.PASSWORD_NOT_MATCH_MSG);
        }
      }
      var password_text_va = "";
      if (this.formMode !== 'add') {
        if (this.user.email != this.user.email_old){
          //trường hợp khác thì bắt nhập lại mật khẩu
          if (this.$commonFuction.isEmpty(this.user.password)){
            password_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG;
          }
          if (this.$commonFuction.isEmpty(this.user.password_confirm)){
            password_confirm_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG
          }
        }else {
          //
          if (!this.$commonFuction.isEmpty(this.user.password)){
            //trường hợp thay đổi thì bắt nhập passcomfirt
            if (this.$commonFuction.isEmpty(this.user.password_confirm)){
              password_confirm_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG
            }
          }
          if (!this.$commonFuction.isEmpty(this.user.password_confirm)){
            //trường hợp thay đổi thì bắt nhập password
            if (this.$commonFuction.isEmpty(this.user.password)){
              password_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG;
            }
          }
        }
      }
      var password_confirm = document.getElementById("password_confirm");
      if (!this.$commonFuction.isEmpty(password_confirm_text_va)){
        password_confirm.setCustomValidity(password_confirm_text_va);
      }else {
        password_confirm.setCustomValidity("");
      }

      var password = document.getElementById("password");
      if (!this.$commonFuction.isEmpty(password_text_va)){
        password.setCustomValidity(password_text_va);
      } else {
        password.setCustomValidity("");
      }*/

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    convertPhone(numberPhone) {
      var phone = numberPhone
      if (numberPhone[0] === '0') {
        phone = phone.substring(1);
      }
      phone = phone.replace(' ', '')
      phone = '+84' + phone
      return phone
    },
    async getUserByPhoneNumber(phoneNumber) {
      // var phone = phoneNumber;
      // if (phoneNumber[0] === "0") {
      //   phone = phone.substring(1);
      // }
      // phone = phone.replace(' ', '');
      // phone = "+84" + phone;
      let query = `mutation MyMutation {
        checkPhoneNumber(phone: "${this.convertPhone(phoneNumber)}") {
          info
        }
      }`;
      await this.$apollo.mutate({
        mutation: gql(query)
      }).then(async(res) => {
        // console.log('=========> checkPhoneNumber: ', res)
        if (res) {
          this.firebaseID = res.data.checkPhoneNumber.info.uid
          await this.$apollo.query({
            query: DataServices.getList('users', {"fields" : "id"}),
            variables: {
              where_key: {
                phone: {'_eq': this.phone},
                deleted: {'_eq': false}
              }
            },
            fetchPolicy: "network-only"
          }).then((res) => {
            if (res && res.data.users.length > 0) this.userID = res.data.users[0].id
            else this.userID = null
            // console.log(this.userID)
          }).catch((error) => {
            console.log('ERROR: ', error.message)
          })
        }
      }).catch((error) => {
        console.log('ERROR: ', error.message)
        // CHƯA CÓ FIREBASE ID
        this.firebaseID = null
        this.userID = null
      })
    },
    async checkPhoneExist() {
      if (this.phone !== undefined && this.phone !== "") {
        // var phone = this.phone;
        // if (this.phone[0] === "0") {
        //   phone = phone.substring(1);
        // }
        // phone = phone.replace(' ', '');
        // phone = "+84" + phone;

        var where = {
          account: {'_eq': this.phone},
          type: {'_eq': 'PHONE'},
          deleted: {'_eq': false}
        }
        if (this.model !== undefined) where = Object.assign(where, {id: {'_neq': this.model.id}})
        var {data} = await this.$apollo.query({
          query: DataServices.getList('accounts', {"fields" : "id, account, type"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        })
        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      }
    },
    async saveData() {

      /* if(this.saving == true){
         return;
       }
       this.saving = true;*/
       //this.$commonFuction.is_loading(true);
      /*if (await this.validateData()) {
        // this.saving = false;
        this.$commonFuction.is_loading(false);
        return
      }*/


    /*  if (!this.account_exist) {
        var params = {
          user: this.user,
          images: this.images,
          imagesDeletes: this.imagesDeletes,
          // firebaseID: this.firebaseID,
          // userID: this.userID,
          // addressData:this.addressData,
          // placeResultData:this.placeResultData
          /!*listCategory : this.selectedCategory,
          addCategory : this.addCategory,
          delCategory : this.delCategory,*!/
        }
        console.log('saveData', th)*/

        var data_request =  this.obj_data;
        data_request["list_order_item"] = this.listDataOrderDetail
        this.$emit('save', data_request);
    //  }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if(files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF']
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Please upload the image file in the following format: jpeg, jpg, gif, png");
            return;
          }
        }
        if (files.length + this.images.length > this.maxFiles) {
          alert("Hình ảnh quá số lượng tối đa là " + this.maxFiles + " hình");
        } else {
          var arrTemp = [], arrFiles = [];
          for (let file of files) {
            if (this.isImage(file) && file.size <= this.maxFileSize) {
              file.source = file;
              file.objectURL = window.URL.createObjectURL(file);
              arrTemp.push(file);
            }
            if (file.size > this.maxFileSize) arrFiles.push(file);
          }

          if (arrFiles.length > 0) {
            alert("Hình ảnh của bạn đã quá dung lượng 1MB");
          } else {
            if (this.images.length > 0 && this.images[0].file === '') {
              this.imagesDeletes.push(this.images[0])
            }
            this.images = [];
            this.images = this.images.concat.apply(this.images, arrTemp);
            // console.log(this.images)
            await this.validateData()
          }
        }
      }
    },
    onFileClear(index) {
      let img = this.images[index];
      this.images.splice(index, 1);
      if (img.file === '') this.imagesDeletes.push(img)
    }
  },
  /*apollo: {
    category: {
      query() {
        return DataServices.getList('category', {"fields" : "id name"});
      },
      update(data) {
        if(data.category){
          this.categories = data.category
        }
      },
      fetchPolicy: "network-only"
    },
  }*/
}
</script>

<style lang="scss">
.parent_page_detail {
  .style_total_price{
    margin-top:16px;
    text-align: right;
    font: normal normal bold 18px/24px Segoe UI;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
  .p-multiselect {
    width: 450px;
  }

  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;

  }

  .category-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .2rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }

  .p-multiselect-label-container {
    height: 40px;
  }

  .group-button-detail-top {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 16px;
    padding-right: 0px;

    .style-button-common {
      margin-left: 8px;
      margin-right: 8px;
      width: fit-content;
    }

    .style-button-common:last-of-type {
      margin-right: 0px;
    }
  }

  .style-block-panel {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;

    .block-input {
      .style-col-input {
        width: 50%;

        .p-col {
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      .left-group-input {
        padding-left: 0px;
        padding-right: 16px;
        padding-top: 0px;
        float: left;
        /* padding-bottom: 0px;*/
      }

      .right-group-input {
        padding-right: 0px;
        padding-left: 16px;
        padding-top: 0px;
        float: right;
        /*padding-bottom: 0px;*/
      }

      label {
        color: #333333;
        font: normal normal bold 16px/21px Segoe UI;
        padding-bottom: 0px;
        margin-bottom: 0px;
        padding-left: 0px;
      }

      input {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #D0D4D9;
        border-radius: 5px;
      }

      textarea {
        height: 72px;
      }
    }
  }

  .group-panel-detail {
    padding-bottom: 16px;

    .p-panel-header {
      background: #FAFAFA 0% 0% no-repeat padding-box !important;
      border-bottom: unset;
    }

    .p-panel-content {
      padding-top: 0px;
      background: #FAFAFA 0% 0% no-repeat padding-box !important;
    }

    .p-timeline {
      margin-left: 8px;
    }

    .p-timeline.p-timeline-vertical .p-timeline-event-opposite {
      display: none;
    }

    .custom-marker {
      border: 2px solid #C40380;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      background-color: #ffffff;
    }

    .p-timeline-event-separator {
      margin-top: 2px;
    }

    .p-timeline-event-content {
      margin-top: -4px;

      .name-status {
        font: normal normal bold 16px/21px Segoe UI;
        letter-spacing: 0px;
        color: #666666;
      }

      .date-status {
        font: normal normal normal 16px/21px Segoe UI;
        letter-spacing: 0px;
        color: #666666;
      }

      .active_content {
        .name-status {
          font: normal normal bold 16px/21px Segoe UI;
          letter-spacing: 0px;
          color: #C40380;
        }
      }
    }

    .custom-marker:last-of-type {
      /*display: none;*/
    }
  }

  .box-status {
    width: 100%;
    .max_height_box{
      height: 85px !important;
    }
    .item-status {
      height: 62px;
      display: block;
      position: relative;
      margin-bottom: 4px;
      .icon-timeline {
        background-image: url("/assets/images/icon/ic_tientrinh.svg");
        width: 24px;
        height: 62px;
      }

      .box-content-status {
        position: absolute;
        top: 0px;
        left: 40px;

        .name-status {
          font: normal normal bold 16px/21px Segoe UI;
          letter-spacing: 0px;
          color: #666666;
        }

        .date-status {
          font: normal normal normal 16px/21px Segoe UI;
          letter-spacing: 0px;
          color: #666666;
        }
        .reason-text{
          font: italic normal 16px/21px Segoe UI;
          .reason-text-bold{
            color: #666666;
            font: italic normal bold 16px/21px Segoe UI;
          }
        }
      }
    }

    .active_status {
      .icon-timeline {
        background-image: url("/assets/images/icon/ic_tientrinh_active.svg");
      }

      .box-content-status {
        .name-status {
          color: #C40380;
        }
      }
    }
  }

  .clear-div-custom {
    width: 100%;
  }

  .group-input-panel {
    .title_check_box {
      margin-left: 16px;
      font: normal normal normal 16px/21px Segoe UI;
      letter-spacing: 0px;
      color: #1D3118;
      font-weight: unset !important;
    }
  }

  .title-group-doc {
    margin-top: -44px;
  }

  .item_docs {
    margin-bottom: 10px;
  }

  .required_documents {
    text-align: left;
    letter-spacing: 0px;
    color: #333333;
    position: relative;

    .total_doc {
      font: normal normal bold 16px/21px Segoe UI;
      width: 45px;
      display: unset;
      position: absolute;
    }

    .content_doc {
      font: normal normal normal 16px/21px Segoe UI;
      display: unset;
      position: absolute;
      left: 45px;
    }
  }

  .style-popup-change-product,
  .style-popup-confirm-payment,
  .style-popup-cancel-order,
  .style-popup-update-address-coordinator,
  .style-popup-update-pakage,
  .style-popup-confirm-profile,
  .style-popup-confirm-close-profile
  {
    width: 600px;
  }
  .style-popup-update-pakage{
    .item-right-popup{
      width: 212px !important;
    }
    .item-left-popup{
      width:calc(100% - 212px) !important;
    }
    .style_group_pakage{
      position: relative;
      .popup_qty_pakage{
        width: 166px;
      }
      .style_icon_del_pakage{
        cursor: pointer;
        position: absolute;
        width: 38px;
        height: 32px;
        right: 0px;
        margin-top: auto;
        margin-bottom: auto;
        top: 0px;
        bottom: 0px;
      }
    }
  }
  .number_profile{
    width: 80px;
  }
  .group_text_profile{
    display: inline;
    .text_profile_request{
      font: normal normal bold 16px/21px Segoe UI;
      letter-spacing: 0px;
      color: #333333;
      margin-left: 2px;
    }
    .name_profile{
      font: normal normal normal 16px/21px Segoe UI;
      letter-spacing: 0px;
      color: #333333;
      margin-left: 15px;
    }
  }
}
@media (max-width: 960px){
  .style-block-panel {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;

    .block-input {
      .style-col-input {
        width: 100% !important;

        .p-col {
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      .left-group-input {
        padding-right: 0px !important;
        /* padding-bottom: 0px;*/
      }

      .right-group-input {
        padding-left: 0px !important;
        /*padding-bottom: 0px;*/
      }

      .left-group-input.responsive-padding{
        padding-right: 4px !important;
      }
      .right-group-input.responsive-padding{
        padding-left: 4px !important;
      }

      label {
        color: #333333;
        font: normal normal bold 16px/21px Segoe UI;
        padding-bottom: 0px;
        margin-bottom: 0px;
        padding-left: 0px;
      }

      input {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #D0D4D9;
        border-radius: 5px;
      }

      textarea {
        height: 72px;
      }
    }
  }
  .title-group-doc {
    margin-top: 0px !important;
  }
}
.style-popup-cancel-order{
  .p-dropdown-panel{
    left: 24px !important;
    top: 0px!important;
    width: 200px;
  }
}
/*
#cancel_order_id{
  .reason_cancel_list{
    width: 100%;
  }
}*/
.width_size{
  width: 50%!important;
  padding: 15px 15px 0px 0px!important;
}
#size{
  border-radius: unset!important;
}
.dif{
  padding-bottom: 10px!important;
}
.style-package-code{
  color: #C40380;
  font-weight: bold;
}

.see-more{
  text-decoration: underline;
  cursor: pointer;
  color: #C40380;
  margin-left: 32px;
}
/*.group-panel-detail-style{
  input {
    background: #EEEEEE 0% 0% no-repeat padding-box;
    border: 1px solid #D0D4D9;
    pointer-events:none;
  }
}*/
#custom-popup-common-address{
  .p-dialog-content {
    overflow-y: unset !important;
}
.p-dropdown-panel{
width: 600px !important;
/*left: 0px !important;*/
  }
}
.p-dropdown-items-wrapper{
  max-width: unset!important;
}
.txt-style-text-date div {
  width: 140px;
}

.txt-200-medium-col {
  max-width: 200px !important;
  min-width: 200px !important;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.class_tmp_popup{
width:100px;height: 300px;
}

.p-dropdown-items-wrapper{
  // max-width: 300px!important;
   max-height: 350px!important;
}
</style>