import BaseModel from '../core/BaseModel';
// import StateModel from "./StateModel";
// import DistrictModel from "./DistrictModel";
// import WardModel from "./WardModel";
// import CountryModel from "./CountryModel";
import TypeWareHouseModel from "./TypeWareHouseModel";
// import SelectWareHouseModel from "./SelectWareHouseModel";
// import Common from "../core/Common";
export default class WarehouseModel extends BaseModel {
    constructor(key_display = 'name') {
        super({
                name: "warehouse",
                tableName: 'warehouse',
                label: "Kho hàng",
                display_key: key_display,
            },
            {
                stt: {
                    name: "stt",
                    label: "STT",
                    type: "Computed",
                    class:" width_90 common",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
                id: {
                    name: 'id',
                    type: "Uuid",
                    label: 'ID',
                    primaryKey: true,
                    display: false
                },
                code: {
                    name:'code',
                    label:'Mã kho',
                    type: "String",
                    // custom: 'SelectionText',
                    // models: new SelectWareHouseModel(),
                    filter: {type: 'input', match: 'contains'},
                    class:" width_90 common",
                },
                // code: Common.makeFilterCompany('code', 'Mã kho', new SelectWareHouseModel(), "warehouse", "width_90 common", false),
                name : {
                    name: 'name',
                    type: "String",
                    label: 'Tên kho',
                    sortable: false,
                    filter: {type: 'input', match: 'contains'},
                    class:" width_180 common",

                },
                type_warehouse: {
                    refs:  ' type_warehouse {  name id }',
                    display: false
                },
                type_id: {
                    name:'type_id',
                    label:' Phân loại',
                    type: "Selection",
                    custom: 'SelectionText',
                    models: new TypeWareHouseModel(),
                    filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                    class:" width_90 common",
                    computed: (data) => {
                        data.type_id = '';
                    },
                },
                // type_id: Common.makeFilterCompany('type_id', 'Phân loại', new TypeWareHouseModel(), "warehouse", "width_90 common"),
                country: {
                    refs:  ' country {  name id }',
                    display: false
                },
                // country_id: {
                //     name:'country_id',
                //     label:'Quốc gia',
                //     type: "Selection",
                //     custom: 'SelectionText',
                //     models: new CountryModel(),
                //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                //     class:" width_90 common",
                //     computed: (data) => {
                //         data.country_id = '';
                //     },
                // },
                country_id : {
                    name: 'country_id',
                    type: "Computed",
                    custom: 'SelectionText',
                    label: 'Quốc gia',
                    sortable: false,
                    filter: {type: 'input', match: 'contains'},
                    class:"width_90 common",

                },



                state: {
                    refs:  ' state {  name id }',
                    display: false
                },
                // state_id: {
                //     name:'state_id',
                //     label:'Tỉnh/ Thành phố',
                //     type: "Selection",
                //     custom: 'SelectionText',
                //     models: new StateModel(),
                //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                //     class:" width_90 common",
                //     computed: (data) => {
                //         data.state_id = '';
                //     },
                // },

                state_id : {
                    name: 'state_id',
                    type: "Computed",
                    custom: 'SelectionText',
                    label: 'Tỉnh/ Thành phố',
                    sortable: false,
                    filter: {type: 'input', match: 'contains'},
                    class:"width_90 common",
                },
                district: {
                    refs:  ' district {  name id }',
                    display: false
                },
                // district_id: {
                //     name:'district_id',
                //     label:'Quận/ Huyện',
                //     type: "Selection",
                //     custom: 'SelectionText',
                //     models: new DistrictModel(),
                //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                //     class:"width_90 common",
                //     computed: (data) => {
                //         data.district_id = '';
                //     },
                // },
                district_id : {
                    name: 'district_id',
                    type: "Computed",
                    custom: 'SelectionText',
                    label: 'Quận/ Huyện',
                    sortable: false,
                    filter: {type: 'input', match: 'contains'},
                    class:"width_90 common",

                },
                ward: {
                    refs:  ' ward {  name id }',
                    display: false
                },
                // ward_id: {
                //     name:'ward_id',
                //     label:'Phường/ Xã',
                //     type: "Selection",
                //     custom: 'SelectionText',
                //     models: new WardModel(),
                //     filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                //     class:"width_90 common",
                //     computed: (data) => {
                //         data.ward_id = '';
                //     },
                // },
                ward_id : {
                    name: 'ward_id',
                    type: "Computed",
                    custom: 'SelectionText',
                    label: 'Phường/ Xã',
                    sortable: false,
                    filter: {type: 'input', match: 'contains'},
                    class:"width_90 common",

                },
                address : {
                    name: 'address',
                    type: "Text",
                    label: 'Số nhà, tên đường',
                    filter: {type: 'input', match: 'contains'},
                    class:"width_180 common",
                },
                protect : {
                    name: 'protect',
                    type: "Text",
                    label: 'Số nhà, tên đường',
                    display: false
                },


                active: {
                    name: "status",
                    label: "Trạng thái",
                    type: "Selection",
                    custom: 'SelectionText',
                    options: [
                        {value: 'true', label: 'Hoạt động', style: 'color:  #000000;'},
                        {value: 'false', label: 'Dừng hoạt động',style: 'color:  #000000;'}
                    ],
                    filter: {
                        type: 'select',
                        optionLabel: 'label',
                        optionKey: 'value',
                        match: 'equals'
                    },
                    class:"center width_90 common",
                },
                updated_by_id: {
                    refs:"updated_by_id { fullname}",
                    type: "Selection",
                    label: "Người cập nhật",
                    custom: 'SelectionText',
                    class:"center width_90 common",
                },
                deleted: {
                    name: "deleted",
                    label: "Hiện thị",
                    type: "Checkbox",

                    display: false
                },
                updated_at : {
                    name: 'updated_at',
                    type: "Datetime",
                    label: 'Ngày cập nhật',
                    class:"center width_90 common",
                    // filter: { type: 'DateRange', match: 'contains'},
                },
                created_at : {
                    name: 'created_at',
                    type: "Datetime",
                    label: 'Ngày tạo',
                    filter: { type: 'DateRange', match: 'contains'},
                    display : false
                },
                key_get: {
                    refs: 'street',
                    display: false
                },
            },)
    }

}
