<template>
  <span>
  <!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
    <!-- @row-click="selectedproduct" :row-hover="true" -->
  <DataTable
    :value="listDataService"
    :lazy="true"
    rowStyle="cursor: pointer"
    class="p-datatable-responsive mt-lg-3 mt-4"
    selectionMode="single"
    rowGroupMode="subheader"
    data-key="id"
    :rows="paginationRows"
    :loading="loading"
    :first="firstRecordIndex"
    :autoLayout="true"
    filterMatchMode="startsWith"
    :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
    :currentPageReportTemplate=" $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM+' {totalRecords}'"
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >
    <Column
      v-for="c in model.getDisplayFields(0)"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="(c.class ? 'txt-' + c.class : '')"
      :bodyStyle="getBodyStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="(c.class ? 'txt-' + c.class : '')"
    >
      <template #body="slotProps">
        <span class="responsive-label">{{ getLabel(c) }}</span>
        <!--{{c}}-->
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'Link'"
          v-html="callbackLink(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'CustomUrl' && view_club ==true"
          v-html="callbackCustomUrl(c, slotProps.data)"
          @click="redirectListClub(slotProps.data, $event)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'CustomUrl'"
          v-html="callbackCustomUrl(c, slotProps.data)"
          @click="redirectListClub(slotProps.data, $event)"
          :style="(c.style ? c.style : '')"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>
          <div v-if="c.custom">
            <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge"  />
            <div v-if="c.custom == 'SelectionText'"  v-html="callbackDataSelect( c ,slotProps.data)"></div>
          </div>
          <span v-else v-html="getData(c, slotProps.data)" :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"></span>
        </div>


      </template>
      <template v-if="c.filter" #filter>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :placeholder="getFilterPlaceHolder(c)"
          @input="onFilter($event,c.filter.type,c.key)"
        />
        <InputNumber
          v-if="c.filter.type == 'number'"
          v-model="filters[getField(c)]"
          class="p-column-filter"
          locale="de-DE"
          :placeholder="getFilterPlaceHolder(c)"
          @input="onFilter($event,c.filter.type,c.key)"
        />
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key, 0)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"

          @change="onFilter($event,c.filter.type,c.key)"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)"
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
        <Calendar
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false"
          :monthNavigator="true"
          :yearNavigator="true"
          :yearRange="yearRange"
          dateFormat="yy-mm-dd"
          :placeholder="getFilterPlaceHolder(c)"
          v-model="filters[getField(c)]"
          class="p-column-filter calendar-light-btn"
          :showIcon="true"
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          :showButtonBar="true"
          @clear-click="onFilter($event,c.filter.type,c.key)"
          @date-select="onFilter($event,c.filter.type,c.key)"
          :manualInput="false"
          style="width: 200px;"
          />

          <Calendar
          v-if="c.filter.type == 'Date'"
          :showOnFocus="false"
          :monthNavigator="true"
          :yearNavigator="true"
          :yearRange="yearDOB"
          dateFormat="yy-mm-dd"
          :placeholder="getFilterPlaceHolder(c)"
          v-model="filters[getField(c)]"
          class="p-column-filter calendar-light-btn"
          :showIcon="true"
          selectionMode="single"
          appendTo="body"
          :showClear="true"
          :maxDate="new Date()"
          :showButtonBar="true"
          @clear-click="onFilter($event,c.filter.type,c.key)"
          @date-select="onFilter($event,c.filter.type,c.key)"
          :manualInput="false"
          style="width: 200px;"
          />
      </template>
    </Column>
    <Column
      v-if="actions"
      headerStyle="width: 10em; text-align: center"
      bodyStyle="width: 10em; text-align: center;  justify-content: center; padding: 1em 0;"
    >
      <template #body="slotProps">
        <span class="p-column-title">Actions</span>
        {{ slotProps.data.color }}
        <Button
          v-for="(a, k) in actions.rows(slotProps.data)"
          :key="k"
          type="button"
          :icon="getIcon(a.icon)"
          :class="getClass(a.name)"
          :disabled="getDisabled(a.disabled)"
          style="margin: 2px"
          v-on:click="actionsClicked(k, a, slotProps)"
          :label="a.label"
        ></Button>
      </template>
      <template #header="slotProps">
        <Button
          v-if="actions.header"
          type="button"
          :icon="getIcon(actions.header.icon)"
          :class="getClass(actions.header.name)"
          v-on:click="actionsClicked(actions.header.name, actions.header, slotProps)"
        ></Button>
      </template>
    </Column>
    <template #empty>
      <div class="text-center">{{ $t('datatable.empty') }}</div>
    </template>
    <template #loading>
      <div class="text-center">{{ $t('datatable.loading') }}</div>
    </template>
    <template #groupfooter  class="p-d-lg-block p-d-none" >
        <td colspan="2">
          <div class="bold" style="text-align: left; width: 100%">Tổng</div>
        </td>
        <td>
          <div class="bold" style="text-align: right; width: 100%">{{ calculateQtyTotal('status_done') }}</div>
        </td>
        <td>
          <div class="bold" style="text-align: right; width: 100%">{{ calculateQtyTotal('waiting_delivery') }}</div>
        </td>
        <td>
          <div class="bold" style="text-align: right; width: 100%">{{ calculateQtyTotal('preparing_goods') }}</div>
        </td>
        <td>
          <div class="bold" style="text-align: right; width: 100%">{{ calculateQtyTotal('waiting_payment') }}</div>
        </td>
        <td>
          <div class="bold" style="text-align: right; width: 100%">{{ calculateQtyTotal('waiting_export') }}</div>
        </td>
        <td>
          <div class="bold" style="text-align: right; width: 100%">{{ calculateQtyTotal('total') }}</div>
        </td>
    </template>
  </DataTable>
</span>
</template>

<script>
import moment from "moment-timezone";
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
import ApiRepository from "@/core/ApiRepository";
export default {
  name: "ListChannel",
  props: {
    model: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
    filterDefault: Object,
    exportExcel: Boolean,
    loading: Boolean,
    listDataService: Array,
    totalRecords: Number
    // view_club:Boolean ,
  },
  data() {
    return {
      filters: {},
      countDropdown: 0,
      pagination: true, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '', // chiều cao của trang danh sách
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      // totalRecords: 120,
      totalRecords2: 12,
      test: null,
      // listDataService:null,
      filtersCustom: [],
      lazyParams: {},
      page_transaction:null,
      firstRecordIndex:0,
      yearRange : (new Date().getFullYear() - 100)+":"+(new Date().getFullYear() + 100),
      yearDOB : (new Date().getFullYear() - 100)+":"+new Date().getFullYear(),
      time_zone_local : moment.tz.guess(true),
      view_club : false,
      status_list: [{value:1,label:"Đã đặt"},{value:2,label:"Đã xác nhận"},{value:3, label:"Hủy hẹn"},{value:4,label:"Hoàn thành"}],
      typeOrderList: [{value:1,label:"Đơn nhập"},{value:2,label:"Đơn xuất"},{value:3, label:"Chuyển kho"}],
    };
    
  },
  async created() {
    var year = new Date().getFullYear()
    var yearRange = (year-10)+':'+(year+90);
    var yearDOB = (year-100)+':'+year;
    this.yearRange = yearRange+'';
    this.yearDOB = yearDOB+'';
    this.test = this.tableData;
    var arr_role_clubs = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_CLUBS);
    // console.log('role_clubrole_club',arr_role_clubs)
    if (arr_role_clubs.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.view_club = false;
    }else{
      this.view_club = true;
    }
  },
  watch: {
    async '$props.filterDefault.company_id'() {
      this.lazyParams.filters = await Object.assign({...this.filters, ...this.filterDefault});
      //this.$emit('loading', true);
      await this.onLazyEvent();
    },
    async '$props.filterDefault.date_order_filter'() {
      this.lazyParams.filters = await Object.assign({...this.filters, ...this.filterDefault});
      // console.log(this.lazyParams.filters);
      //this.$emit('loading', true);
      await this.onLazyEvent();
    },
    async '$props.filterDefault.channel_id'() {
      this.lazyParams.filters = await Object.assign({...this.filters, ...this.filterDefault});
      // console.log(this.lazyParams.filters);
      //this.$emit('loading', true);
      await this.onLazyEvent();
    },
    async '$props.exportExcel'() {
      if (this.exportExcel) {
        await this.exportExcelFunc();
      }
    }
  },
  async mounted () {
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    };
    /*
    * stateStorage="session"
    stateKey="dt-state-demo-session"
    * phai them thuoc tinh nay vao table
    * */
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
        this.firstRecordIndex = this.lazyParams.first;
        // console.log("this.lazyParams:22222222",this.lazyParams);
        /*if (this.lazyParams){
          this.filters = (this.lazyParams.filters) ? this.lazyParams.filters : {};
        }*/
      }
    }
    // this.loading = false
    this.countDropdown++;
    this.lazyParams.filters = await Object.assign({...this.filters, ...this.filterDefault});
    // console.log("this.lazyParamsthis.lazyParams", this.lazyParams);
    // await this.onLazyEvent();
  },
  methods: {
    redirectListClub(data, e) {
      let clickedElId = e.target.id
      // console.log(data.id)
      if (clickedElId === 'list-users-clubs') {
        var id = data.id;
        var path = `/admin/list-users_clubs/${id}`;
        this.$router.push({path: path})
      }
    },
    callbackCustomUrl(c,data){
      var text = ''
      switch(c.key) {
        case 'club_html':
          if(data.view_users_clubs_expiration_aggregate && data.view_users_clubs_expiration_aggregate.aggregate){
            var count_club = data.view_users_clubs_expiration_aggregate.aggregate.count
            if(this.view_club == true){
              text = '<a class="txt-link" id="list-users-clubs" :data-id="'+data.id+'">'+count_club+'</a>';
            }else{
              text = count_club; 
            }
          }else{
            text = 0;
          }
          break;
        default:
          break;
      }
      // this.date_now
      return text;
    },
    select_date(event,col_name) {
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if(col.inputOptions?.mode == 'icon') {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange)  f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {

      return col.key;
    },
    getHeaderStyle(col) {
      var style = "text-align:center;";
      if (col.width) style += "width: " + col.width + "px;";
      else if (col.type === "image" || col.type === "Image") style += "width: 6em;";
      if (col.name === "stt" || col.name === "STT") style += "padding: 0;";
      return style;
    },
    getBodyStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "px;";
      else if (col.name === "stt" || col.name === "STT") style = "text-align:center; width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      // console.log("this.options:",this.options)
      var value = '';
      if (col.type == "Date" && row[col.key]) {
        // value = moment.tz(row[col.key], this.time_zone_local).format("LL");
        value = moment.tz(row[col.key], this.time_zone_local).format("DD/MM/yyyy");
      } else if (col.type == "Datetime" && row[col.key]) {
        // value = moment.tz(row[col.key], this.time_zone_local).format("ll HH:mm");
        value = moment.tz(row[col.key], this.time_zone_local).format("HH:mm - DD/MM/yyyy");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment.tz(row[col.key], this.time_zone_local).format("HH:mm - DD/MM/yyyy");
      } else if(col.type == "Hour" && row[col.key]){
        value = moment.tz(row[col.key], this.time_zone_local).format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          // console.log("col.models:",col.models);
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
        var filtered = col.options.filter( a => a.value  === row[col.key]);
        if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
          value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
        }
      }
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      if (col.filter.placeHolder) {
        return col.filter.placeHolder;
      } else if (col.filter.type == "select") {
        return `Chọn`;
      } else if (col.filter.type == "input" || col.filter.type == "number") {
        return `Nhập`;
      } else if (col.filter.type == "DateRange") {
        return `Chọn ngày`;
      } else if (col.filter.type == "Date") {
        return `Chọn ngày`;
      } else {
        return ``;
      }
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    selectedBooking(event) {
      const selectedItem = event;
      this.$emit("action-click", {
        originalEvent: event,
        action: { name: 'view', icon: '', key: 'id', path: `list-product/view/`},
        key: (selectedItem.data.stt-1),
        data: selectedItem,
        lazyParams:this.lazyParams
      });
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams:this.lazyParams
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
        case "reexamine":
          return "p-button-primary";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
    callbackLink(c, data) {
      var text = "";
       switch(c.key) {
          case 'link_image':
            text = data.link_image ? `<a target="_blank" href='${data.link_image}' >Mở link</a>` : '-';
            break;
          case 'link':
            text = data.link ? `<a target="_blank" href='${data.link}' >Mở link</a>` : '-';
            break;
          default:
       }
       return text;
    },
    callbackDataSelect(c,data){
        var text = "";
        switch(c.key) {
            case 'gender':
                if(data.active){
                    text = "Đang kinh doanh";
                }else {
                  text = "Ngừng kinh doanh"
                }
                break;
            case 'company_code':
                text = data.product?.company?.code || "-";
              break;
            case 'unit_name':
                text = data.product?.unit?.name || "-";
              break;
            case 'material_code':
              text = data.material?.code || "-";
              break;
            case 'material_name':
              text = data.material?.name || "-";
              break;
            case 'warehouse_code':
              text = data.warehouse?.code || "-";
              break;
            case 'warehouse_name':
              text = data.warehouse?.name || "-";
              break;
            case 'type_order':
              text = data.type_order;
              break;
            case 'suggest_delivery_type':
              text = data.suggest_delivery_type || '-';
              break;
            /*case 'type_order_status':
              var list_class = [
                {value: ['ST013','ST014','ST015'], label: 'Đã giao thành công', style: 'color:  #000000;'},
                {value: ['ST008','ST009','ST010','ST011'], label: 'Đang giao hàng', style: 'color:  #000000;'},
                {value: ['ST004'], label: 'Đang soạn hàng', style: 'color:  #000000;'},
                //{value: ['ST013','ST014','ST015'], label: 'Chờ thanh toán', style: 'color:  #000000;'},
                {value: ['ST005','ST006','ST007'], label: 'Chờ xuất', style: 'color:  #000000;'},
              ]
              for(var k=0;k<list_class.length;k++){
                if (list_class[k].value.indexOf(data.type_order_status) != -1){
                  text = list_class[k]["label"];
                }
              }
              if (this.$commonFuction.isEmpty(text)){
                text = '-';
              }

              break;*/
            case 'order_status':
              console.log(data.order_status);
              text = data.order_status || '-';
              break;
            case 'delivery_status':
              text = data.delivery_status;
              break;
            case 'active':
              text = data.active ? "Đang kinh doanh" : "Ngừng kinh doanh";
              break;
            case 'warning_expiry':
              var diffMonth = this.inMonths(data.expiry_date);
              if (diffMonth < 1) {
                text = '<div class="lemay-tag lemay-tag-danger"><1</div>';
              } else if (diffMonth >= 1 && diffMonth < 3) {
                text = '<div class="lemay-tag lemay-tag-warning">1-3</div>';
              } else if (diffMonth >= 3 && diffMonth < 6) {
                text = '<div class="lemay-tag lemay-tag-info">3-6</div>';
              } else if (diffMonth >= 6 && diffMonth <= 12) {
                text = '<div class="lemay-tag lemay-tag-main">6-12</div>';
              } else if (diffMonth > 12) {
                text = '<div class="lemay-tag lemay-tag-success">>12</div>';
              }
              break;
            case 'status':
              // console.log(data);
              for (let k=0; k < this.status_list.length; k++) {
                if (this.status_list[k].value == data.status) {
                  text = this.status_list[k].label;
                  break;
                } else {
                  text = '-';
                }
              }
              break;
            default:
        }
        return text;
    },
    inMonths(date) {
        var now = moment(new Date()); //todays date
        var end = moment(date); // another date
        var duration = moment.duration(end.diff(now, 'month'));
        // console.log(duration);
        return duration;
    },
    getDateSelectMonth(value) {
      var now = moment(new Date()); //todays date
      var date = moment(now).subtract(value, 'months');
      return date;
    },
    async dataList(lazyParams) {
    // console.log('lazyParams');
    // console.log('lazyParams:',lazyParams);
     var offset = lazyParams.first;
     var limit = lazyParams.rows;
     var gqLQueryList = DataServices.getList(this.model.tableName);
     var where = {
       deleted: {_eq: false},
       order: {}
     }
     var filters = lazyParams.filters;
     filters = {...filters, ...this.filterDefault};
     if (filters){
       for (const [key, value] of Object.entries(filters)) {
        //  console.log("dsadsd",value.type_input);
         if (value.type_input == 'input'){
           if (!this.$commonFuction.isEmpty(value.value)){
             if (key == 'doc_code'){
                where.order['doc_code'] = {_iregex: this.$commonFuction.search(value.value)};
             } else if (key == 'unit_name'){
               where.unit = {name: {_iregex: this.$commonFuction.search(value.value)} };
             } else if (key == 'warehouse_export_name'){
              where.warehouseByWarehouseId = { name: {_iregex: this.$commonFuction.search(value.value)} };
             } else if (key == 'warehouse_export_code'){
              where.warehouseByWarehouseId = { code: {_iregex: this.$commonFuction.search(value.value)} };
             } else if (key == 'warehouse_import_name'){
               where.warehouse = { name: {_iregex: this.$commonFuction.search(value.value)} };
             } else if (key == 'warehouse_import_code'){
               where.warehouse = { code: {_iregex: this.$commonFuction.search(value.value)} };
             } else if (key == 'company_code'){
               where.product = { company : { code: {_iregex: this.$commonFuction.search(value.value)} } };
             } else if (key == 'product_code'){
               where.product_code = {_iregex: this.$commonFuction.search(value.value)};
             } else if (key == 'product_name'){
               where.product_name = {_iregex: this.$commonFuction.search(value.value)};
             } else if (key == 'material_name'){
               where.material = { name: {_iregex: this.$commonFuction.search(value.value)} };
             } else if (key == 'description'){
               where.order['description'] = {_iregex: this.$commonFuction.search(value.value)}
             } else if (key == 'order_code'){
               where.order['order_code'] = {_iregex: this.$commonFuction.search(value.value)}
             } else if (key == 'customer_code'){
               where.order['customer_code'] = {_iregex: this.$commonFuction.search(value.value)}
             } else if (key == 'lot_number'){
               where.lot_number = {_iregex: this.$commonFuction.search(value.value)};
             }
             else {
               where[key] = {_iregex: this.$commonFuction.search(value.value)};
             }
           }
         }
         else if(value.type_input == 'select'){
            if(key == "type_order"){
                if(value.value !== undefined && value.value !== null){
                  where.order['type'] = {_eq: value.value}
                }
            } else if(key == "company_id"){
                if(value.value !== undefined && value.value !== null){
                    where.order['company_id'] = {_eq: value.value}
                }
            }else if(key == "unit_name"){
                if(value.value !== undefined && value.value !== null){
                    where.product = {
                      unit_id: {_eq: value.value}
                    }
                }
            }else if(key == "material_code"){
                if(value.value !== undefined && value.value !== null){
                    where.materials_id = {_eq: value.value}
                }
            }else if(key == "active"){
                if(value.value !== undefined && value.value !== null){
                    where.active = {_eq: Boolean(value.value)}
                }
            }else if(key == "warehouse_code"){
                if(value.value !== undefined && value.value !== null){
                    where.warehouse_id = {_eq: value.value}
                }
            }else if(key == "order_status"){
                if(value.value !== undefined && value.value !== null){
                    where.order['current_order_status_id'] = {_eq: value.value}
                }
            }else if(key == "delivery_status"){
              if(value.value !== undefined && value.value !== null){
                  where.order['current_order_status_id'] = {_eq: value.value}
              }
            } else if(key == "warning_expiry"){
                if(value.value !== undefined && value.value !== null){
                    var max = null;
                    var min = null;
                    switch(value.value) {
                      case 1:
                        max = this.getDateSelectMonth(1);
                        break;
                      case 3:
                        min = this.getDateSelectMonth(1);
                        max = this.getDateSelectMonth(3);
                        break;
                      case 6:
                        min = this.getDateSelectMonth(3);
                        max = this.getDateSelectMonth(6);
                        break;
                      case 12:
                        min = this.getDateSelectMonth(6);
                        max = this.getDateSelectMonth(13);
                        break;
                      case 13:
                        min = this.getDateSelectMonth(12);
                        break;
                    }
                    if (!this.$commonFuction.isEmpty(min) && !this.$commonFuction.isEmpty(max)) {
                      where.expiry_date = { _gte: min,  _lt: max }
                    } else if (!this.$commonFuction.isEmpty(min) && this.$commonFuction.isEmpty(max)) {
                      where.expiry_date = { _gt: min }
                    } else if (this.$commonFuction.isEmpty(min) && !this.$commonFuction.isEmpty(max)) {
                      where.expiry_date = { _lt: max }
                    }
                }
            }
            else{
                if(value.value !== undefined && value.value !== null){
                  where[key] = {_eq: value.value}
                }
            }
          }else if (value.type_input == 'DateRange'){
              if(value.value !== undefined && value.value !== null){
                if(key == "date_order"){
                  if(value.value !== null && value.value[1] != null){
                    where.order['date_order'] = {'_gte': value.value[0],'_lt': value.value[1]}
                  }
                } else {
                  where[key] = {'_gte': value.value.from,'_lt': value.value.to}
                }
              }

          } else if (value.type_input == 'Date'){
            if(value.value !== undefined && value.value !== null){
              if(key == "date_order"){
                  where.order['date_order'] = {_eq: moment.tz(value.value, this.time_zone_local).format("yyyy-MM-DD")}
              } else if(key == "doc_created_at"){
                  where.order['doc_created_at'] = {_eq: moment.tz(value.value, this.time_zone_local).format("yyyy-MM-DD")}
              } else {
                where[key] = {_eq: value.value}
              }
            }
          } else if (value.type_input == 'number'){
            if(value.value !== undefined && value.value !== null){
              if(key == "qty_export_txt"){
                  where.export_qty = {_eq: value.value}
              } else if(key == "qty_import_txt"){
                  where.import_qty = {_eq: value.value}
              } else {
                where[key] = {_eq: value.value}
              }
            }
          }
       }
     }
     var resData = await this.$apollo.mutate({
       mutation: gqLQueryList,
       variables: {
         where_key:where ,
         offset:offset,
         limit:limit,
         orderBy:{created_at: 'desc'}
       }
     });
     var listData = resData.data[this.model.tableName];

     var gqLQueryCount = DataServices.countData(this.model.tableName);
     var countRes = await this.$apollo.mutate({
       mutation: gqLQueryCount,
       variables: {
         where_key:where
       }
     });
     var total = countRes.data[this.model.tableName+'_aggregate']['aggregate']['count'];

     var dataRes = {
       listData: listData,
       total: total,
     };
    //  console.log('dataRes:',dataRes);
     return dataRes;
    },
    async onLazyEvent() {
      this.lazyParams.filters = {...this.lazyParams.filters, ...this.filtersCustom};
      this.$emit("getFilters", this.lazyParams.filters);
      // this.lazyParams.filters = this.filtersCustom;
      // // console.log('this.lazyParams:',this.lazyParams)
      // //this.$emit('loading', true);
      // var that =this;
      // this.dataList(this.lazyParams).then(async data => {
      //   if (data.listData.length < 1) {
      //     that.listDataService = data.listData;
      //     that.totalRecords = data.total;
      //     that.loading = false;
      //   }
      //   var stt = this.lazyParams.first;
      //   // var service_name_arr = [];
      //   for (var i = 0; i < data.listData.length; i++) {
      //     stt++;
      //     data.listData[i].stt = stt;
      //     // var code = await this.$CoreService.getTypeOrderByCode(data.listData[i]?.order?.code)
      //     // console.log("code", code);
      //     // var type_order = this.typeOrderList.filter((m) => m.value == code);
      //     // data.listData[i].type_order = type_order[0]?.label || '-';
      //     data.listData[i].unit_name = data.listData[i]?.unit?.name || "-";
      //     data.listData[i].material_name = data.listData[i]?.material?.name || "-";
      //     data.listData[i].material_code = data.listData[i]?.material?.code || "-";
      //     data.listData[i].order_code = data.listData[i]?.order?.code || "-";
      //     data.listData[i].date_order = data.listData[i]?.order?.date_order || null;
      //     data.listData[i].doc_created_at = data.listData[i]?.order?.doc_created_at || null;
      //     data.listData[i].doc_code = data.listData[i]?.order?.doc_code || "-";
      //     data.listData[i].description = data.listData[i]?.order?.description || "-";
      //     data.listData[i].customer_code = data.listData[i]?.order?.customer_code || "-";
      //     data.listData[i].qty_import_txt = this.$commonFuction.numberFormat(data.listData[i]?.import_qty,0) || 0;
      //     data.listData[i].qty_export_txt = this.$commonFuction.numberFormat(data.listData[i]?.export_qty,0) || 0;
      //     data.listData[i].warehouse_export_name = data.listData[i]?.warehouseByWarehouseId?.name || "-";
      //     data.listData[i].warehouse_export_code = data.listData[i]?.warehouseByWarehouseId?.code || "-";
      //     data.listData[i].warehouse_import_name = data.listData[i]?.warehouse?.name || "-";
      //     data.listData[i].warehouse_import_code = data.listData[i]?.warehouse?.code || "-";
      //     data.listData[i].order_status = data.listData[i]?.order?.order_status?.name || "-";
      //     data.listData[i].delivery_status = data.listData[i]?.order?.order_status?.name || "-";
      //   }
      //   // console.log(data.listData)
      //   that.listDataService = data.listData;
      //   that.totalRecords = data.total;
      //   that.loading = false;
      // });
    },
    async onPage(event) {
      var filterTmp = {...event, filters: this.lazyParams.filters};
      this.lazyParams = filterTmp;
      this.$emit("getFilters", this.lazyParams);
    },
    async onFilter(event,type_input,key){
        // console.log("type_input",type_input)
        // console.log("key",key)
      ////this.$emit('loading', true);
      this.tableData = this.test;
      if(type_input == "DateRange"){
        if(this.filters[key] !== null && this.filters[key][1]== null){
          return false;
        }
      }
      // if(type_input == "Date"){
      //   if(this.filters[key] !== null && this.filters[key][0]== null){
      //     return false;
      //   }
      // }
    //   this.filtersCustom[key] = {
    //     type_input:type_input,
    //     value:this.filters[key],
    //   };
      // console.log('this.filtersCustom:',this.filtersCustom);
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      // console.log('this.filtersCustom:',this.filtersCustom);
      this.lazyParams.first = 0;
      // await this.onLazyEvent();
      this.lazyParams.filters = {...this.lazyParams.filters, ...this.filtersCustom};
      this.$emit("getFilters", this.lazyParams.filters);
      /*setTimeout(() => {
        this.customers = this.datasource.slice(event.first, event.first + event.rows);
        //this.$emit('loading', false);
      }, 500);*/
    },
    calculateQtyTotal(k) {
      let total = 0;
      if (this.listDataService) {
        this.listDataService.map(m => {
          for (const [key, value] of Object.entries(m)) {
            if (key == k) {
              total += parseInt(value);
            }
          }
        })
      }
      return this.$commonFuction.numberFormat(total, 0);
    },
    
    async exportExcelFunc() {
      //this.$emit('loading', true);
      const headers = {
        "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
      };
      ApiRepository.post("_api/common/report_inventory_export",this.lazyParams, {headers}).then(async (res) => {
        // console.log("ressssssssss", res);
        //this.$emit('loading', false);
        this.$emit('exportDone');
        if (res?.data?.data?.linkExcel) {
          window.open(res?.data?.data?.linkExcel);
        }
      }).catch(async error => {
            this.$emit('exportDone');
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            //this.$emit('loading', false);
        });
    },
  },
};
</script>
<style lang="scss">
.p-paginator-bottom{padding: 0 !important;}
.p-inputtext{
  width: 100%;
}
.p-button-label-o{
  color : white;
}
.p-button-primary {
  width: auto !important;
  font-size: 7.5pt;
  padding: 0.429rem 1rem !important;
}
.product_view.p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
  cursor: unset;
}

.vs--single:not(.vs--open) .vs__selected {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 85%;
  height: fit-content;
  align-self: center;
}

.vs__dropdown-option {
  min-width: max-content;
  width: 100%;
}
.bottom-0 {
  bottom: 0;
}

.txt-medium-col {
  max-width: 150px !important;
  min-width: 150px !important;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.txt-des-medium-col {
  max-width: 200px !important;
  min-width: 200 !important;
}
.txt-mi-medium-col {
  max-width: 180px !important;
  min-width: 180px !important;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
@media(max-width: 960px){
  .p-rowgroup-footer{
    display: none !important;
  }
}
</style>