<template>
  <form id="frm_action" class="parent_page_detail_ship">
    <div class="p-formgrid">
<!--      <div class="p-col-12 group-button-detail-top"  v-if="mode == 'detail'" >
        <Button v-for="lad in list_action_detail" :key="lad.code_change"
            :label="lad.action"
            class=" style-button-common main-style-button"
            @click="click_change_status(lad.code_change)"
        />
      </div>-->
<!--      <Panel class="group-panel-detail"  :class="mode=='edit' ? 'group-panel-edit':'group-panel-detail-style'">
        <template #header>
          THÔNG TIN CHUNG
        </template>-->
        <div class="p-card-field style-block-panel" v-if="!is_lock">
          <!-- <div class="p-field p-grid" v-if="(!$commonFuction.isEmpty(code))">
            <label class="p-col-fixed txt-right">Mã chủ thú cưng (*)</label>
            <div class="p-col">
              <InputText disabled="disabled" v-model="code"   class="p-col-width" />
            </div>
          </div> -->
          <div class=" block-input">
            <div class="style-col-input left-group-input">
              <div class="group-input-panel">
                <div class="item-left-group-custom">
                  <label class="p-col-fixed txt-right">Mã chuyến</label>
                  <div class="p-col">
                    <InputText disabled required v-model="obj_data.code"   type="text" class="p-col-width" placeholder="Nhập" />
                  </div>
                </div>
                <div class="item-right-group-custom">
                  <label class="p-col-fixed txt-right">Số thứ tự <span style="color: red">*</span></label>
                  <div class="p-col">
                    <InputText required v-model="data_change.ordinal"   type="text" class="p-col-width" placeholder="Nhập" />
                  </div>
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Phương tiện</label>
                <div class="p-col">
                  <Dropdown   v-model="data_change.vehicle_id" :options="list_vehicle" optionLabel="name" optionValue="id" placeholder="Chọn " />
                  <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Tổng số kiện</label>
                <div class="p-col">
                  <InputText  required v-model="data_change.total_package"  id="doc_code" type="text" class="p-col-width" placeholder="Nhập" />
                </div>
              </div>
              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Dịch vụ vận chuyển</label>
                <div class="p-col">
                  <div class="p-inputgroup">
                    <!--                    <span class="p-inputgroup-addon">$</span>-->
                    <InputText placeholder="Nhập" v-model="data_change.shipping_service_price" />
                    <span class="p-inputgroup-addon">đ</span>
                  </div>
                </div>
              </div>

              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Giao bằng xe tải từ điểm giao thứ 2</label>
                <div class="p-col">
                  <div class="p-inputgroup">
                    <!--                    <span class="p-inputgroup-addon">$</span>-->
                    <InputText placeholder="Nhập" v-model="data_change.shipping_truck_price" />
                    <span class="p-inputgroup-addon">đ</span>
                  </div>
                </div>
              </div>

              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Hàng trả về có kết hợp với chuyến đi giao cùng địa điểm</label>
                <div class="p-col">
                  <div class="p-inputgroup">
                    <!--                    <span class="p-inputgroup-addon">$</span>-->
                    <InputText placeholder="Nhập" v-model="data_change.return_delivery" />
                    <span class="p-inputgroup-addon">đ</span>
                  </div>
                </div>
              </div>



              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Hàng trả về không kết hợp với chuyến đi giao cùng địa điểm</label>
                <div class="p-col">
                  <div class="p-inputgroup">
                    <!--                    <span class="p-inputgroup-addon">$</span>-->
                    <InputText placeholder="Nhập" v-model="data_change.return_not_delivery" />
                    <span class="p-inputgroup-addon">đ</span>
                  </div>
                </div>
              </div>


              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Ghép chuyến với hàng của Lê Mây</label>
                <div class="p-col">
                  <div class="p-inputgroup">
                    <!--                    <span class="p-inputgroup-addon">$</span>-->
                    <InputText placeholder="Nhập" v-model="data_change.combine_flights_company" />
                    <span class="p-inputgroup-addon">đ</span>
                  </div>
                </div>
              </div>

              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Dịch vụ vận chuyển từ chành đến NPP</label>
                <div class="p-col">
                  <div class="p-inputgroup">
                    <!--                    <span class="p-inputgroup-addon">$</span>-->
                    <InputText placeholder="Nhập" v-model="data_change.transportation_service_npp_price" />
                    <span class="p-inputgroup-addon">đ</span>
                  </div>
                </div>
              </div>

              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Dịch vụ bốc xếp</label>
                <div class="p-col">
                  <div class="p-inputgroup">
                    <!--                    <span class="p-inputgroup-addon">$</span>-->
                    <InputText placeholder="Nhập" v-model="data_change.handling_fee_price" />
                    <span class="p-inputgroup-addon">đ</span>
                  </div>
                </div>
              </div>

              <div class="group-input-panel">
                <label class="p-col-fixed txt-right">Gửi/ trả hàng bằng bưu điện</label>
                <div class="p-col">
                  <div class="p-inputgroup">
                    <!--                    <span class="p-inputgroup-addon">$</span>-->
                    <InputText placeholder="Nhập" v-model="data_change.send_return_goods_post" />
                    <span class="p-inputgroup-addon">đ</span>
                  </div>
                </div>
              </div>
              <div class="group-input-panel">
<!--                <label class="p-col-fixed txt-right">Gửi/ trả hàng bằng bưu điện</label>-->
                <div class="p-col">
                  <div class="p-inputgroup div-button-add">
                    <!--                    <span class="p-inputgroup-addon">$</span>-->
<!--                    <InputText placeholder="Nhập" v-model="data_change.send_return_goods_post" />
                    <span class="p-inputgroup-addon">đ</span>-->
                    <Button @click="process_add_cost()" class="p-button-success" icon="pi pi-plus"  />
                  </div>
                </div>
              </div>


  <!--            <label class="p-col-fixed txt-right">Họ tên1(*)</label>
              <div class="p-col">
                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />
              </div>-->
            </div>

          </div>
          <div class="p-grid block-input clear-div-custom">
          </div>
        </div>
<!--      </Panel>-->

<!--      <Panel class="group-panel-detail" :class="mode=='edit' ? 'group-panel-edit':'group-panel-detail-style'">
        <template #header>
          THÔNG TIN HÀNG HÓA
        </template>-->
        <div class="p-card-field style-block-panel">
          <!-- <div class="p-field p-grid" v-if="(!$commonFuction.isEmpty(code))">
            <label class="p-col-fixed txt-right">Mã chủ thú cưng (*)</label>
            <div class="p-col">
              <InputText disabled="disabled" v-model="code"   class="p-col-width" />
            </div>
          </div> -->
          <div class=" block-input"  v-if="dataModelOrderDetail && obj_id">
            <BaseDatatable
                :key="componentKeyOrderDetail"
                :tableData="listDataOrderDetail"
                :model="dataModelOrderDetail"
                :options="optionsOrderDetail"
                :obj_id="obj_id"
                :actions="getRowActionsOrderItem()"
                v-on:action-click="onActionClickedOrderDetail"
            ></BaseDatatable>
          </div>
          <div class="p-grid block-input clear-div-custom">
          </div>
        </div>
<!--      </Panel>-->


      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-warning callback-btn"
          />
          <Button
              v-if="!is_lock"
              label="Lưu"
              @click="saveData()"
              class="p-button-success main-style-button"
          />
<!--          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="p-button-success main-style-button"
          />-->
          <Dialog
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-change-product" header="Chỉnh sửa sản phẩm trong đơn" footer="Footer" :visible.sync="data_popup_product.display_add_product">
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Sản phẩm</label>
              <div class="p-col">
                <Dropdown
                    @change="change_product_popup($event)"
                    v-model="data_popup_product.obj_product.product_id" :options="data_popup_product.list_product" optionLabel="name" optionValue="id" placeholder="Chọn"  />
                <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                <input class="input_tmp_validator" id="popup_product_product_id">
              </div>
            </div>
            <div class="group-input-popup">
              <div class="item-left-popup" style="">
                <label class="p-col-fixed txt-right">Mã sản phẩm</label>
                <div class="p-col">
                  <InputText disabled="true" v-model="data_popup_product.obj_product.product_code"  type="text" class="p-col-width" placeholder="Nhập" />
                </div>
              </div>
              <div class="item-right-popup" style="">
                <label class="p-col-fixed txt-right">Đơn vị tính</label>
                <div class="p-col">
                  <InputText disabled="true" v-model="data_popup_product.obj_product.unit_name"  type="text" class="p-col-width" placeholder="Nhập" />
                  </div>
              </div>
            </div>
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Mã kho xuất</label>
              <div class="p-col">
                <Dropdown v-model="data_popup_product.obj_product.warehouse_id" :options="data_popup_product.list_warehouse" optionLabel="warehouse_name" optionValue="warehouse_id" placeholder="Chọn" />
                <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                <input class="input_tmp_validator" id="popup_product_warehouse_id">
              </div>
            </div>
            <div class="group-input-popup">
              <div class="item-left-popup" style="">
                <label class="p-col-fixed txt-right">Số lô</label>
                <div class="p-col">
                  <Dropdown v-model="data_popup_product.obj_product.lot_number" :options="list_lot_number_group" optionLabel="key_lot" optionValue="key_lot" placeholder="Chọn" />
                  <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                  <input class="input_tmp_validator" id="popup_product_lot_number">
                </div>
              </div>
              <div class="item-right-popup" style="">
                <label class="p-col-fixed txt-right">Hạn sử dụng</label>
                <div class="p-col">
                  <Dropdown @change="change_test($event)" v-model="data_popup_product.obj_product.expiry_date" :options="list_expiry_date_group" optionLabel="text_expiry_date" optionValue="key_expiry_date" placeholder="Chọn" />
                  <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                  <input class="input_tmp_validator" id="popup_product_expiry_date">
                </div>
              </div>
            </div>

            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Số lượng</label>
              <div class="p-col">
                <InputText  v-model="data_popup_product.obj_product.qty"  type="text" class="p-col-width" placeholder="Nhập" id="popup_product_qty" />
                </div>
            </div>

            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="data_popup_product.display_add_product = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="save_product_popup()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>


          <Dialog
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-confirm-payment" header="Xác nhận thanh toán" footer="Footer" :visible.sync="display_confirm_payment">
              <div class="text_confirm_popup">Bạn xác nhận rằng đơn hàng này đã được thanh toán đầy đủ bởi khách hàng.</div>
            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="display_confirm_payment = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="process_confirm_payment()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>

          <Dialog
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-cancel-order" header="Hủy đơn" footer="Footer" :visible.sync="data_popup_cancel_order.display_cancel_order">
            <div class="text_confirm_popup">Bạn muốn huỷ đơn hàng này, vui lòng cập nhật lý do:</div>
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Lý do *</label>
              <div class="p-col">
              <!--@change="change_product_popup($event)"                -->
                <Dropdown

                    v-model="data_popup_cancel_order.reason_id" :options="data_popup_cancel_order.list_reason" optionLabel="name" optionValue="id" placeholder="Chọn"  />
                <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
               <input class="input_tmp_validator" id="data_popup_cancel_order_reason_id">
              </div>
            </div>
            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="data_popup_cancel_order.display_cancel_order = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="process_cancel_order()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>

          <Dialog
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-update-address-coordinator" header="Cập nhật địa chỉ điều phối" footer="Footer" :visible.sync="data_popup_update_address_coordinator.display">
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Loại điều phối</label>
              <div class="p-col" >

                <div class="field-radiobutton" >
                  <RadioButton id="type_delivery1"  value="1" v-model="data_popup_update_address_coordinator.type_delivery" />
                  <label for="type_delivery1">Giao hàng đến địa chỉ khách</label>
                </div>
                <div class="field-radiobutton">
                  <RadioButton id="type_delivery2"  value="2" v-model="data_popup_update_address_coordinator.type_delivery" />
                  <label for="type_delivery2">Giao hàng đến chành</label>
                </div>
                <div class="field-radiobutton" v-if="this.type_order !=2">
                  <RadioButton id="type_delivery3"  value="3" v-model="data_popup_update_address_coordinator.type_delivery" />
                  <label for="type_delivery3">Gửi bưu điện</label>
                </div>
                <div class="field-radiobutton" v-if="this.type_order !=2">
                  <RadioButton id="type_delivery4"  value="4" v-model="data_popup_update_address_coordinator.type_delivery" />
                  <label for="type_delivery4">Tự lấy hàng</label>
                </div>

              </div>
            </div>

            <div class="group-input-popup" v-if="data_popup_update_address_coordinator.type_delivery==2">
              <label class="p-col-fixed txt-right">Chành *</label>
              <div class="p-col">
                <!--@change="change_product_popup($event)"                -->
                <Dropdown

                    v-model="data_popup_update_address_coordinator.freight_id" :options="data_popup_update_address_coordinator.list_freight" optionLabel="text" optionValue="id" placeholder="Chọn"  />
                <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                <!--                <input class="input_tmp_validator" id="popup_product_product_id">-->
              </div>
            </div>
            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="data_popup_update_address_coordinator.display = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="process_update_address_coordinator()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>


          <Dialog
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-update-pakage" header="Cập nhật kiện hàng" footer="Footer" :visible.sync="data_popup_update_pakage.display">
            <div class="text_confirm_popup">Bạn xác nhận đơn hàng đã được soạn hàng xong với thông tin số kiện được liệt kê bên dưới và sẵn sàng cho hoạt động điều phối.</div>
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Mã đơn hàng</label>
              <div class="p-col">
                <InputText disabled="true" v-model="obj_data.code"  type="text" class="p-col-width" placeholder="Nhập" />
                </div>
            </div>
            <div class="group-input-popup">
              <div class="p-col">
                <Checkbox id="data_popup_update_pakage_combine_packages" v-model="data_popup_update_pakage.obj_data.combine_packages" :binary="true" />
                <label for="data_popup_update_pakage_combine_packages" class="title_check_box">Ghép kiện</label>
              </div>
            </div>

            <div class="group-input-popup" v-if="data_popup_update_pakage.obj_data.combine_packages ==true">
              <label class="p-col-fixed txt-right">Đơn hàng muốn ghép</label>
              <div class="p-col">
                <InputText id="code_combine_packages" v-model="data_popup_update_pakage.obj_data.code_combine_packages"  type="text" class="p-col-width" placeholder="Nhập" />
              </div>
            </div>
<!--

<li v-for="({ message }, index) in items">
  {{ message }} {{ index }}
</li>
-->

            <div class="group-input-popup" v-for="(item_pakage, index_pa) in data_popup_update_pakage.list_pakage_data" :key="item_pakage.index_pa">
              <span class="responsive-label">{{ getLabel(c) }}</span>
              <div class="item-left-popup" style="">
                <label class="p-col-fixed txt-right" v-if="index_pa==0">Kiện hàng</label>
                <div class="p-col ">
                  <Dropdown v-model="item_pakage.parcel_id" :options="data_popup_update_pakage.list_parcel" optionLabel="name" optionValue="id" placeholder="Chọn" />
                  <!--                <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />-->
                </div>
              </div>
              <div class="item-right-popup" style="">
                <label class="p-col-fixed txt-right" v-if="index_pa==0">Số lượng</label>
                <div class="p-col style_group_pakage">
                  <InputText  v-model="item_pakage.parcel_qty"  type="text" class="p-col-width popup_qty_pakage" placeholder="Nhập" />
                <img  @click="delete_pakage_popup(item_pakage.key_data)" class="style_icon_del_pakage" style="" src="/assets/images/icon/ic_delete.svg">
                </div>
              </div>
            </div>

            <div class="group-input-popup">
                <div class="add_item_popup" @click="add_pakage_popup()">Thêm kiện hàng</div>
            </div>

            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="data_popup_update_pakage.display = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="process_submit_pakage()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>
          <Dialog
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-confirm-profile" header="Chuyển hồ sơ chứng từ lên kế toán" footer="Footer" :visible.sync="data_popup_confirm_profile.display">
            <div class="text_confirm_popup">Bạn xác nhận chuyển toàn bộ Chứng từ của Đơn hàng đã chọn cho Kế toán?</div>
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Nhập số lượng chứng từ đã nhận:</label>
              <div class="p-col" v-for="du in list_docs_update" :key="du.id">
                <span v-if="du.number_request > 0">
                  <!--@change="change_product_popup($event)"                -->
                  <InputText  v-model="du.number_process"  type="text" class="p-col-width number_profile" placeholder="Nhập"  />
                  <div class="group_text_profile">
                    <span class="text_profile_request">/{{du.number_request}}  </span>
                    <span class="name_profile">{{(!$commonFuction.isEmpty(du.document)) ? du.document.name:''}}</span>
                  </div>
                </span>
              </div>
            </div>
            <div class="group-input-popup">
              <label class="p-col-fixed txt-right">Ghi chú hồ sơ chứng từ</label>
              <div class="p-col">
                <Textarea v-model="data_popup_confirm_profile.obj_data.note" autoResize="true"  cols="30"  />
              </div>
            </div>
            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="data_popup_confirm_profile.display = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="process_update_profile()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>
          <Dialog
              :modal="true"
              :showHeader="true"
              position="center" class="custom-popup-common style-popup-confirm-close-profile" header="Xác nhận đóng đơn & đủ chứng từ" footer="Footer" :visible.sync="data_popup_confirm_close_profile.display">
            <div class="text_confirm_popup">Bạn xác nhận rằng đã nhận đủ chứng từ giao hàng & đóng đơn hàng này. Đơn hàng đã đóng sẽ không được cập nhật chỉnh sửa bất kỳ thông tin gì.</div>
            <template #footer>
              <Button
                  icon="pi pi-times" iconPos="left"
                  label="Hủy"
                  @click="data_popup_confirm_close_profile.display = false"
                  class="p-button-success style-button-cancel-popup "
              />
              <Button
                  icon="pi pi-check" iconPos="left"
                  label="Xác nhận"
                  @click="process_submit_enough_profile()"
                  class="p-button-success main-style-button"
              />
            </template>
          </Dialog>
          <Dialog
              header="Xóa"
              :visible.sync="display_popup_delete_cost"
              :contentStyle="{overflow: 'visible'}"
              :modal="true"
          >
            Bạn có chắc muốn xóa
            <template
                #footer
            >
              <Button label="Xóa" @click="process_delete_cost()" icon="pi pi-check" class="p-button-danger" />
              <Button
                  label="Hủy"
                  @click="display_popup_delete_cost = false"
                  icon="pi pi-times"
                  class="p-button-warning callback-btn"
              />
            </template>
          </Dialog>
        </template>
      </Toolbar>
    </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices'
import gql from "graphql-tag";
import BaseDatatable from './list_order_item'
import ApiRepository from "@/core/ApiRepository";
import moment from "moment-timezone";

export default {
  components: {
    BaseDatatable,
    // Popup
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      dataDeleteTmpCost:null,
      display_popup_delete_cost:false,
      list_map_vehicle:[],
      list_vehicle:[],
      data_change:{
        return_not_delivery:null,
        return_delivery:null,
        vehicle_id:null,
        ordinal:null,
        total_package:null,
        shipping_service_price:null,
        shipping_truck_price:null,
        goods_returned_price:null,
        handling_fee_price:null,
        send_return_goods_post:null,
        transportation_service_npp_price:null,
        combine_flights_company:null,
      },
      disable_common:false,
      data_popup_confirm_close_profile:{
        display:false,
        obj_data:{

        }
      },
      data_popup_confirm_profile:{
        display:false,
        obj_data:{

        }
      },
      data_popup_update_pakage:{
        display:false,
        list_pakage_data:[],
        list_parcel:[],
        obj_data:{
          combine_packages:false,
          code_combine_packages:'',
          id_combine_packages:''
        }
      },
      data_popup_update_address_coordinator:{
        display:false,
        list_freight:[],
        freight_id :null,
        type_delivery:null,
        key:0
      },
      data_popup_cancel_order:{
        display_cancel_order:false,
        list_reason:[],
        reason_id :null
      },
      display_confirm_payment:false,
      map_warehouse:{},
      list_lot_number_group:[],
      list_expiry_date_group:[],
      data_popup_product:{
        map_convert_data:{},
        display_add_product:false,
          list_warehouse:[],
          list_product:[],
          list_lot_number:[],
          obj_product:{
           /* key_data:null,
            id:null,
            product_id:null,
            stt:null,
            product_code:null,
            product_name:null,
            /!*unit_name:item_order.unit.name,*!/
            unit_name:null,
            lot_number:null,
            expiry_date:null,
            material_code: null,
            warehouse_code:  null,
            warehouse_id:  null,*/
          }

      },
      list_action_detail:[],
      list_pakage:[],
      obj_id:null,
      list_docs: [],
      list_docs_update: [],
      listDataOrderDetail: [],
      modelNameOrderDetail: '',
      dataModelOrderDetail: null,
      componentKeyOrderDetail: 0,
      optionsOrderDetail: [],
      obj_data :{},
      list_user:[],
      staff_id :null,//NV phụ trách
      list_status: [ ],
      type_order:1,
      user:{
        fullname: '',
        phone: '',
        email: '',
        password: '',
        password_confirm: '',
        group_id:null
      },
      code: '',
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role:[],
      saving:false,
      is_lock:false,
      addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}

    }
  },
  async mounted() {
    console.log("this.modethis.mode:",this.mode)
    if (this.mode == 'detail'){
      this.disable_common = true;
    }
    //var that =this;
    //Lấy danh sách nv
    var gqLQueryListUser = DataServices.getList('users');
    var where_user = {}
    var resData = await this.$apollo.mutate({
      mutation: gqLQueryListUser,
      variables: {
        where_key:where_user ,
        /*offset:offset,
        limit:limit,*/
        orderBy:{created_at: 'desc'}
      }
    });
    var listDataUser = resData.data['users'];
    var list_user_custom = [];
    for (var i=0;i<listDataUser.length;i++){
      var item_user_custom = listDataUser[i];
      var fullname = (item_user_custom.fullname) ? item_user_custom.fullname:'';
      var code = (item_user_custom.code) ? item_user_custom.code:'';
      var text_lable = code+" - "+fullname
      item_user_custom.text_lable= text_lable
      list_user_custom.push(item_user_custom)
    }
    this.list_user = list_user_custom
    console.log("this.modelthis.model:",this.model)

    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.modelthis.model:this.model",this.model)
      this.obj_id = this.model.id
      this.is_lock = this.model.is_lock
      console.log("this.obj_id:",this.obj_id)
      this.obj_data = this.model
     /* this.obj_data.doc_created_at = this.obj_data.doc_created_at !== null && new Date(this.obj_data.doc_created_at) || null;
      this.obj_data.date_order = this.obj_data.date_order !== null && new Date(this.obj_data.date_order) || null;
      this.obj_data.date_expected = this.obj_data.date_expected !== null && new Date(this.obj_data.date_expected) || null;
      this.obj_data.date_done = this.obj_data.date_done !== null && new Date(this.obj_data.date_done) || null;
      this.obj_data.date_paid = this.obj_data.date_paid !== null && new Date(this.obj_data.date_paid) || null;
      *///Lấy danh sách chứng từ
     /* this.load_docs();
      this.load_pakage();
      this.load_status_order();*/
      this.load_list_detail_order();
      this.load_vehicle();
      /*this.load_button_detail();*/
/*
      this.model.email_old = this.model.email;

      var dataGroupUser = await this.getGroupUser(this.model.id);
      if (dataGroupUser && dataGroupUser.length){
        this.model.group_id = dataGroupUser[0].group_id;
      }
      this.user = this.$commonFuction.convertJsonObject(this.model);



      this.$CoreService.getAccountByUid(this.model.id).then(res => {
        console.log("getAccount",res)
        if (res && res.length){
          this.user.user_id = res[0].uid;
        }
      });*/
    }
    /*this.listGroupRole().then(res => {
      console.log("resres",res)
      this.list_group_role = res;
    });*/
  },
  methods: {
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    change_vehicle(event){
      console.log("eventeventevent:",event)
    },
    process_delete_cost(){
      this.process_save_cost(3,this.$commonFuction.convertJsonObject(this.dataDeleteTmpCost));
      this.display_popup_delete_cost = false;
    },
    process_add_cost(){
      var key_data = (this.data_change.key_data) ? this.data_change.key_data:null;
      this.data_change.text_code =  this.obj_data.code+" - "+this.data_change.ordinal
      var vehicle_id = this.data_change?.vehicle_id ?? null;
      if ((!this.$commonFuction.isEmpty(vehicle_id)) ){
      console.log("this.list_map_vehicle[vehicle_id]:",this.list_map_vehicle[vehicle_id]["name"])
      this.data_change.vehicle_name = this.list_map_vehicle[vehicle_id]["name"]
      }
      if ((this.$commonFuction.isEmpty(key_data)) ){
        this.data_change.key_data = (new Date()).getTime()+""
        //Thêm mới
        this.process_save_cost(1,this.$commonFuction.convertJsonObject(this.data_change))
      }else {
        //chỉnh sửa
        this.process_save_cost(2,this.$commonFuction.convertJsonObject(this.data_change))
      }
    },
    async process_save_cost(type,params=null) {
      /*console.log("paramsparams:",params)
      console.log("paramsparams:",type)*/
      if (type==1){
        this.listDataOrderDetail.push(params)
        console.log("this.listDataOrderDetail:",this.listDataOrderDetail)
      }else {
        var key_check = params.key_data
        var list_data_res = []
        for (var i=0;i<this.listDataOrderDetail.length;i++){
          var item_data =  this.listDataOrderDetail[i];
          var key_data = item_data.key_data
          if (key_check == key_data){
            if (type==2){
              //update
              list_data_res.push(params);
            }else {
              //xóa || ko xử lý
              console.log("xoa")
            }
          }else {
            list_data_res.push(item_data)
          }
        }
        this.listDataOrderDetail = list_data_res;

      }
      this.dataModelOrderDetail.processData(this.listDataOrderDetail)
      this.componentKeyOrderDetail++;
    //  this.data_popup_product.display_add_product=false;

    },
    change_test(){
      console.log("data_popup_product.obj_product.expiry_date:",this.data_popup_product.obj_product.expiry_date)
    },
    async get_order_ship() {
      try {
        var list_id = [];
        list_id.push(this.model.id)
        var dataRequest = {
          list_id: list_id,
        };
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        var item_order_ship = await ApiRepository.post('/_api/order/get-order-ship', dataRequest, {headers});
        var order_ship_map  = (item_order_ship["data"]["data"]) ? item_order_ship["data"]["data"]:null
        console.log("order_shiporder_ship:",order_ship_map)
        if (order_ship_map){
          if (!this.$commonFuction.isEmpty(order_ship_map[this.model.id])) {
            var order_ship = order_ship_map[this.model.id]
            this.data_popup_update_address_coordinator.type_delivery = (order_ship.delivery_type) ? order_ship.delivery_type + "" : null
            //this.data_popup_update_address_coordinator.key++;
            //this.data_popup_update_address_coordinator.type_delivery = 2
            //console.log("this.data_popup_update_address_coordinator.type_delivery:",this.data_popup_update_address_coordinator.type_delivery)
            this.data_popup_update_address_coordinator.freight_id = (order_ship.freight_id) ? order_ship.freight_id : null
          }
          }
      } catch (err) {
        console.log(err);
      }

    },
    async process_update_address_coordinator() {
      //cập nhật hsct
      var that = this
      var dataRequest = {
        id:this.model.id,
        type_delivery:this.data_popup_update_address_coordinator.type_delivery,
        freight_id:this.data_popup_update_address_coordinator.freight_id,
      }
      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/process-update-address-coordinator', dataRequest, {headers});
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });
        location.reload()
        return true
      } catch (err) {
        that.$toast.add({
          severity: "error",
          summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000,
        });
        console.log(err);
        return false;
      }
    },
    async list_freight_customer(id_customer) {
      var gqLQueryListData = DataServices.getList('freightcustomer_');
      var where_data = {
        customer_id: {_eq: id_customer},
      }
      var resData_data = await this.$apollo.mutate({
        mutation: gqLQueryListData,
        variables: {
          where_key:where_data ,
          /*offset:offset,
          limit:limit,*/
        //  orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_data.data['freight_customer'];
      var data_convert = [];
      for (var i=0;i<listData.length;i++){
          var item_data = listData[i];
          if ((!this.$commonFuction.isEmpty(item_data.freight))){
            var code = (!this.$commonFuction.isEmpty(item_data.freight)) ? item_data.freight.code+' - ':'';
            var name = (!this.$commonFuction.isEmpty(item_data.freight)) ? item_data.freight.name:'';
            var text =code + name;
            var item_data_convert = {
              id:item_data.freight.id,
              text:text
            }
            data_convert.push(item_data_convert)
          }

      }
      console.log("data_convertdata_convert:",data_convert)
      return data_convert;

    },
    async process_update_profile() {
      //cập nhật hsct
      var that = this


      var dataRequest = {
        id:this.model.id,
        list_docs_update:this.list_docs_update,
        note:this.data_popup_confirm_profile.obj_data.note,
      }
        try {
          const headers = {
            "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
          };
          await ApiRepository.post('/_api/order/process-update-profile-order', dataRequest, {headers});
          that.$toast.add({
            severity: "success",
            summary: "Thông báo",
            detail: "Cập nhật thành công",
            life: 3000,
          });
          location.reload()
          return true
        } catch (err) {
          that.$toast.add({
            severity: "error",
            summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
            detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
            life: 3000,
          });
          console.log(err);
          return false;
        }
    },
    async process_submit_enough_profile() {
      var that = this
      var dataRequest = {
        id:this.model.id,
        code_change:this.$constants.STATUS_ORDER.ENOUGH_DOCUMENTS_CLOSE_APPLICATION,
      }
      var status =  await this.update_status_order(dataRequest)
      if (status == true){
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });
        location.reload()
      }else {
        that.$toast.add({
          severity: "error",
          summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000,
        });
      }
    },
    async validateDataPopupPakage() {
      //var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;
     if (this.data_popup_update_pakage.obj_data.combine_packages){
       var code_combine_packages = document.getElementById("code_combine_packages");
       // var is_err = 0;
       if (this.$commonFuction.isEmpty(this.data_popup_update_pakage.obj_data.code_combine_packages)) {
         //     is_err = 1;
         code_combine_packages.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
       } else {
         var where_obj = {code: {'_eq': this.data_popup_update_pakage.obj_data.combine_packages},id: {_neq: this.model.id}};
         var obj_data = await this.$CoreService.getObjectData('order',where_obj);
         if (this.$commonFuction.isEmpty(obj_data)){
           this.data_popup_update_pakage.obj_data.id_combine_packages= obj_data.id
           code_combine_packages.setCustomValidity("Đơn hàng ghép kiện không hợp lệ");
         }else {
           code_combine_packages.setCustomValidity("");
         }

       }
     }


      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    async process_submit_pakage() {
      var that = this
      if (await this.validateDataPopupPakage()) {
        // this.saving = false;
        // this.$commonFuction.is_loading(false);
        return
      }
      if (this.data_popup_update_pakage.list_pakage_data.length <1){
        alert("Vui lòng thêm kiện hàng");
        return;
      }
      var dataRequest = {
        id:this.model.id,
        combine_packages:this.obj_data.code_combine_packages,
        list_pakage_data:this.data_popup_update_pakage.list_pakage_data,
        id_combine_order:this.data_popup_update_pakage.obj_data.id_combine_packages,
      }

      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/process-update-pakage', dataRequest, {headers});
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });
        location.reload()
        return true
      } catch (err) {
        that.$toast.add({
          severity: "error",
          summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000,
        });
        console.log(err);
        return false;
      }
    },
    async delete_pakage_popup(key_data) {
      var list_pakage_data = [];
      var list_pakage_data_convert = this.data_popup_update_pakage.list_pakage_data;
      for (var i=0;i<list_pakage_data_convert.length;i++){
        var item_data = list_pakage_data_convert[i];
        var key_data_check = item_data.key_data;
        if (key_data!==key_data_check){
          list_pakage_data.push(item_data)
        }
      }
      this.data_popup_update_pakage.list_pakage_data = list_pakage_data
    },
    async add_pakage_popup() {
      var item_pakage = {
            parcel_id:null,
            parcel_qty:0,
            key_data:(new Date()).getTime()+this.$commonFuction.makeId(8)
          }
      this.data_popup_update_pakage.list_pakage_data.push(item_pakage)
    },
    async validateDataPopupCancelOrder() {
      //var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;
      var data_popup_cancel_order_reason_id = document.getElementById("data_popup_cancel_order_reason_id");
      // var is_err = 0;
      if (this.$commonFuction.isEmpty(this.data_popup_cancel_order.reason_id)) {
        //     is_err = 1;
        data_popup_cancel_order_reason_id.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        data_popup_cancel_order_reason_id.setCustomValidity("");
      }
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    async process_cancel_order() {
      var that = this
      if (await this.validateDataPopupCancelOrder()) {
        // this.saving = false;
        // this.$commonFuction.is_loading(false);
        return
      }

      var dataRequest = {
        id:this.model.id,
        reason_id:this.data_popup_cancel_order.reason_id,
        code_change:this.$constants.STATUS_ORDER.CANCELED,
      }
      var status =  await this.update_status_order(dataRequest)
      if (status == true){
        that.$toast.add({
          severity: "success",
          summary: "Thông báo",
          detail: "Cập nhật thành công",
          life: 3000,
        });
        location.reload()
      }else {
        that.$toast.add({
          severity: "error",
          summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000,
        });
      }
    },
    async update_status_order(dataRequest) {
      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/update-status-order', dataRequest, {headers});
        return true
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async process_confirm_payment() {
      var that = this
      var order_id = this.model.id;
      var data_update = {
          paid:true,
          date_paid:(new Date()).toISOString()
      }
      let variables = {
        data_update: data_update,
        where_key: {
          id: { _eq:order_id },
        },
      };
      await this.$apollo
          .mutate({
            mutation: DataServices.updateData("order"),
            variables: variables,
          })
          .then(async (res) => {/*
            var uid = this.$store.getters.user;
            var data_insert = {
              order_id:order_id,
              name_process_custom:"Xác nhận thanh toán",
              created_by:uid,
              updated_by:uid,
            }
            await  this.$CoreService.insert_process_order(data_insert);
*/
            var dataRequest = {
              id:this.model.id,
              code_change:this.$constants.STATUS_ORDER.CONFIRM_PAYMENT,
              name_process_custom:"Xác nhận thanh toán",
            }
           await this.update_status_order(dataRequest)
            console.log("Thành công");
            console.log(res); //
            this.$commonFuction.is_loading(false);
            that.$toast.add({
              severity: "success",
              summary: "Thông báo",
              detail: "Cập nhật thành công",
              life: 3000,
            });
            location.reload()
            //this.$router.push({ path: "/admin/list-reason" });
          })
          .catch(async (error) => {
            console.log("error", error);
            that.$toast.add({
              severity: "error",
              summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000,
            });
            this.$commonFuction.is_loading(false);
            // this.summitEvent = false;
          });

    },
    async list_parcel() {
      var gqLQueryListData = DataServices.getList('parcel');
      var where_data = {
        active: {_eq: true},
        deleted: {_eq: false},
      }
      var resData_data = await this.$apollo.mutate({
        mutation: gqLQueryListData,
        variables: {
          where_key:where_data ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{ordinal: 'desc'}
        }
      });
      var listData = resData_data.data['parcel'];
      return listData;

    },
    async list_reason() {
      var gqLQueryListData = DataServices.getList('reason');
      var where_data = {
        active: {_eq: true},
        deleted: {_eq: false},
      }
      var resData_data = await this.$apollo.mutate({
        mutation: gqLQueryListData,
        variables: {
          where_key:where_data ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_data.data['reason'];
      return listData;

    },
    async validateDataPopupProduct() {
      //var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;
      var popup_product_id = document.getElementById("popup_product_product_id");
     // var is_err = 0;
      if (this.$commonFuction.isEmpty(this.data_popup_product.obj_product.product_id)) {
   //     is_err = 1;
        popup_product_id.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        popup_product_id.setCustomValidity("");
      }
      var popup_product_warehouse_id = document.getElementById("popup_product_warehouse_id");
      if (this.$commonFuction.isEmpty(this.data_popup_product.obj_product.warehouse_id)) {
      //  is_err = 1;
        popup_product_warehouse_id.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        popup_product_warehouse_id.setCustomValidity("");
      }
      var popup_product_lot_number = document.getElementById("popup_product_lot_number");
      if (this.$commonFuction.isEmpty(this.data_popup_product.obj_product.lot_number)) {
     //   is_err = 1;
        popup_product_lot_number.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        popup_product_lot_number.setCustomValidity("");
      }
      var popup_product_expiry_date = document.getElementById("popup_product_expiry_date");
      if (this.$commonFuction.isEmpty(this.data_popup_product.obj_product.expiry_date)) {
      ///  is_err = 1;
        popup_product_expiry_date.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        popup_product_expiry_date.setCustomValidity("");
      }
      var popup_product_qty = document.getElementById("popup_product_qty");
      if (this.$commonFuction.isEmpty(this.data_popup_product.obj_product.qty)) {
      //  is_err = 1;
        popup_product_qty.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      } else {
        popup_product_qty.setCustomValidity("");
      }


/*
      var text_validator_email = "";
      if (!this.$commonFuction.isEmpty(this.user.email)) {
        console.log("this.model:",this.model);
        this.account_exist = await this.checkEmailAdminExist(this.user.email.trim(), this.model);
        if (this.account_exist) {
          text_validator_email = this.$constants.MSG_TEXT.EMAIL_EXIST_SYSTEM_MSG;
        }
      }

      var email = document.getElementById("email");
      if (!this.$commonFuction.isEmpty(text_validator_email)) {
        email.setCustomValidity(text_validator_email);
      } else {
        email.setCustomValidity("");
      }
      var password_confirm_text_va = "";
      if (!this.$commonFuction.isEmpty(this.user.password) && !this.$commonFuction.isEmpty(this.user.password_confirm)) {
        if (this.user.password !== this.user.password_confirm) {
          password_confirm_text_va = this.$constants.MSG_TEXT.PASSWORD_NOT_MATCH_MSG;
          //password_confirm.setCustomValidity(this.$constants.MSG_TEXT.PASSWORD_NOT_MATCH_MSG);
        }
      }
      var password_text_va = "";
      if (this.formMode !== 'add') {
        if (this.user.email != this.user.email_old){
          //trường hợp khác thì bắt nhập lại mật khẩu
          if (this.$commonFuction.isEmpty(this.user.password)){
            password_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG;
          }
          if (this.$commonFuction.isEmpty(this.user.password_confirm)){
            password_confirm_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG
          }
        }else {
          //
          if (!this.$commonFuction.isEmpty(this.user.password)){
            //trường hợp thay đổi thì bắt nhập passcomfirt
            if (this.$commonFuction.isEmpty(this.user.password_confirm)){
              password_confirm_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG
            }
          }
          if (!this.$commonFuction.isEmpty(this.user.password_confirm)){
            //trường hợp thay đổi thì bắt nhập password
            if (this.$commonFuction.isEmpty(this.user.password)){
              password_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG;
            }
          }
        }
      }
      var password_confirm = document.getElementById("password_confirm");
      if (!this.$commonFuction.isEmpty(password_confirm_text_va)){
        password_confirm.setCustomValidity(password_confirm_text_va);
      }else {
        password_confirm.setCustomValidity("");
      }

      var password = document.getElementById("password");
      if (!this.$commonFuction.isEmpty(password_text_va)){
        password.setCustomValidity(password_text_va);
      } else {
        password.setCustomValidity("");
      }*/

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    async save_product_popup() {
      //this.obj_id

      if (await this.validateDataPopupProduct()) {
        // this.saving = false;
       // this.$commonFuction.is_loading(false);
        return
      }
      var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;

     /* var key_check_duplicate = this.data_popup_product.obj_product.product_id+this.data_popup_product.obj_product.warehouse_id+this.data_popup_product.obj_product.lot_number+this.data_popup_product.obj_product.expiry_date
      if ((this.$commonFuction.isEmpty(key_data)) ){
        //them moi
        console.log("sssssss")
        for (var i=0;i<this.listDataOrderDetail.length;i++){
            var item_data_check =  this.listDataOrderDetail[i]
          console.log("item_data_check",item_data_check)
          var expiry_date_root =  moment(item_data_check.expiry_date,"yyyy-MM-DD").format("yyyy-MM-DD");
            var key_check_add = item_data_check.product_id+item_data_check.warehouse_id+item_data_check.lot_number+expiry_date_root
          console.log("key_check_add:",key_check_add)
          console.log("key_check_duplicate:",key_check_duplicate)
            if (key_check_add == key_check_duplicate){
              alert("Sản phẩm đã tồn tại!")
              return false;
            }
        }
      }else {
        //edit
        console.log("listDataOrderDetaillistDataOrderDetaillistDataOrderDetail")
        for (var j=0;j<this.listDataOrderDetail.length;j++){
          console.log("listDataOrderDetaillistDataOrderDetaillistDataOrderDetail")
          var item_data_check_ =  this.listDataOrderDetail[j]
          var key_data_check_edit = item_data_check_.key_data
          if (key_data_check_edit != key_data){
            console.log("66666666666666666666")
            var expiry_date_root_ =  moment(item_data_check.expiry_date,"yyyy-MM-DD").format("yyyy-MM-DD");
            var key_check_edit = item_data_check_.product_id+item_data_check_.warehouse_id+item_data_check_.lot_number+expiry_date_root_
            console.log("key_check_edit:",key_check_edit)
            console.log("key_check_duplicate:",key_check_duplicate)
            if (key_check_edit == key_check_duplicate){
              alert("Sản phẩm đã tồn tại!")
              return false;
            }
          }
        }
      }*/
      console.log("save_product_popup:")
      //var key_data = (this.data_popup_product.obj_product) ? this.data_popup_product.obj_product.key_data:null;
      var warehouse_id = this.data_popup_product.obj_product.warehouse_id
      var item_warehouse = this.map_warehouse[warehouse_id];
      console.log("item_warehouse:",item_warehouse)
      this.data_popup_product.obj_product.warehouse_code = item_warehouse.warehouse.code
      this.data_popup_product.obj_product.warehouse_id = item_warehouse.warehouse.id
      if ((this.$commonFuction.isEmpty(key_data)) ){
        this.data_popup_product.obj_product.key_data = (new Date()).getTime()+""
        //Thêm mới
        this.process_save_product(1,this.data_popup_product.obj_product)
      }else {
        //chỉnh sửa
        this.process_save_product(2,this.data_popup_product.obj_product)
      }

    },
    async process_save_product(type,params=null) {
      console.log("paramsparams:",params)
      console.log("paramsparams:",type)
     if (type==1){
       this.listDataOrderDetail.push(params)
       console.log("this.listDataOrderDetail:",this.listDataOrderDetail)
     }else {
       var key_check = params.key_data
       var list_data_res = []
       for (var i=0;i<this.listDataOrderDetail.length;i++){
          var item_data =  this.listDataOrderDetail[i];
          var key_data = item_data.key_data
          if (key_check == key_data){
            if (type==2){
              //update
              list_data_res.push(params);
            }else {
              //xóa || ko xử lý
              console.log("xoa")
            }
          }else {
            list_data_res.push(item_data)
          }
       }
       this.listDataOrderDetail = list_data_res;

     }
      this.dataModelOrderDetail.processData(this.listDataOrderDetail)
     this.componentKeyOrderDetail++;
     this.data_popup_product.display_add_product=false;

    },
    async change_product_popup() {
      //this.obj_id
      var product_id = this.data_popup_product.obj_product.product_id
      //this.data_popup_product.obj_product = {}
     /* console.log()
      if ((!this.$commonFuction.isEmptyObject(this.data_popup_product.obj_product))){
        console.log("asssssssssssssssss,",this.data_popup_product.obj_product)
        this.data_popup_product.obj_product.lot_number = null
        this.data_popup_product.obj_product.expiry_date = null
        this.data_popup_product.obj_product.material_code = null
        this.data_popup_product.obj_product.warehouse_code = null
        this.data_popup_product.obj_product.warehouse_id = null
      }else {
        console.log("asdddddddddddd")
      }*/

      this.data_popup_product.obj_product.product_id = product_id
      var item_product = this.map_convert_data[product_id];
      console.log("item_productitem_product:",item_product)
      this.data_popup_product.obj_product.product_code = item_product.code
      this.data_popup_product.obj_product.product_name = item_product.name
      this.data_popup_product.obj_product.unit_name = (!this.$commonFuction.isEmpty(item_product.unit)) ? item_product.unit.name:null;
      this.data_popup_product.obj_product.unit_id = (!this.$commonFuction.isEmpty(item_product.unit)) ? item_product.unit.id:null;
      this.data_popup_product.obj_product.material_code = (!this.$commonFuction.isEmpty(item_product.material)) ? item_product.material.name:null;
      this.data_popup_product.obj_product.material_id = (!this.$commonFuction.isEmpty(item_product.material)) ? item_product.material.id:null;
      console.log("this.map_convert_data",this.map_convert_data[product_id])
      this.load_warehouse(product_id)
    },
    async load_warehouse(product_id) {
      //this.obj_id
      var gqLQueryListOrderDocs = DataServices.getList('stock_quant');
      var where_user_docs = {
        product_id: {_eq: product_id},
        qty: {_gt: 0},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['stock_quant'];
      var map_warehouse = {}
      var list_convert_order = []
      var group_lot_number = {};
      var group_expiry_date = {};
      var list_lot_number_group =[];
      var list_expiry_date_group =[];
      for (var i=0;i<listData.length;i++){
        var item_data = listData[i];
        item_data["warehouse_name"] = (!this.$commonFuction.isEmpty(item_data.warehouse)) ? item_data.warehouse.name:null;
        var warehouse_id = (!this.$commonFuction.isEmpty(item_data.warehouse)) ? item_data.warehouse.id:null;
        item_data["warehouse_id"] = warehouse_id;
        map_warehouse[warehouse_id] = item_data
        var lot_number = item_data.lot_number
        var expiry_date_root = item_data.expiry_date
        //var expiry_date_root =  moment(item_data.expiry_date,"yyyy-MM-DD").format("DD_MM_yyyy");
        var expiry_date =  moment(item_data.expiry_date,"yyyy-MM-DD").format("DD/MM/yyyy");
        console.log("expiry_date:",expiry_date)
        if (this.$commonFuction.isEmpty(group_lot_number[lot_number])){
          group_lot_number[lot_number] = []
          var item_lot = {
            key_lot :lot_number
          }
          list_lot_number_group.push(item_lot)
        }
        var list_lot_number = group_lot_number[lot_number]
        list_lot_number.push(expiry_date)

        if (this.$commonFuction.isEmpty(group_expiry_date[expiry_date])){
          group_expiry_date[expiry_date] = []
          var item_expiry = {
            key_expiry_date :expiry_date_root,
            text_expiry_date :expiry_date,
          }
          list_expiry_date_group.push(item_expiry)
        }
        var list_expiry_date = group_expiry_date[expiry_date]
        list_expiry_date.push(lot_number)
        list_convert_order.push(item_data);
      }
      this.map_warehouse = map_warehouse
      this.list_lot_number_group = list_lot_number_group
      console.log("list_expiry_date_grouplist_expiry_date_group:",list_expiry_date_group)
      this.list_expiry_date_group = list_expiry_date_group
      this.data_popup_product.list_warehouse = list_convert_order
      console.log("this.data_popup_product.list_warehouse",this.data_popup_product.list_warehouse)
    },
    async onActionClickedOrderDetail(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      console.log("payloadpayloadpayload:",payload)
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          /*this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }*/
            console.log("data.datadata.data:",data.data)
          this.data_change = this.$commonFuction.convertJsonObject(data.data );
          //this.data_change = data.data
          //this.data_popup_product.display_add_product = true;
          //this.load_popup_product()
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          //this.deleteName = data.data.name
          this.dataDeleteTmpCost = data.data
          this.display_popup_delete_cost = true
          break
        case 'add':
          /*this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }*/
          this.data_popup_product.obj_product = {}
          this.data_popup_product.display_add_product = true
          this.load_popup_product()
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    async load_popup_product() {
      var gqLQueryListData = DataServices.getList('product');
      var where_data = {
        active: {_eq: true},
        deleted: {_eq: false},
      }
      var resData_data = await this.$apollo.mutate({
        mutation: gqLQueryListData,
        variables: {
          where_key:where_data ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_data.data['product'];
      var map_convert_data = {}
      for (var i=0;i<listData.length;i++){
        var item_data = listData[i];
        var id = item_data.id
        map_convert_data[id] = item_data
      }
      this.map_convert_data = this.$commonFuction.convertJsonObject( map_convert_data )
      this.data_popup_product.list_product = this.$commonFuction.convertJsonObject(listData)
      /*if (this.data_popup_product.obj_product.product_id){

      }*/
      this.load_data_popup_by_product();
      console.log("listDatalistData:",listData)
    },
    async  load_data_popup_by_product() {
      console.log("this.data_popup_product.obj_product.product_id:",this.data_popup_product.obj_product.product_id)
      if (this.data_popup_product.obj_product.product_id){
        /*var product_id = this.data_popup_product.obj_product.product_id;
        var get_item_product = this.map_convert_data[product_id];
        this.data_popup_product.obj_product.product_code = get_item_product["code"]
        this.data_popup_product.obj_product.unit_name = (!this.$commonFuction.isEmpty(get_item_product["unit"])) ? get_item_product["unit"]["name"]:null
        this.data_popup_product.obj_product.unit_id = (!this.$commonFuction.isEmpty(get_item_product["unit"])) ? get_item_product["unit"]["id"]:null
*/
        this.load_warehouse(this.data_popup_product.obj_product.product_id)


      }else {
      console.log("aaaa")
      }
    },
    getRowActionsOrderItem() {
      var actions = {}
      console.log("this.is_lockthis.is_lock:",this.is_lock)
      if (this.is_lock){
        return null
      }
      var that = this;
      /*this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD).then( (res)  => {
       if (res){
         actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
       }
      })*/
      //if (this.role.add) {
        //actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`,disabled:this.disable_common};
      //}
      //if ((await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD))){

      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        console.log("5555555555555555555")
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
         //if (that.role.edit) {

           buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`,disabled:this.disable_common};
         //}
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
         //if (that.role.delete) {
           buttons.delete = {name: 'delete', icon: 'trash', key: 'id',disabled:this.disable_common}

         //}
        console.log("buttonsbuttonsbuttons:",that.dataModelOrderDetail)
        console.log("that.dataModel.actions:",that.dataModelOrderDetail.actions)
        if (that.dataModelOrderDetail.actions) {
          var modelaction = that.dataModelOrderDetail.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    async click_change_status(code) {
      switch (code) {
        case this.$constants.STATUS_ORDER.EDIT:
          window.location.href = '/admin/list-order/edit/'+this.model.id
          ///this.$router.push({ path: '/admin/list-order/edit/'+this.model.id })
          //this.$router.push({ path: '/admin/list-order/edit/'+this.model.id ,query: { key_tmp: (new Date()).getTime() } })
          break;
        case this.$constants.STATUS_ORDER.CANCELED:
          //load danh sách lý do
          this.data_popup_cancel_order.list_reason = await this.list_reason()
          this.data_popup_cancel_order.display_cancel_order = true;
          break;
        case this.$constants.STATUS_ORDER.UPDATED_DISPATCHER_ADDRESS:
          //Lấy địa chỉ chành
          await this.get_order_ship()
          this.data_popup_update_address_coordinator.list_freight =await this.list_freight_customer(this.model.customer_id);
          this.data_popup_update_address_coordinator.display = true;
          this.data_popup_update_address_coordinator.key++
          break;
        case this.$constants.STATUS_ORDER.PACKAGED:
          this.data_popup_update_pakage.list_parcel = await this.list_parcel()
          this.data_popup_update_pakage.display = true;
          break;
        case this.$constants.STATUS_ORDER.ENOUGH_DOCUMENTS_CLOSE_APPLICATION:
          this.data_popup_confirm_close_profile.display = true;
          break;
        case this.$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING:
          this.data_popup_confirm_profile.display = true;
          break;
        case 'CONFIRM_PAYMENT':
          this.display_confirm_payment = true;
          break;
        case 'CONFIRMATION_RECEIPT':
          this.$router.push({ path: '/admin/list-order/confirm-order/'+this.model.id ,query: {type:1, key_tmp: (new Date()).getTime() } })
          break;
        case 'REASON_RETURN':
          this.$router.push({ path: '/admin/list-order/confirm-order/'+this.model.id ,query: {type:2, key_tmp: (new Date()).getTime() } })
          break;
        default:
          //text = "Looking forward to the Weekend";
      }
    },
    async load_button_detail() {
      try {
        var dataRequest = {
          order_id: this.model.id,
        };
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
       var list_action_detail = await ApiRepository.post('/_api/order/list_status_next_order', dataRequest, {headers});
       var list_action = (list_action_detail["data"]["data"]["list_action_detail"]) ? list_action_detail["data"]["data"]["list_action_detail"]:[]
       var type_order = (list_action_detail["data"]["data"]["type_order"]) ? list_action_detail["data"]["data"]["type_order"]:[]
        this.type_order = type_order
        var list_action_convert = [];
        for (var i=0;i<list_action.length;i++){
          var item_data = list_action[i];
          var code_change =  item_data.code_change;
          if (code_change == this.$constants.STATUS_ORDER.CONFIRM_PAYMENT){
                if (!( this.obj_data.paid==true || this.obj_data.request_payment==false )){
                  list_action_convert.push(item_data);
                }
          }else if (code_change == this.$constants.STATUS_ORDER.CONFIRMATION_RECEIPT){
            if (!( this.obj_data.confirm_receipt==false)){
              list_action_convert.push(item_data);
            }
          }
          else {
            list_action_convert.push(item_data);
          }
        }
       this.list_action_detail = list_action_convert
       //console.log("list_action_detail:",list_action_detail["data"]["data"]["list_action_detail"])
      } catch (err) {
        console.log(err);
      }

    },
    async load_list_detail_order() {
      //this.obj_id
      var gqLQueryListOrderDocs = DataServices.getList('delivery_costs_detail');
      var where_user_docs = {
        delivery_costs_id: {_eq: this.model.id},
        deleted: {_eq: false},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'asc'}
        }
      });
      var listData = resData_docs.data['delivery_costs_detail'];
      //var listdata_convert = []
      //var count_stt = 0;
      var list_data_convert = []
      var item_tmp = null
      for (var i=0;i<listData.length;i++){
        var item_data = listData[i];
        var shipping_service_price =  (!this.$commonFuction.isEmpty(item_data["shipping_service_price"])) ? item_data["shipping_service_price"]:""
        var shipping_truck_price =  (!this.$commonFuction.isEmpty(item_data["shipping_truck_price"])) ? item_data["shipping_truck_price"]:""
        var return_not_delivery =  (!this.$commonFuction.isEmpty(item_data["return_not_delivery"])) ? item_data["return_not_delivery"]:""
        var return_delivery =  (!this.$commonFuction.isEmpty(item_data["return_delivery"])) ? item_data["return_delivery"]:""
        var combine_flights_company =  (!this.$commonFuction.isEmpty(item_data["combine_flights_company"])) ? item_data["combine_flights_company"]:""
        var transportation_service_npp_price =  (!this.$commonFuction.isEmpty(item_data["transportation_service_npp_price"])) ? item_data["transportation_service_npp_price"]:""
        var handling_fee_price =  (!this.$commonFuction.isEmpty(item_data["handling_fee_price"])) ? item_data["handling_fee_price"]:""
        var send_return_goods_post =  (!this.$commonFuction.isEmpty(item_data["send_return_goods_post"])) ? item_data["send_return_goods_post"]:""
        var code = item_data["code"];
        if (!this.is_lock){
          if (!this.$commonFuction.isEmpty(item_data["vehicle"])){
            item_data["vehicle_name"] = item_data["vehicle"]["name"]
          }
          var ordinal = item_data["ordinal"];
          var text_code = code+" - "+ordinal;
          item_data["text_code"] = text_code;
          item_data["key_data"] = item_data.id
          list_data_convert.push(item_data);
        }else {
          //gộp nhiều dòng lại
          if (i==0){
            item_tmp = listData[i];
            item_tmp["text_code"] = code;
            item_tmp["key_data"] = item_data.id;
            if (!this.$commonFuction.isEmpty(item_data["vehicle"])){
              item_tmp["vehicle_name"] = item_data["vehicle"]["name"]
            }
          }else {
            item_tmp["shipping_service_price"] = parseFloat(item_tmp["shipping_service_price"]) + parseFloat(shipping_service_price)
            item_tmp["shipping_truck_price"] = parseFloat(item_tmp["shipping_truck_price"]) + parseFloat(shipping_truck_price)
            item_tmp["return_not_delivery"] = parseFloat(item_tmp["return_not_delivery"]) + parseFloat(return_not_delivery)
            item_tmp["return_delivery"] = parseFloat(item_tmp["return_delivery"]) + parseFloat(return_delivery)
            item_tmp["combine_flights_company"] = parseFloat(item_tmp["combine_flights_company"]) + parseFloat(combine_flights_company)
            item_tmp["transportation_service_npp_price"] = parseFloat(item_tmp["transportation_service_npp_price"]) + parseFloat(transportation_service_npp_price)
            item_tmp["handling_fee_price"] = parseFloat(item_tmp["handling_fee_price"]) + parseFloat(handling_fee_price)
            item_tmp["send_return_goods_post"] = parseFloat(item_tmp["shipping_service_price"]) + parseFloat(send_return_goods_post)
          }
        }
      }
      if (this.is_lock){
        list_data_convert.push(item_tmp);
      }
      this.listDataOrderDetail = list_data_convert;
      //this.list_pakage = listData
      this.dataModelOrderDetail = DataServices.getModel('delivery_costs_detail');
      this.dataModelOrderDetail.processData(list_data_convert)
      this.componentKeyOrderDetail++;
    },
    async load_status_order() {
      //this.obj_id
      var gqLQueryListOrderDocs = DataServices.getList('history_status_order');
      var where_user_docs = {
        order_id: {_eq: this.model.id},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['history_status_order'];
      var listdata_convert = []
      for (var i=0;i<listData.length;i++){
        var item_status = listData[i];
        if (!this.$commonFuction.isEmpty(listData[i].name_process_custom)){
          item_status["name"] = (!this.$commonFuction.isEmpty(listData[i].name_process_custom)) ? listData[i].name_process_custom:''
        }else{
          item_status["name"] = (!this.$commonFuction.isEmpty(listData[i].order_status)) ? listData[i].order_status.name:''
        }

        var fullname = (!this.$commonFuction.isEmpty(listData[i].obj_created_by)) ? listData[i].obj_created_by.fullname:''
        var created_at = (!this.$commonFuction.isEmpty(listData[i].created_at)) ? listData[i].created_at:''
        var text_time = moment.tz(created_at, "Asia/Ho_Chi_Minh").format("DD/MM/yyyy HH:mm");
        if ((!this.$commonFuction.isEmpty(fullname))){
          text_time +=" - "+fullname
        }
        item_status["text_time"] = text_time;
        if (i==0){
          item_status["active"] = true;
        }else {
          item_status["active"] = false;
        }
        listdata_convert.push(item_status)
      }
      this.list_status = listdata_convert;
      console.log("load_status_order:",listdata_convert)
      //this.list_pakage = listData
    },
    async load_vehicle() {
      //this.obj_id
     /* console.log("this.obj_data.order_group_parcel_id:",this.obj_data.order_group_parcel_id)
      if (this.$commonFuction.isEmpty(this.obj_data.order_group_parcel_id)) {
        this.list_pakage = [];
        return false;
      }*/
      var gqLQueryListOrderDocs = DataServices.getList('vehicle');
      var where_user_docs = {
        active: {_eq: true},
        deleted: {_eq: false},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,//
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['vehicle'];
      var list_map ={}
      for (var i=0;i<listData.length;i++){
         var item_data = listData[i];
         list_map[item_data.id] = item_data
      }
      //console.log("this.list_pakage:",listData)
      this.list_map_vehicle = list_map
      this.list_vehicle = listData
    },
    async load_pakage() {
      //this.obj_id
      console.log("this.obj_data.order_group_parcel_id:",this.obj_data.order_group_parcel_id)
      if (this.$commonFuction.isEmpty(this.obj_data.order_group_parcel_id)) {
        this.list_pakage = [];
        return false;
      }
      var gqLQueryListOrderDocs = DataServices.getList('order_parcel');
      var where_user_docs = {
        order_group_parcel_id: {_eq: this.obj_data.order_group_parcel_id},
        deleted: {_eq: false},
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,//
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['order_parcel'];
      //console.log("this.list_pakage:",listData)
      this.list_pakage = listData
    },
    async load_docs() {
      var gqLQueryListOrderDocs = DataServices.getList('order_document');
      var where_user_docs = {}
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['order_document'];
      this.list_docs = this.$commonFuction.convertJsonObject(listData)
      var list_docs_update = this.$commonFuction.convertJsonObject(listData)
      var list_docs_update_convert = []
      for (var i=0;i<list_docs_update.length;i++){
          var item_data = list_docs_update[i];
          item_data["number_process"] = item_data["number_request"]
          list_docs_update_convert.push(item_data)
      }
      this.list_docs_update = list_docs_update_convert

    },
    render_mark_class(item) {
      var class_name = ``
      if (item.active ==true){
        class_name +=` active_mark `
      }
     // console.log("class_name:",class_name)
      return class_name;
    },
     render_status_class(item) {
      var class_name = ``
      if (item.active ==true){
        class_name +=` active_content `
      }
     // console.log("class_name:",class_name)
      return class_name;
    },
    async listGroupRole() {
      var where = {
        active: {_eq: true},
        deleted: {_eq: false}
      };
      var order_by = {id: 'desc'};
      var listData = await  this.$CoreService.getListData('group',where,null,null,order_by);
      return listData;
    },
    async getGroupUser(uid) {
      var table_cer = 'group_user';
      var where = {uid: {_eq: uid}};
      var gqLQueryList =await DataServices.getList(table_cer);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key:where ,
        }
      });
      var listData = resData.data[table_cer];
      return listData;
    },
    /*async getAccount(uid) {
      var table_cer = 'accounts';
      var where = {uid: {_eq: uid}};
      var gqLQueryList =await DataServices.getList(table_cer);
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key:where ,
        }
      });
      var listData = resData.data[table_cer];
      return listData;
    },*/
    getAddressData: function (addressData, placeResultData, id) {
      this.addressData = addressData;
      this.placeResultData = placeResultData;
      console.log(JSON.stringify(addressData));
      console.log(JSON.stringify(placeResultData));
      console.log(id);
    },
    backToList() {
      this.$emit('back');
    },
    async checkEmailAdminExist(email,model) {//check tồn tại sdt
      if (!this.$commonFuction.isEmpty(email)) {
        var where = {
          account: {'_eq': email},
          type: {'_eq': 'EMAIL'},
          deleted: {'_eq': false}
        }
        if (model !== undefined) where = Object.assign(where, {uid: {'_neq': model.id}})

        var {data} = await this.$apollo.query({
          query: DataServices.getList('accounts', {"fields" : "id, account, type"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        });

        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      }
    },
    async validateData() {
      var group_id_va = document.getElementById("group_id_va");
      if (this.$commonFuction.isEmpty(this.user.group_id)) {
        group_id_va.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        group_id_va.setCustomValidity("");
      }

      /*var text_validator_email = "";
      if (!this.$commonFuction.isEmpty(this.user.email)) {
        console.log("this.model:",this.model);
        this.account_exist = await this.checkEmailAdminExist(this.user.email.trim(), this.model);
        if (this.account_exist) {
          text_validator_email = this.$constants.MSG_TEXT.EMAIL_EXIST_SYSTEM_MSG;
        }
      }

      var email = document.getElementById("email");
      if (!this.$commonFuction.isEmpty(text_validator_email)) {
        email.setCustomValidity(text_validator_email);
      } else {
        email.setCustomValidity("");
      }
      var password_confirm_text_va = "";
      if (!this.$commonFuction.isEmpty(this.user.password) && !this.$commonFuction.isEmpty(this.user.password_confirm)) {
        if (this.user.password !== this.user.password_confirm) {
          password_confirm_text_va = this.$constants.MSG_TEXT.PASSWORD_NOT_MATCH_MSG;
          //password_confirm.setCustomValidity(this.$constants.MSG_TEXT.PASSWORD_NOT_MATCH_MSG);
        }
      }
      var password_text_va = "";
      if (this.formMode !== 'add') {
        if (this.user.email != this.user.email_old){
          //trường hợp khác thì bắt nhập lại mật khẩu
          if (this.$commonFuction.isEmpty(this.user.password)){
            password_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG;
          }
          if (this.$commonFuction.isEmpty(this.user.password_confirm)){
            password_confirm_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG
          }
        }else {
          //
          if (!this.$commonFuction.isEmpty(this.user.password)){
            //trường hợp thay đổi thì bắt nhập passcomfirt
            if (this.$commonFuction.isEmpty(this.user.password_confirm)){
              password_confirm_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG
            }
          }
          if (!this.$commonFuction.isEmpty(this.user.password_confirm)){
            //trường hợp thay đổi thì bắt nhập password
            if (this.$commonFuction.isEmpty(this.user.password)){
              password_text_va = this.$constants.MSG_TEXT.INPUT_INVALID_MSG;
            }
          }
        }
      }
      var password_confirm = document.getElementById("password_confirm");
      if (!this.$commonFuction.isEmpty(password_confirm_text_va)){
        password_confirm.setCustomValidity(password_confirm_text_va);
      }else {
        password_confirm.setCustomValidity("");
      }

      var password = document.getElementById("password");
      if (!this.$commonFuction.isEmpty(password_text_va)){
        password.setCustomValidity(password_text_va);
      } else {
        password.setCustomValidity("");
      }*/

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    convertPhone(numberPhone) {
      var phone = numberPhone
      if (numberPhone[0] === '0') {
        phone = phone.substring(1);
      }
      phone = phone.replace(' ', '')
      phone = '+84' + phone
      return phone
    },
    async getUserByPhoneNumber(phoneNumber) {
      // var phone = phoneNumber;
      // if (phoneNumber[0] === "0") {
      //   phone = phone.substring(1);
      // }
      // phone = phone.replace(' ', '');
      // phone = "+84" + phone;
      let query = `mutation MyMutation {
        checkPhoneNumber(phone: "${this.convertPhone(phoneNumber)}") {
          info
        }
      }`;
      await this.$apollo.mutate({
        mutation: gql(query)
      }).then(async(res) => {
        // console.log('=========> checkPhoneNumber: ', res)
        if (res) {
          this.firebaseID = res.data.checkPhoneNumber.info.uid
          await this.$apollo.query({
            query: DataServices.getList('users', {"fields" : "id"}),
            variables: {
              where_key: {
                phone: {'_eq': this.phone},
                deleted: {'_eq': false}
              }
            },
            fetchPolicy: "network-only"
          }).then((res) => {
            if (res && res.data.users.length > 0) this.userID = res.data.users[0].id
            else this.userID = null
            // console.log(this.userID)
          }).catch((error) => {
            console.log('ERROR: ', error.message)
          })
        }
      }).catch((error) => {
        console.log('ERROR: ', error.message)
        // CHƯA CÓ FIREBASE ID
        this.firebaseID = null
        this.userID = null
      })
    },
    async checkPhoneExist() {
      if (this.phone !== undefined && this.phone !== "") {
        // var phone = this.phone;
        // if (this.phone[0] === "0") {
        //   phone = phone.substring(1);
        // }
        // phone = phone.replace(' ', '');
        // phone = "+84" + phone;

        var where = {
          account: {'_eq': this.phone},
          type: {'_eq': 'PHONE'},
          deleted: {'_eq': false}
        }
        if (this.model !== undefined) where = Object.assign(where, {id: {'_neq': this.model.id}})
        var {data} = await this.$apollo.query({
          query: DataServices.getList('accounts', {"fields" : "id, account, type"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        })
        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      }
    },
    async saveData() {

      /* if(this.saving == true){
         return;
       }
       this.saving = true;*/
       //this.$commonFuction.is_loading(true);
      /*if (await this.validateData()) {
        // this.saving = false;
        this.$commonFuction.is_loading(false);
        return
      }*/


    /*  if (!this.account_exist) {
        var params = {
          user: this.user,
          images: this.images,
          imagesDeletes: this.imagesDeletes,
          // firebaseID: this.firebaseID,
          // userID: this.userID,
          // addressData:this.addressData,
          // placeResultData:this.placeResultData
          /!*listCategory : this.selectedCategory,
          addCategory : this.addCategory,
          delCategory : this.delCategory,*!/
        }
        console.log('saveData', th)*/

        var data_request =  this.obj_data;
        data_request["list_data"] = this.$commonFuction.convertJsonObject(this.listDataOrderDetail)
      console.log("savedata",data_request)
        this.$emit('save', data_request);
    //  }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if(files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF']
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Please upload the image file in the following format: jpeg, jpg, gif, png");
            return;
          }
        }
        if (files.length + this.images.length > this.maxFiles) {
          alert("Hình ảnh quá số lượng tối đa là " + this.maxFiles + " hình");
        } else {
          var arrTemp = [], arrFiles = [];
          for (let file of files) {
            if (this.isImage(file) && file.size <= this.maxFileSize) {
              file.source = file;
              file.objectURL = window.URL.createObjectURL(file);
              arrTemp.push(file);
            }
            if (file.size > this.maxFileSize) arrFiles.push(file);
          }

          if (arrFiles.length > 0) {
            alert("Hình ảnh của bạn đã quá dung lượng 1MB");
          } else {
            if (this.images.length > 0 && this.images[0].file === '') {
              this.imagesDeletes.push(this.images[0])
            }
            this.images = [];
            this.images = this.images.concat.apply(this.images, arrTemp);
            // console.log(this.images)
            await this.validateData()
          }
        }
      }
    },
    onFileClear(index) {
      let img = this.images[index];
      this.images.splice(index, 1);
      if (img.file === '') this.imagesDeletes.push(img)
    }
  },
  /*apollo: {
    category: {
      query() {
        return DataServices.getList('category', {"fields" : "id name"});
      },
      update(data) {
        if(data.category){
          this.categories = data.category
        }
      },
      fetchPolicy: "network-only"
    },
  }*/
}
</script>

<style lang="scss">
.parent_page_detail_ship {
  .p-multiselect {
    width: 450px;
  }

  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;

  }

  .category-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .2rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }

  .p-multiselect-label-container {
    height: 40px;
  }

  .group-button-detail-top {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 16px;
    padding-right: 0px;

    .style-button-common {
      margin-left: 8px;
      margin-right: 8px;
      width: fit-content;
    }

    .style-button-common:last-of-type {
      margin-right: 0px;
    }
  }

  .style-block-panel {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;

    .block-input {
      .style-col-input {
        width: 50%;

        .p-col {
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      .left-group-input {
        padding-left: 0px;
        padding-right: 16px;
        padding-top: 0px;
        float: left;
        /* padding-bottom: 0px;*/
      }

      .right-group-input {
        padding-right: 0px;
        padding-left: 16px;
        padding-top: 0px;
        float: right;
        /*padding-bottom: 0px;*/
      }

      label {
        color: #333333;
        // font: normal normal bold 16px/21px Segoe UI;
        padding-bottom: 0px;
        margin-bottom: 0px;
        padding-left: 0px;
      }

      input {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #D0D4D9;
        border-radius: 5px;
      }

      textarea {
        height: 72px;
      }
    }
  }

  .group-panel-detail {
    padding-bottom: 16px;

    .p-panel-header {
      background: #FAFAFA 0% 0% no-repeat padding-box !important;
      border-bottom: unset;
    }

    .p-panel-content {
      padding-top: 0px;
      background: #FAFAFA 0% 0% no-repeat padding-box !important;
    }

    .p-timeline {
      margin-left: 8px;
    }

    .p-timeline.p-timeline-vertical .p-timeline-event-opposite {
      display: none;
    }

    .custom-marker {
      border: 2px solid #C40380;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      background-color: #ffffff;
    }

    .p-timeline-event-separator {
      margin-top: 2px;
    }

    .p-timeline-event-content {
      margin-top: -4px;

      .name-status {
        // font: normal normal bold 16px/21px Segoe UI;
        letter-spacing: 0px;
        color: #666666;
      }

      .date-status {
        font: normal normal normal 16px/21px Segoe UI;
        letter-spacing: 0px;
        color: #666666;
      }

      .active_content {
        .name-status {
          // font: normal normal bold 16px/21px Segoe UI;
          letter-spacing: 0px;
          color: #C40380;
        }
      }
    }

    .custom-marker:last-of-type {
      /*display: none;*/
    }
  }

  .box-status {
    width: 100%;

    .item-status {
      height: 62px;
      display: block;
      position: relative;

      .icon-timeline {
        background-image: url("/assets/images/icon/ic_tientrinh.svg");
        width: 24px;
        height: 62px;
      }

      .box-content-status {
        position: absolute;
        top: 0px;
        left: 40px;

        .name-status {
          // font: normal normal bold 16px/21px Segoe UI;
          letter-spacing: 0px;
          color: #666666;
        }

        .date-status {
          // font: normal normal normal 16px/21px Segoe UI;
          letter-spacing: 0px;
          color: #666666;
        }
      }
    }

    .active_status {
      .icon-timeline {
        background-image: url("/assets/images/icon/ic_tientrinh_active.svg");
      }

      .box-content-status {
        .name-status {
          color: #C40380;
        }
      }
    }
  }

  .clear-div-custom {
    width: 100%;
  }

  .group-input-panel {
    .title_check_box {
      margin-left: 16px;
      font: normal normal normal 16px/21px Segoe UI;
      letter-spacing: 0px;
      color: #1D3118;
      font-weight: unset !important;
    }
  }

  .title-group-doc {
    margin-top: -44px;
  }

  .item_docs {
    margin-bottom: 10px;
  }

  .required_documents {
    text-align: left;
    letter-spacing: 0px;
    color: #333333;
    position: relative;

    .total_doc {
      font: normal normal bold 16px/21px Segoe UI;
      width: 45px;
      display: unset;
      position: absolute;
    }

    .content_doc {
      font: normal normal normal 16px/21px Segoe UI;
      display: unset;
      position: absolute;
      left: 45px;
    }
  }

  .style-popup-change-product,
  .style-popup-confirm-payment,
  .style-popup-cancel-order,
  .style-popup-update-address-coordinator,
  .style-popup-update-pakage,
  .style-popup-confirm-profile,
  .style-popup-confirm-close-profile
  {
    width: 600px;
  }
  .style-popup-update-pakage{
    .item-right-popup{
      width: 212px !important;
    }
    .item-left-popup{
      width:calc(100% - 212px) !important;
    }
    .style_group_pakage{
      position: relative;
      .popup_qty_pakage{
        width: 166px;
      }
      .style_icon_del_pakage{
        cursor: pointer;
        position: absolute;
        width: 38px;
        height: 32px;
        right: 0px;
        margin-top: auto;
        margin-bottom: auto;
        top: 0px;
        bottom: 0px;
      }
    }
  }
  .number_profile{
    width: 80px;
  }
  .group_text_profile{
    display: inline;
    .text_profile_request{
      font: normal normal bold 16px/21px Segoe UI;
      letter-spacing: 0px;
      color: #333333;
      margin-left: 2px;
    }
    .name_profile{
      font: normal normal normal 16px/21px Segoe UI;
      letter-spacing: 0px;
      color: #333333;
      margin-left: 15px;
    }
  }

  .item-left-group-custom {
    float: left;
    /* padding-bottom: 0px;*/
    padding-right: 8px;
    width: calc(100% - 169px);
  }
  .item-right-group-custom{
    padding-left: 8px;
    width: 169px;
    float: right;
  }
}

@media screen and (max-width: 600px){
  .left-group-input{
    width: 100%!important;
    padding-right: 0px!important;
  }
  .p-selectable-row{
    border: none!important;
  }
  .p-dropdown{
    border: 1px solid #D0D4D9;
    height: 45px!important;
  }
  .action{
    display: flex!important;
  }
  .p-column-title{
    display: flex!important;
    align-items: center!important;
  }
  .div-button-add{
    display: flex;
    justify-content: flex-end;
  }
  .p-inputtext{
    border-radius: unset!important;
  }

}


@media screen and (max-width: 1000px){
  .left-group-input{
    width: 100%!important;
    padding-right: 0px!important;
  }
  .p-selectable-row{
    border: none!important;
  }
  .p-dropdown{
    border: 1px solid #D0D4D9;
    height: 45px!important;
  }
  .action{
    display: flex!important;
  }
  .p-column-title{
    display: flex!important;
    align-items: center!important;
  }
  .div-button-add{
    display: flex;
    justify-content: flex-end;
  }
  .p-inputtext{
    border-radius: unset!important;
  }
}
/*.group-panel-detail-style{
  input {
    background: #EEEEEE 0% 0% no-repeat padding-box;
    border: 1px solid #D0D4D9;
    pointer-events:none;
  }
}*/
</style>