<template>
  <div class="p-fluid function-view">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-4 manager-title-custom">{{ title }}</div>
          <div class="p-col-8 txt-right fixed-bottom-toolbar" style="display: flex; justify-content: flex-end;">
            <!-- <Toolbar class="fixed-bottom-toolbar bg-transparent border-0"> -->
              <!-- <template slot="right"> -->
                <Button
                  label="Quay lại"
                  @click="backToList()"
                  class="p-button-warning callback-btn"
                />
                <Button
                  v-if="mode == 'edit'"
                  :label="'Lưu'"
                  @click="triggerSave = true"
                  class="p-button-success main-style-button"
                />
                <Button
                  v-else
                  :label="'Thêm mới'"
                  @click="triggerSave = true"
                  class="p-button-success main-style-button"
                />
              <!-- </template> -->
            <!-- </Toolbar> -->
          </div>
        </div>
        <!-- <div class="note">(*) Nội dung không được bỏ trống</div> -->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :triggerSave.sync="triggerSave"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
// import Config from '../../../config/index'
import DataServices from '@/core/DataServices'
import formEdit from './add'
import moment from "moment-timezone";
// import gql from "graphql-tag";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      users: null,
      validationErrors: [],
      title: "Thêm loại dịch vụ",
      mode: 'add',
      models: {},
      componentKey: 0,
      accountID: '',
      page_transaction:null,
      active_check:true,
      users_clubs_id : '',
      triggerSave: false,
      time_zone_local : moment.tz.guess(true),
      type_service_id: '',
      icon_service: [],
      logo_service: []
    }
  },
  async created() {
    await this.fetchTypeService();
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'editTypeService' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Sửa loại dịch vụ' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_TYPE_SERVICE);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    async fetchTypeService() {
      var where_key = {
          type: {_eq: 'beauty'}
      }
      let {data} = await this.$apollo.query({
          query: DataServices.getList('type_service', {"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{ordinal : "asc" }]
          },
          fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      this.type_service_id = list[0].id;
    },
    backToList() {
      this.$router.push({ path: '/admin/list-type-service' });
    },
    
    async saveData(params) {
      // console.log("params",params)
      let objects = {
        'id': this.models.id,
        'name' : params.name,
        'name_en' : params.name_en,
        'plain_name': params.plain_name,
        'ordinal': params.ordinal,
        'updated_at': moment(new Date()),
      }
      var icon_service = params.icon_service;
      var logo_service = params.logo_service;
      this.triggerSave = false;
      if(this.mode == 'add'){
        console.log("ADD")
        await this.$apollo.mutate({
            mutation: DataServices.insertData('type_service'),
            variables: {
              objects: objects
            }
        }).then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
              if (icon_service && icon_service.length > 0){
                let type_image = icon_service[0].type;
                if (type_image != 'old_edit') {
                  await this.$CoreService.deleteDataImage(this.$constants.TYPE_IMAGE.TYPE_SERVICE, returning.id).then(async (resDelete) => {
                    console.log("resresres:", resDelete);
                    await this.$CoreService.uploadImages(this.$constants.FOLDER_S3.TYPE_SERVICE, icon_service).then((resImage) => {
                      console.log("resresres=====:", resImage);
                      if (resImage) {
                        var objectImage = resImage[0];//lấy 1 tấm hình
                        this.$CoreService.updateDataImage(this.$constants.TYPE_IMAGE.TYPE_SERVICE, returning.id, objectImage).then(() => {
                            this.redirectEdit();
                        }).catch(async error => {
                            console.log('error', error);
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                                life: 3000
                            });
                            this.$commonFuction.is_loading(false);
                        });
                      }
                    });
                  });
                // } else {
                //   this.redirectEdit();
                }
              // } else {
              //   this.redirectEdit();
              }
              
              if (logo_service && logo_service.length > 0){
                let type_image = logo_service[0].type;
                if (type_image != 'old_edit') {
                  await this.$CoreService.deleteDataImage(this.$constants.TYPE_IMAGE.TYPE_SERVICE_LOGO, returning.id).then(async (resDelete) => {
                    console.log("resresres:", resDelete);
                    await this.$CoreService.uploadImages(this.$constants.FOLDER_S3.TYPE_SERVICE_LOGO, logo_service).then((resImage) => {
                      console.log("resresres=====:", resImage);
                      if (resImage) {
                        var objectImage = resImage[0];//lấy 1 tấm hình
                        this.$CoreService.updateDataImage(this.$constants.TYPE_IMAGE.TYPE_SERVICE_LOGO, returning.id, objectImage).then(() => {
                            this.redirectEdit();
                        }).catch(async error => {
                            console.log('error', error);
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                                life: 3000
                            });
                            this.$commonFuction.is_loading(false);
                        });
                      }
                    });
                  });
                } else {
                  this.redirectEdit();
                }
              } else {
                this.redirectEdit();
              }
            }
        })
      }else{
        // var user_id = this.$route.params.id;
        let variables = {
          'data_update': objects,
          'where_key': {
            'id': {'_eq': this.models.id}
          }
        }
        await this.$apollo.mutate({
            mutation: DataServices.updateData('type_service'),
            variables: variables
        }).then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
              if (icon_service && icon_service.length > 0){
                let type_image = icon_service[0].type;
                if (type_image != 'old_edit') {
                  await this.$CoreService.deleteDataImage(this.$constants.TYPE_IMAGE.TYPE_SERVICE, returning.id).then(async (resDelete) => {
                    console.log("resresres:", resDelete);
                    await this.$CoreService.uploadImages(this.$constants.FOLDER_S3.TYPE_SERVICE, icon_service).then((resImage) => {
                      console.log("resresres=====:", resImage);
                      if (resImage) {
                        var objectImage = resImage[0];//lấy 1 tấm hình
                        this.$CoreService.updateDataImage(this.$constants.TYPE_IMAGE.TYPE_SERVICE, returning.id, objectImage).then(() => {
                            this.redirectEdit();
                        }).catch(async error => {
                            console.log('error', error);
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                                life: 3000
                            });
                            this.$commonFuction.is_loading(false);
                        });
                      }
                    });
                  });
                // } else {
                //   this.redirectEdit();
                }
              // } else {
              //   this.redirectEdit();
              }
              
              if (logo_service && logo_service.length > 0){
                let type_image = logo_service[0].type;
                if (type_image != 'old_edit') {
                  await this.$CoreService.deleteDataImage(this.$constants.TYPE_IMAGE.TYPE_SERVICE_LOGO, returning.id).then(async (resDelete) => {
                    console.log("resresres:", resDelete);
                    await this.$CoreService.uploadImages(this.$constants.FOLDER_S3.TYPE_SERVICE_LOGO, logo_service).then((resImage) => {
                      console.log("resresres=====:", resImage);
                      if (resImage) {
                        var objectImage = resImage[0];//lấy 1 tấm hình
                        this.$CoreService.updateDataImage(this.$constants.TYPE_IMAGE.TYPE_SERVICE_LOGO, returning.id, objectImage).then(() => {
                            this.redirectEdit();
                        }).catch(async error => {
                            console.log('error', error);
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                                life: 3000
                            });
                            this.$commonFuction.is_loading(false);
                        });
                      }
                    });
                  });
                } else {
                  this.redirectEdit();
                }
              } else {
                this.redirectEdit();
              }
            }
        })
      }
      this.$commonFuction.is_loading(false);
    },
    async redirectEdit() {
      var that = this;
      var router_back = {path: '/admin/list-type-service'};
      that.$commonFuction.is_loading(false);
      if(that.mode == 'add'){
        that.$router.push(router_back);
      }else{
        // if (!that.$commonFuction.isEmpty(router_back)){
          // that.page_transaction = ""+that.page_transaction
          // router_back.query = { page_transaction: that.page_transaction.toString() };
        // }
        that.$router.push(router_back);
      }
      that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('type_service', 
        {
            "fields" : "id name ordinal name_en"
        }),
        variables: {
          where_key: {
            id: {'_eq': id},
            deleted: {_eq: false}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        if(this.models.active==false){
          this.active_check = false
        }
        
        let {listData} = await this.$CoreService.getMultipleFile(
        [
          this.$constants.TYPE_IMAGE.TYPE_SERVICE
        ],this.models.id,0);
        if (listData.length > 0){
          // console.log("listData",listData)
          for (let i = 0; i < listData.length; i++) {
            const ele = listData[i];
            if(ele.relationship_files.length > 0){
              if(ele.relationship_files[0].type == this.$constants.TYPE_IMAGE.TYPE_SERVICE){
                this.models.icon_service = {
                  id : ele.id,
                  url : ele.url
                }
              }
            }
          }
        }
      
        var DataNew = await this.$CoreService.getMultipleFile(
        [
          this.$constants.TYPE_IMAGE.TYPE_SERVICE_LOGO
        ],this.models.id,0);
        listData = DataNew['listData'];
        if (listData.length > 0){
          // console.log("listData",listData)
          for (let i = 0; i < listData.length; i++) {
            const ele = listData[i];
            if(ele.relationship_files.length > 0){
              if(ele.relationship_files[0].type == this.$constants.TYPE_IMAGE.TYPE_SERVICE_LOGO){
                this.models.logo_service = {
                  id : ele.id,
                  url : ele.url
                }
              }
            }
          }
        }
      }
      this.componentKey++;
    },
  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
  .manager-title-custom{
    color: #1D3118;
    font-size: 18px;
    /* margin-bottom: 16px; */
    padding-bottom: 10px;
    /* border-bottom: 1px solid #EEEEEE; */
    font-weight: 700;
  }
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 150px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 10px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;color: #ffffff;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.manager-title-row{
  border-bottom: 1px solid #EEEEEE;
}
.function-view .p-toolbar-group-right {
  flex-basis: 30%;
  justify-content: flex-end;
}
.function-view .p-button-warning.p-button {
  margin: 0 10px;
}

.fixed-bottom-toolbar{
  .p-button{width: auto; margin-right: 8px;
    &:last-child{margin-right: 0;}
  }
}
  
</style>