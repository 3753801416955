<template>
    <Panel>
        <div class="row">
            <div class="col-12 p-0">
                <div class="p-formgrid">
                    <div class="p-card-field p-grid">
                        <div class="col-12 d-flex pb-3 align-items-center">
                            <h5 class="pr-3 m-0">ĐỊA CHỈ CHÀNH XE</h5>
                            <Button class="p-button-success" style="width: 24px !important; height: 24px !important" icon="fa fa-plus" @click="addFreight"/>
                        </div>
                        <div class="col-lg-6 col-12 py-3" v-for="(freight, index) in freights" :key="index">
                            <Card>
                                <template #header>
                                    <div class="d-flex p-3 justify-content-between">
                                    <h5>CHÀNH XE {{ index + 1 }}</h5>
                                    <Button class="p-button-danger" icon="fa fa-trash" @click="delFreight(index)"/>
                                    </div>
                                </template>
                                <!-- <template #title>
                                    Advanced Card
                                </template> -->
                                <template #content>
                                    <div class="row pt-0 px-3" style=" margin-top: -2rem;">
                                        <div class="col-12 p-0">
                                            <label class="p-col-fixed txt-left">Chành xe</label>
                                            <div v-if="mode == 'add'" class="p-col">
                                                <v-select :clearable="false" title="Chành xe" :id="'freight-'+index" :filterable="false" placeholder="Chọn" v-model="freight.selFreight" :options="listFreight" label="name" @search="(search, loading) => { fetchOptionsFreight(search, loading, index) }" @open="getListFreight(index)">
                                                    <span slot="no-options" @click="$refs.select.open = false">
                                                        Không có dữ liệu
                                                    </span>
                                                </v-select>
                                                <input class="input_tmp_validator" :id="'id_freight-'+index">
                                            </div>
                                            <div v-else class="p-col">
                                                <!-- <v-select :clearable="false" title="Chành xe đã chọn" :id="'freight-'+index" :filterable="false" placeholder="Chành xe đã chọn" v-model="freight.selFreight" :options="listFreight" label="name" @search="(search, loading) => { fetchOptionsFreight(search, loading, index) }" @open="getListFreight(index)">
                                                    <span slot="no-options" @click="$refs.select.open = false"> 
                                                        Không có dữ liệu
                                                    </span>
                                                </v-select> -->
                                                <v-select :clearable="false" title="Chành xe đã chọn" :id="'freight-'+index" :filterable="false" placeholder="Chành xe đã chọn" v-model="freight.selFreight" :options="listFreight" label="code" @search="fetchOptionsFreight">
                                                    <template #selected-option="{ code, name }">
                                                        <em><b>{{code}}</b> - {{name}}</em>
                                                    </template>
                                                    <template #option="{ code, name }">
                                                        <em><b>{{code}}</b> - {{name}}</em>
                                                    </template>
                                                    <span slot="no-options" @click="$refs.select.open = false">
                                                        Không có dữ liệu
                                                    </span>
                                                </v-select>
                                                <input class="input_tmp_validator" :id="'id_freight-'+index">
                                            </div>
                                        </div>
                                        
                                        <div class="col-12 p-0">
                                            <label class="p-col-fixed txt-left">Đơn giá chành</label>
                                            <div class="p-col">
                                                <div class="p-inputgroup">
                                                    <InputNumber :min="0" mode="decimal" :minFractionDigits="0" v-model="freight.unit_price" placeholder="Nhập"/>
                                                    <span class="p-inputgroup-addon">
                                                        đ
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </Card>
                        </div>
                        <div class="col-12 text-center" v-if="freights.length === 0">
                            Chưa có địa chỉ chành xe
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Panel>
</template>
<script>
import DataServices from '@/core/DataServices'
export default {
  props: {
    mode: String,
    freights: Array,
    FreightDelete: Array
  },
  data() {
    return {
        tmp_freights: [],
        selFreight: null,
        qty_delivery: null,
        return_quantity: null,
        listFreight: [],
        listFreightAll: []
    }
  },
  async created() {
    await this.getListFreight();
    console.log("hello this is vbee", this.listFreight);
  },
  methods: {
    async getListFreight(index=null) {
        var list_ids = [];
        if (index != null) {
            list_ids = this.freights.filter((e) => !this.$commonFuction.isEmptyObject(e.selFreight?.id) && index && this.freights[index]?.selFreight?.id != e.selFreight?.id);
        } else {
            list_ids = this.freights.filter((e) => !this.$commonFuction.isEmptyObject(e.selFreight?.id));
        }
        var ids = list_ids.map(e => {return e.selFreight?.id;});
        var where_key = {
            deleted : {_eq : false},
            active : {_eq : true},
            id: {_nin: ids}
        }
        let {data} = await this.$apollo.query({
            query: DataServices.getList('freight', {"fields" : `id code name`}),
            variables: {
            where_key: where_key,
            orderBy:[{created_at : "asc" }]
            },
            fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_origin = [];
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_origin.push({id : e.id, name: e.name, code: e.code});
        }
        this.listFreight = list_origin;
        this.listFreightAll = list_origin;
    },
    
    async fetchOptionsFreight(search, loading, index=null){
      console.log(loading);
      if(search !== '' && search !== null){
        var list_ids = [];
        if (index != null) {
            list_ids = this.freights.filter((e) => !this.$commonFuction.isEmptyObject(e.selFreight?.id) && index && this.freights[index]?.selFreight?.id != e.selFreight?.id);
        } else {
            list_ids = this.freights.filter((e) => !this.$commonFuction.isEmptyObject(e.selFreight?.id));
        }
        var ids = list_ids.map(e => {return e.selFreight?.id;});
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          active : {_eq : true},
          name:{_iregex: this.$commonFuction.search(search).trim()},
          id: {_nin: ids}
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('freight',{"fields" : `id code name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'asc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_origin = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_origin.push({id : e.id, name :e.name, code: e.code});
        }
        console.log("list_origin===",list_origin);
        this.listFreight = list_origin;
      }else{
        // cosnole.log("dasdasd")
        this.listFreight = this.listFreightAll
      }
    },
    
    addFreight() {
        this.freights.push({
            id: null,
            // 'tmp_id-'+this.$commonFuction.generateUUID(),
            selFreight: null,
            unit_price: null
        });
    },
    
    delFreight(index) {
        var check = this.freights[index]?.id?.split("tmp_id") || [];
        if (check.length === 1) {
            this.FreightDelete.push(this.freights[index].id);
        }
        this.freights.splice(index, 1);
    },
  }
}
</script>

<style lang="scss">
.freight-table input[disabled=disabled], .freight-table .vs--disabled .vs__dropdown-toggle {
    background: transparent;
    border: unset;
    opacity: 1;
}
button{
    .fa.fa-plus{
        width: 14px !important;
        height: 14px !important;
        font-size: 14px !important;
    }
}
.p-inputgroup-addon{
  height: 45px;
}
</style>

